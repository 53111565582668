<template>
  <div>
    <div class="comet-modal-mask comet-fade-appear-done comet-fade-enter-done comet-fade-enter comet-fade-enter-active" v-if="isShow"></div>
    <div tabindex="-1" class="comet-modal-wrap" v-if="isShow">
      <div class="comet-modal comet-zoom-appear-done comet-zoom-enter-done" style="width: 360px;">
        <div tabindex="0" aria-hidden="true" style="width: 0px; height: 0px; overflow: hidden; outline: none;"></div>
        <button @click="onClose()" type="button" aria-label="Close" class="comet-modal-close">
          <span class="comet-icon comet-icon-close ">
            <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
              <path d="M181.088 135.936l1.536 1.44L512 466.741333l329.376-329.365333a32 32 0 0 1 46.688 43.712l-1.44 1.536L557.258667 512l329.365333 329.376a32 32 0 0 1-43.712 46.688l-1.536-1.44L512 557.258667 182.624 886.624a32 32 0 0 1-46.688-43.712l1.44-1.536L466.741333 512 137.376 182.624a32 32 0 0 1 43.712-46.688z"></path>
            </svg>
          </span>
        </button>
        <div class="comet-modal-content comet-modal-no-header">
          <div class="comet-modal-body comet-confirm">
            <div class="comet-confirm-title">{{title}}</div>
            <div class="comet-confirm-content">{{content}}</div>
          </div>
          <div class="comet-modal-footer comet-modal-footer-vertical">
            <button @click="onOk()" type="button" class="comet-btn comet-btn-primary">
              <span>{{$t('address.confirm')}}</span>
            </button>
            <button v-if="isCancel" @click="onClose()" type="button" class="comet-btn">
              <span>{{$t('address.cancel')}}</span>
            </button>
          </div>
        </div>
        <div tabindex="0" aria-hidden="true" style="width: 0px; height: 0px; overflow: hidden; outline: none;"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmVue",
  props: {
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    isCancel: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      isShow: false,
    };
  },
  methods: {
    open () {
      this.isShow = !this.isShow;
    },
    hide () {
      this.isShow = false;
    },
    onClose () {
      this.open();
      this.$emit("onClose");
    },
    onOk () {
      this.$emit("onOk");
    },
  },
};
</script>

<style>
</style>