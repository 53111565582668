<template>
  <a-modal v-model:visible="RechargeVisible" :title="$t('share.hz')" :footer="null" :style="{ width: '800px' }">
    <div :style="{ padding: '0 20px' }">
      <a-form :model="params" name="basic" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }" autocomplete="off" @finish="onFinish">
        <!-- type -->
        <a-form-item :label="$t('share.hzd')" name="type">
          <a-radio-group v-model:value="params.type">
            <a-radio :value="1">{{ $t('share.qbye') }}</a-radio>
            <a-radio :value="2">{{ $t('share.tgje') }}</a-radio>
          </a-radio-group>
        </a-form-item>
        <!--  -->
        <a-form-item :label="$t('share.jjsy')">
          <div style="display: flex">${{ wallet.award }}</div>
        </a-form-item>
        <!-- number -->
        <a-form-item :label="$t('share.hzje')" name="number">
          <a-input v-model:value="params.number" type="number" :step="0.01" :placeholder="$t('share.qsrhzje')">
            <template #suffix>
              <a href="javascript:" @click="onAll()">{{ $t('withdrawal.btn.all') }}</a>
            </template>
          </a-input>
        </a-form-item>
        <!-- 备注 -->
        <!-- <a-form-item :label="$t('withdrawal.notice')" name="username" :label-col="{ span: 4 }" :wrapper-col="{ span: 17 }">
          <div style="padding: 5px 0; line-height: 23px; color: #999">
            <div>{{ $t('withdrawal.notice.text') }}</div>
          </div>
        </a-form-item> -->
        <a-form-item :wrapper-col="{ offset: 9, span: 16 }">
          <a-button type="primary" html-type="submit" :loading="btnLoad">{{ $t('withdrawal.submit') }}</a-button>
          <a-button style="margin-left: 10px" @click="hide()">{{ $t('recharge.cancel.text') }}</a-button>
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'RechargeVue',
  data() {
    return {
      //加载按钮
      btnLoad: false,
      RechargeVisible: false,

      params: {
        type: 1,
        number: ''
      },

      wallet: {
        balance: 0
      },
      credit: {
        max: 0,
        balance: 0
      }
    }
  },
  created() {},
  methods: {
    getWallet() {
      let that = this
      this.$api.store.getShopWallet().then((res) => {
        that.wallet = res.data.wallet[0]
      })
    },
    /**
     * 存币
     */
    show() {
      this.getWallet()
      this.RechargeVisible = true
    },
    hide() {
      this.RechargeVisible = false
    },
    /**
     * 提交提现
     * @param {*} values
     */
    onFinish(values) {
      console.log('params:', values)
      //参数
      this.btnLoad = true
      this.$message.loading({ content: 'Submit repayment...', key: 'msg' })

      let that = this
      this.$api.store
        .transferAward(values)
        .then((res) => {
          that.btnLoad = false
          that.$message.success({
            content: res.msg,
            key: 'msg'
          })

          //关闭弹窗，重新刷新
          that.hide()
          that.$emit('AwardSuccess')
        })
        .catch(() => {
          that.btnLoad = false
        })
    },
    /**
     * 全部
     */
    onAll() {
      this.params.number = this.wallet.award
      this.onNumber()
    },
    /**
     * 输入数字
     */
    onNumber() {
      let number = parseFloat(this.params.number)

      //小于0
      if (!number || number < 0) {
        number = ''
      }

      console.log(number)

      if (number > this.wallet.award) {
        number = this.wallet.award
      }

      let that = this
      setTimeout(function () {
        that.params.number = number
      }, 10)
    }
  }
}
</script>

<style>
.wallet-title {
  margin-right: 5px;
  font-weight: 700;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
