<template>
  <a-modal title="选择商品" width="60%" :visible="visible">
    <!-- 表格 -->
    <a-table ref="table" :rowKey="(record)=>{return record.id}" :row-selection="{
        onChange: this.onSelectChange,
        selectedRowKeys: this.selectedRowKeys
      }" :columns="columns" :data-source="dataSource" :pagination="pagination" :loading="loading" @change="getStoreProducts">
      <template #bodyCell="{ column, record }">
        <template v-if="record && column.dataIndex === 'img'">
          <img :src="record.img" width="50" style="margin-right:5px" />
        </template>
        <template v-if="record && column.dataIndex === 'name'">
          <div style="display: flex; align-items: center;width: 300px; ">
            <div style="font-size: 13px;width: 100%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ record.name }}</div>
          </div>
        </template>
        <template v-if="record && column.dataIndex === 'price'">
          <div>${{ record.price }}</div>
        </template>
        <template v-if="record && column.dataIndex === 'profit_value'">
          <div>
            <a-tag color="green"> ≈ ${{ record.profit_value }}</a-tag>
          </div>
        </template>
        <template v-else-if="column.key === 'action'">
          <span>
            <!-- <a>Detail</a>
              <a-divider type="vertical" /> -->
            <a-popconfirm :title="$t('products.ask.delete')" :ok-text="$t('products.yes')" :cancel-text="$t('products.no')" @confirm="onDelete(record)">
              <a href="javascript:">{{$t('products.delete')}}</a>
            </a-popconfirm>
          </span>
        </template>
      </template>
    </a-table>

    <template #footer>
      <a-button key="back" @click="onCancel">Return</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="onOk">Submit</a-button>
    </template>
  </a-modal>
</template>

<script>
export default {
  components: {

  },
  props: {
    visible: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      columns: [
        {
          title: "ID",
          dataIndex: "id",
          sorter: false,
          width: "5%",
        },
        {
          title: this.$t('products.table.img'),
          dataIndex: "img",
          width: "5%",
        },
        {
          title: this.$t('products.table.name'),
          dataIndex: "name",
          width: "30%",
        },
        {
          title: this.$t('products.table.category'),
          dataIndex: "cate_name",
          width: "15%",
        },
        {
          title: this.$t('products.table.stock'),
          dataIndex: "total",
          width: "15%",
        },
        {
          title: this.$t('products.table.price'),
          dataIndex: "price",
          width: "15%",
        },
        {
          title: this.$t('products.table.profit'),
          dataIndex: "profit_value",
          width: "15%",
        },
      ],
      loading: false,
      pagination: { total: 0, current: 1, pageSize: 20 },
      dataSource: [],
      queryParam: {
        search: "",
        category: []
      },

      //筛选
      categorys: [],

      //批量选中
      selectedRowKeys: [],
      selectedRows: [],

    };
  },
  created () {
    this.getStoreProducts(this.pagination);
  },
  watch: {
    visible (newVal, oldVal) {
      console.log(newVal, oldVal)
      if (newVal) {
        this.selectedRowKeys = []
      }
    }
  },
  methods: {
    /**
    * 选中框
    * @param {*} selectedRowKeys 
    * @param {*} selectedRows 
    */
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      console.log(this.selectedRowKeys, this.selectedRows)
    },
    onOk () {
      this.$emit('ok', this.selectedRows)
    },
    onCancel () {
      this.$emit('cancel')
    },
    onSearch () {
      this.getStoreProducts(this.pagination);
    },
    getStoreProducts (parameter) {
      const pagination = {
        page: parameter.current,
        size: parameter.pageSize,
      };
      const requestParameters = Object.assign({}, pagination, this.queryParam);

      this.loading = true;

      let that = this;
      this.$api.store.getStoreProducts(requestParameters).then((res) => {
        that.loading = false;
        that.pagination = {
          total: res.data.total,
          current: res.data.pageNo,
          pageSize: res.data.pageSize,
        };
        that.dataSource = res.data.data;
      });
    },
  },
}
</script>
