<template>
  <div>MESSAGE</div>
  <chat></chat>
</template>

<script>
import Chat from '@/components/Chat.vue'
export default {
  name: "messageVue",
  components: { Chat },
}
</script>

<style>
</style>