<template>
  <a-table :columns="columns" :data-source="dataSource" :pagination="pagination" :loading="loading" @change="getStoreTransfer">
    <template #bodyCell="{ column, record }">
      <template v-if="record && column.dataIndex === 'status'">
        <a-tag color="orange" v-if="record.status == 1">{{ $t('recive.review') }}</a-tag>
        <a-tag color="green" v-if="record.status == 2">{{ $t('recive.success') }}</a-tag>
        <a-tag color="red" v-if="record.status == 3">{{ $t('recive.reject') }}</a-tag>
      </template>
    </template>
  </a-table>
</template>

<script>
export default {
  name: 'TransferList',
  data() {
    return {
      //存币记录表格
      columns: [
        // {
        //   title: 'ID',
        //   dataIndex: 'id',
        //   // sorter: true,
        //   width: '50px'
        // },
        {
          title: this.$t('_sjsy._xh'),
          customRender: (record) => {
            if (!this.loading) {
              return (this.pagination.current - 1) * this.pagination.pageSize + record.index + 1
            }
          }
        },
        {
          title: this.$t('recive.method'),
          customRender: (item) => {
            return item.record.name + ' (' + item.record.cp_name + ')'
          }
        },
        {
          title: this.$t('withdrawal.number'),
          dataIndex: 'number'
        },
        {
          title: this.$t('withdrawal.real.number'),
          dataIndex: 'real_number'
        },
        {
          title: this.$t('recive.address'),
          dataIndex: 'transfer_token'
        },
        {
          title: this.$t('recive.date'),
          dataIndex: 'create_time'
        },
        {
          title: this.$t('recive.status'),
          dataIndex: 'status'
        }
      ],
      loading: false,
      pagination: { total: 0, current: 1, pageSize: 10 },
      dataSource: [],
      queryParam: {
        search: ''
      }
    }
  },
  created() {
    this.getStoreTransfer(this.pagination)
  },
  methods: {
    refresh() {
      this.getStoreTransfer({ total: 0, current: 1, pageSize: 10 })
    },
    getStoreTransfer(parameter) {
      const pagination = {
        page: parameter.current,
        size: parameter.pageSize
      }
      const requestParameters = Object.assign({}, pagination, this.queryParam)

      this.loading = true

      let that = this
      this.$api.store.getStoreTransfer(requestParameters).then((res) => {
        that.loading = false
        that.pagination = {
          total: res.data.total,
          current: res.data.pageNo,
          pageSize: res.data.pageSize
        }
        that.dataSource = res.data.data
      })
    }
  }
}
</script>

<style></style>
