<template>
  <div id="root">
    <!-- 账号信息 -->
    <div class="account-info">
      <div class="account-info-box">
        <img :src="user.avatar">
        <div class="account-info-name">{{user.nickname}}</div>
      </div>
    </div>
    <!-- 订单栏 -->
    <div class="account-order">
      <div class="account-order-header">
        <div class="account-order-title">{{$t('member.order.title')}}</div>
        <router-link :to="{name:'orderAll'}" class="account-order-extra">
          <span>{{$t('member.order.view.all')}}</span>
          <span class="comet-icon comet-icon-arrowright arrow-icon">
            <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
              <path d="M280.96 938.197333a32 32 0 0 0 42.517333 5.397334l2.602667-2.005334 393.856-338.901333a107.52 107.52 0 0 0 5.333333-158.101333l-3.221333-3.050667L326.698667 82.965333a32 32 0 0 0-45.312 45.077334l2.304 2.346666L679.04 488.917333a43.52 43.52 0 0 1 1.770667 62.784l-2.624 2.432-393.856 338.922667a32 32 0 0 0-3.392 45.12z">
              </path>
            </svg>
          </span>
        </router-link>
      </div>
      <div class="account-order-content">
        <router-link :to="{name:'orderUnpaid'}" class="account-order-status">
          <div class="badge">{{order.unpaid}}</div>
          <img class="status-image" src="@/static/img/unpaid.png">
          <div class="account-order-status-text">{{$t('member.order.unpaid')}}</div>
        </router-link>
        <router-link :to="{name:'orderProcurement'}" class="account-order-status">
          <div class="badge">{{order.purchase}}</div>
          <img class="status-image" src="@/static/img/shipped.png">
          <div class="account-order-status-text">{{$t('member.order.procurement')}}</div>
        </router-link>
        <router-link :to="{name:'orderProcessing'}" class="account-order-status">
          <div class="badge">{{order.processing}}</div>
          <img class="status-image" src="@/static/img/shipped.png">
          <div class="account-order-status-text">{{$t('member.order.processing')}}</div>
        </router-link>
        <router-link :to="{name:'orderShipped'}" class="account-order-status">
          <div class="badge">{{order.shipped}}</div>
          <img class="status-image" src="@/static/img/shipped2.png">
          <div class="account-order-status-text">{{$t('member.order.shipped')}}</div>
        </router-link>
        <router-link :to="{name:'orderCompleted'}" class="account-order-status">
          <div class="badge">{{order.completed}}</div>
          <img class="status-image" src="@/static/img/reviewed.png">
          <div class="account-order-status-text">{{$t('member.order.completed')}}</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "overviewVue",
  data () {
    return {
      user: {},

      //订单
      order: {},
    };
  },
  created () {
    this.$message.loading({ content: "loading...", key: "msg" });

    //获取用户信息
    this.user = this.$common.getInfo();

    //获取
    let that = this;
    this.$api.user.getUserInfo().then((res) => {
      that.order = res.data;
      setTimeout(function () {
        that.$message.destroy();
      }, 500);
    });
  },
};
</script>

<style src="@/static/css/overview.css"></style>