<template>
  <a-table :columns="columns" :data-source="dataSource" :pagination="pagination" :loading="loading" @change="getStoreWalletlog">
    <template #bodyCell="{ column, record }">
      <template v-if="record && column.dataIndex === 'type'">
        <a-tag color="green" v-if="record.type_str?.fh === '+'">{{ record.type_str?.bt }}</a-tag>
        <a-tag color="red" v-if="record.type_str?.fh === '-'">{{ record.type_str?.bt }}</a-tag>
      </template>
      <template v-if="record && column.dataIndex === 'number'">
        <a-tag color="green" v-if="record.type_str?.fh === '+'">+ ${{ record.number }}</a-tag>
        <a-tag color="red" v-if="record.type_str?.fh === '-'">- ${{ record.number }}</a-tag>
      </template>
    </template>
  </a-table>
</template>

<script>
// const TYPE = {
//   1: "采购",
//   2: "收益",
// };

export default {
  name: 'TransferList',
  data() {
    return {
      //存币记录表格
      columns: [
        // {
        //   title: 'ID',
        //   dataIndex: 'id',
        //   // sorter: true,
        //   width: '50px'
        // },
        {
          title: this.$t('_sjsy._xh'),
          customRender: (record) => {
            if (!this.loading) {
              return (this.pagination.current - 1) * this.pagination.pageSize + record.index + 1
            }
          }
        },
        {
          title: this.$t('recive.create.at'),
          dataIndex: 'create_time'
        },
        {
          title: this.$t('recive.type'),
          dataIndex: 'type'
        },
        {
          title: this.$t('recive.befor'),
          dataIndex: 'befor',
          customRender: (text) => {
            return '$' + text.value
          }
        },
        {
          title: this.$t('recive.amount'),
          dataIndex: 'number'
        },
        {
          title: this.$t('recive.balance'),
          dataIndex: 'after',
          customRender: (text) => {
            return '$' + text.value
          }
        },
        {
          title: this.$t('recive.freeze'),
          dataIndex: 'freeze',
          customRender: (text) => {
            return '$' + text.value
          }
        }
      ],
      loading: false,
      pagination: { total: 0, current: 1, pageSize: 10 },
      dataSource: [],
      queryParam: {
        search: ''
      }
    }
  },
  created() {
    this.getStoreWalletlog(this.pagination)
  },
  methods: {
    refresh() {
      this.getStoreWalletlog({ total: 0, current: 1, pageSize: 10 })
    },
    getStoreWalletlog(parameter) {
      const pagination = {
        page: parameter.current,
        size: parameter.pageSize
      }
      const requestParameters = Object.assign({}, pagination, this.queryParam)

      this.loading = true

      let that = this
      this.$api.store.getStoreWalletlog(requestParameters).then((res) => {
        that.loading = false
        that.pagination = {
          total: res.data.total,
          current: res.data.pageNo,
          pageSize: res.data.pageSize
        }
        that.dataSource = res.data.data
      })
    }
  }
}
</script>

<style></style>
