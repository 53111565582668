<template>
  <a-modal v-model:visible="visible" :title="$t('setting.upload.pic')" @ok="onOk" @cancel="onCancel" :style="{width:'300px'}" :okText="$t('setting.send.pic')" :cancelText="$t('recharge.cancel.text')">
    <div :style="{ padding: '0 20px'}">
      <a-form :model="params" name="basic" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }" autocomplete="off">
        <a-form-item :required="true">
          <a-upload name="file" action="web/v1/upload/file" list-type="picture-card" :show-upload-list="false" :headers="{}" :data="{}" :before-upload="beforeUpload" @change="onAvatarUpload" accept=".jpg,.png,.bmp">
            <img v-if="imageUrl" :src="imageUrl" alt="avatar" style="width: 100%;" />
            <div v-else>
              <loading-outlined v-if="loadingImage"></loading-outlined>
              <plus-outlined v-else></plus-outlined>
              <div class="ant-upload-text">{{ $t('setting.upload') }}</div>
            </div>
          </a-upload>
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons-vue";
export default {
  name: "RechargeVue",
  components: {
    LoadingOutlined,
    PlusOutlined,
  },
  data () {
    return {
      //存币
      visible: false,

      // 加载图片
      loadingImage: false,
      imageUrl: "",
      img: "",

      //参数
      params: {

      }
    };
  },
  created () {

  },
  methods: {
    onOk () {
      this.$emit("onSend",this.img)
    },
    onCancel () {
      this.imageUrl = ''
      this.img = ''
    },
    /**
     * 存币
     */
    show () {
      this.visible = true;
    },
    hide () {
      this.visible = false;
    },
    /**
     * 上传封面图
     * @param {*} img
     * @param {*} callback
     */
    getBase64 (img, callback) {
      const reader = new FileReader();
      reader.addEventListener("load", () => callback(reader.result));
      reader.readAsDataURL(img);
    },
    /**
     * 上传头像
     * @param {*} info 
     */
    onAvatarUpload (info) {
      if (info.file.status === "uploading") {
        this.loadingImage = true;
        return;
      }
      if (info.file.status === "done") {
        var response = info.file.response;
        if (response.code === 1) {
          this.loadingImage = false;
          this.$message.error(response.msg);
          return;
        }

        this.img = response.data.url;
        this.loadingImage = false;

        // Get this url from response in real world.
        this.getBase64(info.file.originFileObj, (imageUrl) => {
          this.imageUrl = imageUrl;
          this.loadingImage = false;
        });
      }
    },
    beforeUpload (file) {
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/bmp";
      if (!isJpgOrPng) {
        this.$message.error("only upload jpg");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("max 2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
  },
};
</script>

<style>
</style>