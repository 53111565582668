<template>
  <!-- 编辑内容 -->

  <!-- 板块 -->
  <!-- <div v-for="(item, i) in content.columns" :key="i" style="position: relative">
    <div class="bbox ibgimg" :style="'background-image: url(' + item.bgimg + ');'">
      <div class="bbitem">
        <div v-for="(vo, k) in item.goods" :key="k" class="ittd">
          <router-link :to="{ name: 'detail', query: { id: vo.id } }" style="color: #000" class="ita">
            <div class="itimg">
              <img :src="vo.img + '?r=' + Math.floor(Math.random() * 1000)" alt="" style="width: 100%; height: 100%" />
            </div>
            <div class="it-font">{{ vo.name }}</div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <div v-html="content.content"></div> -->

  <StoreHead>
    <!-- 顶部 -->
    <template v-slot:banner="{ banner }">
      <img v-if="banner != ''" :src="banner + '?r=' + Math.floor(Math.random() * 1000)" alt="" style="width: 100%; min-height: 80px; max-height: 150px" />
      <div v-else style="color: #fff; min-height: 80px">
        <img :src="require('@/static/temp/banner/b1.jpg')" alt="" style="width: 100%; min-height: 80px; max-height: 150px" />
      </div>
    </template>

    <!-- 内容 -->
    <template v-slot:content="{ template, imgs }">
      <!-- 推荐滚动栏 -->
      <div class="slick-slider slick-initialized" v-if="imgs.length > 1">
        <button type="button" class="slick-arrow slick-prev sl-prev">Previous</button>
        <div class="slick-list">
          <div class="slick-track">
            <swiper
              :modules="modules"
              :slidesPerView="1"
              :pagination="{
                el: '.banner-slick-dots',
                type: 'custom',
                renderCustom: onRenderCustom
              }"
              :navigation="{
                nextEl: '.sl-next',
                prevEl: '.sl-prev'
              }"
              :loop="true"
              :autoplay="{
                delay: 5000,
                disableOnInteraction: false
              }"
            >
              <swiper-slide v-for="(item, i) in imgs" :key="i">
                <div class="slick-slide slick-active slick-current" style="outline: none; width: 100%; height: 600px">
                  <div class="ibanner" :style="'background-image: url(' + item.url + '?r=' + Math.floor(Math.random() * 1000) + ');'"></div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <button type="button" data-role="none" class="slick-arrow slick-next sl-next">Next</button>
      </div>
      <!-- 轮播图1张 -->
      <div v-else-if="imgs.length == 1">
        <img :src="imgs[0] && imgs[0].url" />
      </div>

      <BannerGoods :store_id="store_id"></BannerGoods>
      <StoreTemplate1 :store_id="store_id" v-if="template == 1"></StoreTemplate1>
      <StoreTemplate2 :store_id="store_id" v-if="template == 2"></StoreTemplate2>
      <StoreTemplate3 :store_id="store_id" v-if="template == 3"></StoreTemplate3>
    </template>
  </StoreHead>
</template>

<script>
import StoreHead from '@/components/shop/StoreHead.vue'
import StoreTemplate1 from '@/components/shop/StoreTemplate1.vue'
import StoreTemplate2 from '@/components/shop/StoreTemplate2.vue'
import StoreTemplate3 from '@/components/shop/StoreTemplate3.vue'
import BannerGoods from '@/components/shop/BannerGoods.vue'
import { Navigation, Pagination, Autoplay } from 'swiper'
export default {
  name: 'HomeVue',
  components: {
    StoreHead,
    StoreTemplate1,
    StoreTemplate2,
    StoreTemplate3,
    BannerGoods
  },
  data() {
    return {
      modules: [Navigation, Pagination, Autoplay],
      store_id: 0,
      recommend: []
    }
  },
  created() {
    this.store_id = this.$route.query.id
  },
  methods: {}
}
</script>
<style scoped>
.bbox {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bbitem {
  width: 80%;
  margin: 0 auto;
  position: relative;
  flex-wrap: wrap;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 50px 50px;
}
.ittd {
  background-color: #fff;
  padding: 5px 5px 20px 5px;
  border-radius: 10px;
}
.itimg {
  border-radius: 10px;
  overflow: hidden;
  height: 75%;
  width: 100%;
}
.ita {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.it-font {
  padding-top: 15px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  max-height: 24%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ibanner {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-position: 100% 100%;
}

.ibgimg {
  padding: 100px 0px;
  position: relative;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  background-position: 100% 100%;
}
</style>
<style scoped src="@/static/css/index.css"></style>
