<template>
  <nav aria-label="sub-navigation" class="-i7Hgd" role="navigation" style="">
    <div ref="menu" class="ZAHWm k75rT h2menu" style="height: auto; top: 158px">
      <div class="U_Vu3 dx7Ke aArXn" style="">
        <div class="aXrua">
          <div class="UYr2M fWRC1 XKOT5" v-if="category.length > 0">
            <!-- 二级 -->
            <template v-for="(item, i) in category" :key="i">
              <ul class="L9PTf" style="">
                <template v-for="(vo, k) in item" :key="k">
                  <template v-for="(vvo, kk) in vo.category" :key="kk">
                    <li class="Bn6xe wZFQY" v-if="kk == 0">
                      <h3 class="kb32p">
                        <a class="lcFyR tuUGG RTmqg">
                          {{ vo.name }}
                        </a>
                      </h3>
                    </li>
                    <li :class="'Bn6xe wZFQY ' + (kk == vo.category.length - 1 ? 'Wpe5J' : '')">
                      <a @click="onCate(vvo)" class="lcFyR tuUGG RTmqg">
                        {{ vvo.name }}
                      </a>
                    </li>
                  </template>
                </template>
              </ul>
            </template>
            <!-- <div class="L9PTf">
              <a class="UUHiq FhWj8 IdMRc RTmqg" href="/browse/sale/men?campaign=0208wingnptp01c&amp;jid=j013071-23894&amp;cm_sp=merch-_-n-slp:_nordstrom_sale_&amp;_promotions_23894_j013071-_-catpromo_0_p99_0&amp;"><img src="https://n.nordstrommedia.com/id/fd3d4570-1440-42da-befa-977203294ab6.jpg" alt="" /></a><a href="/browse/sale/men?campaign=0208wingnptp01c&amp;jid=j013071-23894&amp;cm_sp=merch-_-n-slp:_nordstrom_sale_&amp;_promotions_23894_j013071-_-catpromo_0_p99_0&amp;" class="FhWj8 Xji0_ RTmqg">Men’s Sale</a>
            </div> -->
          </div>
          <!-- <button aria-label="close" class="YYqrk">
            <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="svg_IconClose"><path d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L12 10.5858L18.2929 4.29289C18.6834 3.90237 19.3166 3.90237 19.7071 4.29289C20.0976 4.68342 20.0976 5.31658 19.7071 5.70711L13.4142 12L19.7071 18.2929C20.0976 18.6834 20.0976 19.3166 19.7071 19.7071C19.3166 20.0976 18.6834 20.0976 18.2929 19.7071L12 13.4142L5.70711 19.7071C5.31658 20.0976 4.68342 20.0976 4.29289 19.7071C3.90237 19.3166 3.90237 18.6834 4.29289 18.2929L10.5858 12L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z" fill="#191A1B"></path></svg>
          </button> -->
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavMenu',
  props: {
    category: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  methods: {
    onCate(item) {
      console.log(item)
      this.$refs.menu.style.display = 'none'
      setTimeout(() => {
        this.$refs.menu.style.display = 'block'
      }, 50)

      this.$router.push({ name: 'category', query: { id: item.id, level: item.level } })
    }
  }
}
</script>

<style></style>

<style scoped>
@import url('../../assets/css/0c8c4e70a20671fb.dl.css');
@import url('../../assets/css/4e2ed820bc8c3428.dl.css');
@import url('../../assets/css/10bd5da474c70b3a.dl.css');
</style>
<style scoped>
.hide {
  visibility: hidden;
  opacity: 0;
}

.h2menu {
  visibility: hidden;
  opacity: 0;
}
.h2head-ul li:hover .h2menu {
  visibility: visible;
  opacity: 1;
}
</style>
