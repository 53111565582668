<template>
  <div class="nav-header">
    <span class="nv-title">{{ $t('store.setting') }}</span>
    <!-- <span class="nv-desc">这是订单的管理页面，可管理用户的订单</span> -->
  </div>
  <!-- 3 -->
  <a-card>
    <div>
      {{ $t('store.home.title') }}
    </div>
    <a-form :model="home" name="basic" :label-col="{ span: 4 }" :wrapper-col="{ span: 24 }" autocomplete="off" @finish="onSaveHome" @finishFailed="onSaveHomeFailed">
      <!-- 封面图 -->
      <a-form-item :label="$t('store.home.topimg')">
        <upload-file @onUpload="onTopUpload" :imageUrl="topImageUrl"></upload-file>
      </a-form-item>

      <!-- 商品多张图 -->
      <a-form-item :label="$t('store.home.banner')">
        <div style="color: #00000073">{{ $t('store.home.up3') }}</div>
        <upload-multi-file @onUpload="onBannerUpload" @onDelete="onDeleteUpload" :imageUrl="bannerImgs"></upload-multi-file>

        <a-modal :visible="previewVisible" :footer="null" @cancel="onBannerCancel">
          <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
      </a-form-item>

      <!-- 板块 -->
      <a-form-item :label="$t('store.home.columns')" hidden>
        <!-- 属性列表 -->
        <a-card :bordered="true">
          <a-card v-for="(item, i) in columns" :key="i" :bordered="true" style="margin-bottom: 16px">
            <a-row :gutter="[16, 16]">
              <div style="height: 15px">
                <delete-outlined @click="onDelAttr(i)" class="del-btn" />
              </div>
              <!-- 封面图 -->
              <a-form-item :label="$t('store.home.bgimg')" :required="true">
                <upload-file @onUpload="onColumnsUpload($event, i)" :imageUrl="columns[i] ? columns[i].bgimg + '?r=' + Math.floor(Math.random() * 1000) : ''"></upload-file>
              </a-form-item>
              <!-- 商品列表 -->
              <a-col :span="24">
                <div style="color: #00000073">{{ $t('store.goods.remark') }}</div>
                <a-space :size="8" style="display: flex; flex-wrap: wrap">
                  <div v-for="(vo, k) in item.goods" :key="k">
                    <div style="border: 1px #ccc dashed; cursor: pointer; display: grid; width: 99px" @click="onDelGoods(i, k)">
                      <img :src="vo.img + '?r=' + Math.floor(Math.random() * 1000)" alt="" style="width: 100px; height: 100px" />
                      <div class="vo-name">{{ vo.name }}</div>
                      <close-outlined style="border-top: 1px dashed rgb(204, 204, 204)" />
                    </div>
                  </div>
                </a-space>
                <div class="op-box">
                  <a-button @click="onAddGoods(i)"> <plus-circle-outlined /> {{ $t('store.home.select') }} </a-button>
                </div>
              </a-col>
            </a-row>
          </a-card>
          <a-row>
            <a-col :span="24">
              <a-button @click="onAddAttr()"> <plus-circle-outlined />{{ $t('store.home.add') }} </a-button>
            </a-col>
          </a-row>
        </a-card>
      </a-form-item>

      <a-form-item :wrapper-col="{ offset: 4, span: 8 }">
        <a-button type="primary" html-type="submit" :loading="btnHomeLoad">{{ $t('setting.shop.save') }}</a-button>
      </a-form-item>
    </a-form>
    <select-goods ref="selectGoods" :visible="visible" @cancel="selectCancel" @ok="selectOk" />
  </a-card>
</template>

<script>
import { PlusCircleOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons-vue'

import UploadFile from '@/components/UploadFile.vue'
import UploadMultiFile from '@/components/UploadMultiFile.vue'
import SelectGoods from '@/components/store/SelectGoods.vue'
export default {
  name: 'settingVue',
  components: {
    PlusCircleOutlined,
    DeleteOutlined,
    CloseOutlined,
    SelectGoods,
    UploadFile,
    UploadMultiFile
  },
  data() {
    return {
      // 加载图片
      loadingImage: false,
      imageUrl: '',
      img: '',

      //个人保存
      btnLoad: false,

      // 上传顶部图
      topLoading: false,
      topImageUrl: '',
      topImg: '',

      // 上传轮播图
      loadingImageMore: false,
      bannerImgs: [],
      previewVisible: false,
      previewImage: '',

      // 栏目
      columns: [],

      // 选择商品
      visible: false,
      selectLoading: false,
      currentSort: 0,

      //首页保存
      home: {},
      btnHomeLoad: false
    }
  },
  created() {
    this.$api.store.getHome().then((res) => {
      // 封面图
      if (res.data) {
        this.topImageUrl = res.data.banner
        this.topImg = ''
        this.content = res.data.content
        this.columns = res.data.columns
        this.bannerImgs = res.data.imgs
      }
    })
  },
  methods: {
    onTopUpload(img) {
      this.topImageUrl = img
      this.topImg = img
    },
    // 取消预览
    onBannerCancel() {
      this.previewVisible = false
    },
    onBannerUpload(imgs) {
      console.log(imgs)
      this.bannerImgs = imgs
    },
    onDeleteUpload(imgs) {
      console.log(imgs)
      //this.bannerImgs = imgs
    },
    /**--------------------------------
     * 增加
     */
    onAddAttr() {
      this.columns.push({
        bgimg: '',
        bgload: false,
        goods: []
      })
    },
    // 删除板块
    onDelAttr(i) {
      this.columns.splice(i, 1)
    },
    /**
     * 上传图
     * @param {*} img
     * @param {*} callback
     */
    onColumnsUpload(img, i) {
      this.columns[i].bgimg = img
    },
    /**
     * 选择商品
     */
    onAddGoods(sort) {
      this.currentSort = sort
      this.visible = true
    },
    // 删除商品
    onDelGoods(i, k) {
      this.columns[i].goods.splice(k, 1)
    },
    selectCancel() {
      this.visible = false
    },
    selectOk(selectedRows) {
      console.log(selectedRows)
      this.confirmLoading = true

      const that = this
      selectedRows.forEach((item) => {
        if (that.columns[that.currentSort].goods.length >= 10) {
          return
        }
        that.columns[that.currentSort].goods.push({
          id: item.id,
          name: item.name,
          img: item.img
        })
      })
      console.log('values', that.columns)
      this.confirmLoading = false
      this.visible = false
    },
    onSaveHome(values) {
      this.btnHomeLoad = true
      console.log('params:', values)
      //参数
      values['img'] = this.topImg
      values['imgs'] = this.bannerImgs
      values['columns'] = this.columns

      let that = this
      this.$api.store
        .saveHome(values)
        .then((res) => {
          console.log(res)
          that.btnHomeLoad = false
          if (res.code == 1) {
            that.$message.warning(res.msg)
          } else {
            that.$message.success(res.msg)
          }
        })
        .catch(() => {
          that.btnHomeLoad = false
        })
    },
    onSaveHomeFailed(errorInfo) {
      console.log('Failed:', errorInfo)
    }
  }
}
</script>
<style scoped src="../../../static/css/store-css.css"></style>

<style>
.a-del {
  position: absolute;
  right: 10px;
  top: 0;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
}

.vo-name {
  font-size: 12px;
  white-space: normal;
  height: 38px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.op-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.del-btn {
  position: absolute;
  right: 5px;
  top: 5px;
}
</style>
