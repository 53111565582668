<template>
  <div class="container" style="display: flex; align-items: center; justify-content: center">
    <a-card style="margin: 20px 0; width: 1400px">
      <Loading v-if="loading"></Loading>
      <div class="docs-title">{{ docs.title }}</div>
      <div v-html="docs.content"></div>
    </a-card>
  </div>
</template>

<script>
import Loading from '@/components/base/Loading.vue'
export default {
  name: 'docsVue',
  components: {
    Loading
  },
  data() {
    return {
      docs: {},
      key: '',
      loading: false
    }
  },
  watch: {
    $route(newRoute) {
      console.log(newRoute)
      this.$router.go(0)
    }
  },
  created() {
    this.key = this.$route.query.key
    this.getDocs()
  },
  methods: {
    languageNotify(e) {
      console.log(e, 'docs lang')
      this.getDocs()
    },
    getDocs() {
      this.loading = true
      //获取文章
      let that = this
      this.$api.docs
        .getDocs({ key: this.key })
        .then((res) => {
          this.loading = false
          that.docs = res.data
        })
        .catch((err) => {
          err
        })
    }
  }
}
</script>

<style>
.docs-title {
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  padding: 20px 0;
}

.container strong {
  font-weight: 700 !important;
}
</style>
