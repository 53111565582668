<template>
  <div id="root">
    <div class="account-setting">
      <div class="setting-title">
        <p class="setting-title-text">{{$t('member.setting')}}</p>
      </div>
      <div class="setting-container">
        <div class="setting-section">
          <p class="setting-section-title">{{$t('setting.update.user')}}</p>
          <div class="setting-section-content">
            <div class="setting-section-content-item">
              <div>
                <div class="u-avatar">
                  <img :src="user.avatar" alt="">
                </div>
                <div style="margin-bottom: 10px;">
                  {{ user.nickname }}
                </div>
                <a class="setting-section-content-item-text" @click="onUpdate()">{{$t('setting.update.info')}}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="setting-section">
          <p class="setting-section-title">{{$t('setting.safe.title')}}</p>
          <div class="setting-section-content">
            <div class="setting-section-content-item">
              <a class="setting-section-content-item-text" @click="onPasswd()">{{$t('setting.user.passwd')}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 修改信息弹框 -->
    <UpdateInfo ref="updateinfo" @onSuccess="onGetInfo"></UpdateInfo>

    <!-- 修改信息弹框 -->
    <UpdatePasswd ref="updatepasswd" @onSuccess="onGetInfo"></UpdatePasswd>
  </div>
</template>

<script>
import UpdateInfo from "@/components/member/UpdateInfo.vue"
import UpdatePasswd from "@/components/member/UpdatePasswd.vue"
export default {
  name: "settingVue",
  components: {
    UpdateInfo,
    UpdatePasswd
  },
  data () {
    return {
      user: {}
    }
  },
  created () {
    this.onGetInfo()
  },
  methods: {
    onUpdate () {
      this.$refs.updateinfo.show();
    },
    onGetInfo(){
      this.user = this.$common.getInfo()
    },
    onPasswd () {
      this.$refs.updatepasswd.show();
    },
  }
};
</script>
<style scoped src="@/static/css/setting.css"></style>
<style scoped>
.u-avatar {
  width: 50px;
  height: 50px;
  overflow: hidden;
  margin-bottom: 10px;
}
.u-avatar img {
  width: 100%;
}
</style>