import Layout from '../view/order/layout.vue'
import Cart from '../view/order/cart.vue'
import Pay from '../view/order/pay.vue'

const Router = [
  {
    path: '/',
    name: 'orderPay',
    component: Layout,
    redirect: '/cart',
    children: [
      {
        path: '/cart',
        name: 'cart',
        component: Cart
      },
      {
        path: '/pay',
        name: 'pay',
        component: Pay
      }
    ]
  }
]
export default Router
