<template>
  <!-- 推荐滚动商品栏 -->
  <div class="t1" v-if="recommend.length > 0">Recommend</div>
  <div class="bk01" v-if="recommend.length > 0">
    <div class="slick-slider slick-initialized bk02">
      <button type="button" class="slick-arrow slick-prev sl-prev-g">Previous</button>
      <div class="slick-list">
        <div class="slick-track">
          <swiper
            :modules="modules"
            :slidesPerView="5"
            :pagination="{
              el: '.banner-slick-dots',
              type: 'custom',
              renderCustom: onRenderCustom
            }"
            :navigation="{
              nextEl: '.sl-next-g',
              prevEl: '.sl-prev-g'
            }"
            :loop="true"
            :autoplay="{
              delay: 5000,
              disableOnInteraction: false
            }"
            class="bk03"
          >
            <swiper-slide v-for="(item, i) in recommend" :key="i" style="padding: 0 5px">
              <div class="slick-slide slick-active slick-current" style="outline: none; width: 100%; height: 400px" v-if="item.goods">
                <div class="gi-item" @click="$router.push({ name: 'detail', query: { id: item.id } })">
                  <div class="gi-img">
                    <img :src="item.goods.img" alt="" />
                  </div>
                  <div class="gi-info">
                    <div class="gi-name">{{ item.goods.name }}</div>
                    <div class="gi-price-box">
                      <div class="gi-price">US ${{ item.goods.price }}</div>
                      <div class="gi-cost-price">/US ${{ item.goods.cost_price }}</div>
                      <div class="gi-dist">-{{ $common.zkPrice(item.goods.price, item.goods.cost_price) }}%</div>
                    </div>
                    <div class="gi-price-box gi-btn">
                      <img src="../../static/temp/1/bt1.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <button type="button" data-role="none" class="slick-arrow slick-next sl-next-g">Next</button>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination, Autoplay } from 'swiper'
export default {
  props: {
    store_id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      modules: [Navigation, Pagination, Autoplay],
      recommend: []
    }
  },
  created() {
    this.getStoreRecommend()
  },
  methods: {
    getStoreRecommend() {
      this.$api.storeInfo.getStoreRecommend({ id: this.store_id }).then((res) => {
        this.recommend = res.data.recommend
      })
    }
  }
}
</script>
<style scoped src="@/static/css/index.css"></style>
<style scoped>
.t1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 65px;
  color: #e74f41;
  font-weight: 700;
  padding-top: 20px;
  background-color: #f4f4f4;
}
.bk01 {
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  background-color: #f4f4f4;
  position: relative;
  padding: 20px 0 50px 0;
}

.bk02 {
  width: 1320px;
}

.gi-item {
  height: 400px;
  overflow: hidden;
  border-radius: 10px;
  background-color: #fff;
}

.gi-img {
  overflow: hidden;
  height: 70%;
}

.gi-img img {
  width: auto;
  height: 100%;
}

.gi-info {
  height: 30%;
}

.gi-name {
  font-size: 18px;
  white-space: nowrap;
  font-weight: 600;
  text-overflow: ellipsis;
  padding: 0 15px;
  overflow: hidden;
}

.gi-price-box {
  display: flex;
  padding: 0 0px;
  align-items: center;
  justify-content: center;
}

.gi-price {
  font-size: 15px;
  font-weight: 700;
  color: #4d53a1;
}

.gi-cost-price {
  font-size: 14px;
  color: #999;
  font-weight: 600;
  margin-left: 5px;
}

.gi-dist {
  padding: 0px 5px;
  background-color: #e44c3e;
  color: #fff;
  border-radius: 15px;
  font-weight: 600;
  margin-left: 5px;
  font-size: 12px;
}

.gi-btn {
  margin-top: 5px;
  cursor: pointer;
}
</style>
