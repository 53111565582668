<template>
  <!-- 主界面 -->
  <main>
    <!-- 广告 -->
    <!-- <router-link :to="{ name: 'category', query: { id: 15, level: 3 } }">
      <section class="jj">
        <div class="bsdf21">
          <div class="sdfa212">
            <img src="../../assets/img/13860317623845424618.jpeg" alt="" />
          </div>
          <div class="sdfa212 sdeew">
            <span class="firstTitle"> Say "I Love You" with I Want Choo </span>
            <span class="secondTitle"> Earn 5X the points on beauty! See restrictions. </span>
            <span class="thirdTitle"> Shop Now </span>
          </div>
        </div>
      </section>
    </router-link> -->
    <!-- banner -->
    <banner id="top"></banner>

    <!--  您的热门精选 -->
    <section class="jh -jl">
      <div class="jj" style="">
        <header class="jm">
          <h2 class="bix biW biz jn" data-testid="typography">
            <span class="jo"
              ><span style="vertical-align: inherit"
                ><span style="vertical-align: inherit">{{ $t('_zdy._rmjx') }}</span></span
              ></span
            >
          </h2>
        </header>
        <div class="Jd">
          <swiper
            :modules="modules"
            :slidesPerView="8"
            :slidesPerGroup="2"
            :spaceBetween="16"
            :navigation="{
              nextEl: '.sl3-next',
              prevEl: '.sl3-prev'
            }"
            :loop="true"
            :autoplay="{
              delay: 4000,
              disableOnInteraction: false
            }"
            class="Jl"
          >
            <swiper-slide class="__7-" v-for="(vo, i) in recommend" :key="i">
              <section class="Ig Ij Im __9">
                <div class="bgu bgx bgy" aria-hidden="true">
                  <div class="pU p_0 bgw">
                    <picture class="HI">
                      <source media="(min-width: 1024px)" srcset="@/static/svg/download.svg" type="image/svg" />
                      <source media="(min-width: 600px) and (max-width: 1023px)" srcset="@/static/svg/download.svg" type="image/svg" />
                      <source media="(max-width: 599px)" srcset="@/static/svg/download.svg" type="image/svg" />
                      <img class="pV pX pW" :alt="vo.goods && vo.goods.name" :src="vo.goods && vo.goods.img" fetchpriority="auto" width="240" height="240" />
                    </picture>
                  </div>
                </div>
                <div class="bg_0 bg_1">
                  <div class="bg_2">
                    <span class="bg_3 bg_5"
                      ><span style="vertical-align: inherit">
                        <span style="vertical-align: inherit">$ {{ vo.goods && vo.goods.price }}</span>
                      </span>
                    </span>
                  </div>
                </div>
                <router-link class="Iq" :to="{ name: 'detail', query: { id: vo.id } }">
                  <span class="Ip2">
                    <span style="vertical-align: inherit">
                      <span style="vertical-align: inherit">{{ vo.goods && vo.goods.name }}</span>
                    </span>
                  </span>
                </router-link>
                <div class="bhc">
                  <span class="WVbEJ tKey9">
                    <span role="img" class="T2Mzf" aria-label="4 out of 5 stars">
                      <span class="GocDN dUBUg"></span>
                      <span class="GocDN bFCnj" :style="'width: ' + 20 * parseInt(vo.star) + '%'"></span>
                    </span>
                    <!-- <span class="HZv8u">(219)</span> -->
                  </span>
                  <span class="bhf"> </span>
                </div>
              </section>
            </swiper-slide>
          </swiper>
          <button type="button" role="button" class="fS Je gg ga f_1 gn gi gu gt sl3-prev">
            <div class="fU">
              <div class="fV">
                <i class="eO eS" aria-hidden="true">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                    <use xlink:href="#arrow_short_left"></use>
                  </svg>
                </i>
              </div>
            </div>
          </button>
          <button type="button" role="button" class="fS Jf gg ga f_1 gn gi gu gt sl3-next">
            <div class="fU">
              <div class="fV">
                <i class="eO eS" aria-hidden="true"
                  ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                    <use xlink:href="#arrow_short_right"></use>
                  </svg>
                </i>
              </div>
            </div>
          </button>
        </div>
      </div>
    </section>

    <!-- 广告 -->
    <section class="jj">
      <div class="bsdf21">
        <div class="">
          <img src="../../assets/img/free.gif" alt="" style="width: 100%" />
        </div>
        <div class="idskl2">
          <span class="diekst">{{ $t('_zdy._mfps') }}</span>
          <span class="">
            <a>{{ $t('_zdy._gmsp') }}</a>
          </span>
        </div>
      </div>
    </section>

    <!--  超值优惠，尽享您喜爱的商品 -->
    <section class="jh -jk">
      <div class="jj">
        <header class="jm">
          <h2 class="bix biW biz jn" data-testid="typography">
            <span class="jo">{{ $t('_zdy._czyh') }}</span>
          </h2>
        </header>
        <div class="Jd">
          <swiper
            :modules="modules"
            :slidesPerView="6"
            :spaceBetween="24"
            :navigation="{
              nextEl: '.sl2-next',
              prevEl: '.sl2-prev'
            }"
            :loop="false"
            :autoplay="{
              delay: 6000,
              disableOnInteraction: false
            }"
            class="Jl"
          >
            <swiper-slide class="bhDD" v-for="(vo, i) in superDeals" :key="i">
              <section class="Ig Ij Im __9">
                <div class="bgu bgx bgy" aria-hidden="true">
                  <div class="pU p_0 bgw">
                    <picture class="HI">
                      <source media="(min-width: 1024px)" srcset="@/static/svg/download.svg" type="image/svg" />
                      <source media="(min-width: 600px) and (max-width: 1023px)" srcset="@/static/svg/download.svg" type="image/svg" />
                      <source media="(max-width: 599px)" srcset="@/static/svg/download.svg" type="image/svg" />
                      <img class="pV pX pW" :alt="vo.goods && vo.goods.name" :src="vo.goods && vo.goods.img" fetchpriority="auto" width="240" height="240"
                    /></picture>
                  </div>
                </div>
                <div class="bg_0 bg_1">
                  <div class="bg_2">
                    <span class="bg_3 bg_5">
                      <div style="vertical-align: inherit">
                        <div style="vertical-align: inherit">$ {{ vo.goods && vo.goods.price }}</div>
                      </div>
                    </span>
                    <!-- <span class="bg_4">
												<div style="vertical-align: inherit">
													<div style="vertical-align: inherit">$ {{vo.goods && vo.goods.cost_price}}</div>
												</div>
											</span> -->
                  </div>
                </div>
                <router-link :to="{ name: 'detail', query: { id: vo.id } }" class="Iq">
                  <span class="Ip2">
                    <span style="vertical-align: inherit">
                      <span style="vertical-align: inherit">{{ vo.goods && vo.goods.name }}</span>
                    </span>
                  </span>
                </router-link>
                <div class="bg_0 bg_1">
                  <div class="bhc">
                    <span class="bhf"> </span>
                  </div>
                  <div class="bhc">
                    <span class="bhf">
                      <!-- <span style="vertical-align: inherit">
                        <span style="vertical-align: inherit">{{ vo.orders }} orders</span> </span
                      >&nbsp; -->
                    </span>
                  </div>
                </div>
              </section>
            </swiper-slide>
          </swiper>
          <!-- 左侧按钮 -->
          <button type="button" role="button" class="fS Je gg ga f_1 gn gi gu gt sl2-prev">
            <div class="fU">
              <div class="fV">
                <i class="eO eS" aria-hidden="true">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                    <use xlink:href="#arrow_short_left"></use>
                  </svg>
                </i>
              </div>
            </div>
          </button>
          <!-- 右侧按钮 -->
          <button type="button" role="button" class="fS Jf gg ga f_1 gn gi gu gt sl2-next">
            <div class="fU">
              <div class="fV">
                <i class="eO eS" aria-hidden="true">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                    <use xlink:href="#arrow_short_right"></use>
                  </svg>
                </i>
              </div>
            </div>
          </button>
        </div>
      </div>
    </section>

    <!--  plusSale -->
    <section class="jh jp">
      <div class="jj">
        <header class="jm">
          <h2 class="bix biW biz jn" data-testid="typography">
            <span class="jo">
              <span style="vertical-align: inherit">
                <span style="vertical-align: inherit">{{ $t('_n._rmsp') }}</span>
              </span>
            </span>
          </h2>
        </header>
        <div class="Jd">
          <swiper
            :modules="modules"
            :slidesPerView="8"
            :slidesPerGroup="5"
            :spaceBetween="16"
            :navigation="{
              nextEl: '.sl4-next',
              prevEl: '.sl4-prev'
            }"
            :loop="false"
            :autoplay="{
              delay: 5000,
              disableOnInteraction: false
            }"
            class="__5 Jl"
          >
            <swiper-slide class="__7-" v-for="(vo, i) in plusSale" :key="i">
              <section class="Ig Ij Im __9">
                <div class="_7_6 _1T">
                  <div class="Rd Rg">
                    <div class="Re"></div>
                  </div>
                </div>
                <div class="bgu bgx bgy" aria-hidden="true">
                  <div class="pU p_0 bgw">
                    <picture class="HI">
                      <source media="(min-width: 1024px)" srcset="@/static/svg/download.svg" type="image/svg" />
                      <source media="(min-width: 600px) and (max-width: 1023px)" srcset="@/static/svg/download.svg" type="image/svg" />
                      <source media="(max-width: 599px)" srcset="@/static/svg/download.svg" type="image/svg" />
                      <img class="pV pX pW" :alt="vo.goods && vo.goods.name" :src="vo.goods && vo.goods.img" fetchpriority="auto" width="240" height="240" />
                    </picture>
                  </div>
                </div>
                <div class="bg_0 bg_1">
                  <span class="bg_8">
                    <span style="vertical-align: inherit">
                      <span style="vertical-align: inherit">-</span>
                    </span>
                    <span class="bha">
                      <span style="vertical-align: inherit">
                        <span style="vertical-align: inherit">{{ vo.goods && $common.zkPrice(vo.goods.price, vo.goods.cost_price) }}</span>
                      </span>
                    </span>
                    <span style="vertical-align: inherit">
                      <span style="vertical-align: inherit">%</span>
                    </span>
                  </span>
                  <div class="bg_2">
                    <span class="bg_3 bg_5">
                      <span style="vertical-align: inherit">
                        <span style="vertical-align: inherit">$ {{ vo.goods && vo.goods.price }}</span>
                      </span>
                    </span>
                    <span class="bg_4">
                      <span style="vertical-align: inherit">
                        <span style="vertical-align: inherit">$ {{ vo.goods && vo.goods.cost_price }}</span>
                      </span>
                    </span>
                  </div>
                </div>

                <router-link :to="{ name: 'detail', query: { id: vo.id } }" class="Iq">
                  <span class="Ip2">
                    <span style="vertical-align: inherit">
                      <span style="vertical-align: inherit">{{ vo.goods && vo.goods.name }}</span>
                    </span>
                  </span>
                </router-link>
                <div class="bhc">
                  <span class="WVbEJ tKey9">
                    <span role="img" class="T2Mzf" aria-label="4 out of 5 stars">
                      <span class="GocDN dUBUg"></span>
                      <span class="GocDN bFCnj" :style="'width: ' + 20 * parseInt(vo.star) + '%'"></span>
                    </span>
                    <!-- <span class="HZv8u">(219)</span> -->
                  </span>
                  <span class="bhf"> </span>
                </div>
                <span class="bg_8_1 bg_8_btn" @click="$router.push({ name: 'detail', query: { id: vo.id } })">
                  <span class="bha_1">
                    <div style="vertical-align: inherit">
                      <div style="vertical-align: inherit">{{ $t('_n._ljgm') }}</div>
                    </div>
                  </span>
                </span>
              </section>
            </swiper-slide>
          </swiper>
          <button type="button" role="button" class="fS Je gg ga f_1 gn gi gu gt sl4-prev">
            <div class="fU">
              <div class="fV">
                <i class="eO eS" aria-hidden="true">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                    <use xlink:href="#arrow_short_left"></use>
                  </svg>
                </i>
              </div>
            </div>
          </button>
          <button type="button" role="button" class="fS Jf gg ga f_1 gn gi gu gt sl4-next">
            <div class="fU">
              <div class="fV">
                <i class="eO eS" aria-hidden="true"
                  ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                    <use xlink:href="#arrow_short_right"></use>
                  </svg>
                </i>
              </div>
            </div>
          </button>
        </div>
      </div>
    </section>

    <!-- 快捷分类 -->
    <section class="jj">
      <div class="un408 vaT37 QUN1Y BYAjq" style="border-top: 3px solid #000">
        <div>
          <div class="InxVx" style="padding-top: 4.49775%"></div>
        </div>
      </div>
      <div class="un408 QUN1Y BYAjq">
        <div>
          <div class="jATLo">
            <div class="rBV41 TqjVp">
              <div class="un408 QUN1Y BYAjq">
                <div>
                  <section class="XaJP8">
                    <div class="">
                      <div class="PiUHT fBKre JDS6c rie47">
                        <h4 class="NJIVb qhMT8 Ouhbi _NbrB bLL0c">
                          <p><strong>SHOP BY CATEGORY</strong></p>
                        </h4>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="SDFW">
                <div class="un408 QUN1Y FU53P XKCD">
                  <div>
                    <section class="XaJP8">
                      <div class="RY6IU">
                        <a href="/#/category?id=15&level=3" class="wp1H6" data-eltype="PHOTO" title="Women">
                          <div class="">
                            <div class="Xdo9E idJS8 HqNaA" style="padding-bottom: 136.194%">
                              <img class="FLQqr" loading="eager" src="../../static/cate/1.png" />
                            </div>
                          </div>
                        </a>
                      </div>
                      <div class="M51ZR">
                        <div class="PiUHT fBKre JDS6c rie47">
                          <h4 class="NJIVb qhMT8 Ouhbi _NbrB">
                            <p><strong>Women</strong></p>
                          </h4>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <div class="un408 QUN1Y FU53P XKCD">
                  <div>
                    <section class="XaJP8">
                      <div class="RY6IU">
                        <a href="/#/category?id=68&level=3" class="wp1H6" data-eltype="PHOTO" title="Men">
                          <div class="">
                            <div class="LutYS Xdo9E idJS8 HqNaA" style="padding-bottom: 136.194%">
                              <img class="FLQqr" loading="lazy"  src="../../static/cate/2.png" />
                            </div>
                          </div>
                        </a>
                      </div>
                      <div class="M51ZR">
                        <div class="PiUHT fBKre JDS6c rie47">
                          <h4 class="NJIVb qhMT8 Ouhbi _NbrB">
                            <p><strong>Men</strong></p>
                          </h4>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <div class="un408 QUN1Y FU53P XKCD">
                  <div>
                    <section class="XaJP8">
                      <div class="RY6IU">
                        <a href="/#/category?id=353&level=3" class="wp1H6" data-eltype="PHOTO" title="Kids">
                          <div class="">
                            <div class="LutYS Xdo9E idJS8 HqNaA" style="padding-bottom: 136.194%">
                              <img class="FLQqr" loading="lazy"  src="../../static/cate/3.png" />
                            </div>
                          </div>
                        </a>
                      </div>
                      <div class="M51ZR">
                        <div class="PiUHT fBKre JDS6c rie47">
                          <h4 class="NJIVb qhMT8 Ouhbi _NbrB">
                            <p><strong>Kids</strong></p>
                          </h4>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <div class="un408 QUN1Y FU53P XKCD">
                  <div>
                    <section class="XaJP8">
                      <div class="RY6IU">
                        <a href="/#/category?id=394&level=3" class="wp1H6" data-eltype="PHOTO" title="Designer">
                          <div class="">
                            <div class="LutYS Xdo9E idJS8 HqNaA" style="padding-bottom: 136.194%">
                              <img class="FLQqr" loading="lazy" src="../../static/cate/4.png" />
                            </div>
                          </div>
                        </a>
                      </div>
                      <div class="M51ZR">
                        <div class="PiUHT fBKre JDS6c rie47">
                          <h4 class="NJIVb qhMT8 Ouhbi _NbrB">
                            <p><strong>Toy</strong></p>
                          </h4>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <div class="un408 QUN1Y FU53P XKCD">
                  <div>
                    <section class="XaJP8">
                      <div class="RY6IU">
                        <a href="/#/category?id=307&level=3" class="wp1H6" data-eltype="PHOTO" title="Home">
                          <div class="">
                            <div class="LutYS Xdo9E idJS8 HqNaA" style="padding-bottom: 136.194%">
                              <img class="FLQqr" loading="lazy"  src="../../static/cate/5.png" />
                            </div>
                          </div>
                        </a>
                      </div>
                      <div class="M51ZR">
                        <div class="PiUHT fBKre JDS6c rie47">
                          <h4 class="NJIVb qhMT8 Ouhbi _NbrB">
                            <p><strong>Home</strong></p>
                          </h4>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <div class="un408 QUN1Y FU53P XKCD">
                  <div>
                    <section class="XaJP8">
                      <div class="RY6IU">
                        <a href="/#/category?id=459&level=3" class="wp1H6" data-eltype="PHOTO" title="Beauty &amp; Fragrance">
                          <div class="">
                            <div class="LutYS Xdo9E idJS8 HqNaA" style="padding-bottom: 136.194%">
                              <img class="FLQqr" loading="lazy"  src="../../static/cate/6.png" />
                            </div>
                          </div>
                        </a>
                      </div>
                      <div class="M51ZR">
                        <div class="PiUHT fBKre JDS6c rie47">
                          <h4 class="NJIVb qhMT8 Ouhbi _NbrB">
                            <p><strong>Beauty</strong></p>
                          </h4>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--  goodsData -->
    <section class="jh _1P ji -jk" style="padding-bottom: 55px" :infinite-scroll-immediate-check="false" :infinite-scroll-disabled="scrollDisabled" infinite-scroll-watch-disabled="scrollDisabled" v-infinite-scroll="loadMore" infinite-scroll-distance="5">
      <div class="jj">
        <header class="jm">
          <h2 class="bix biW biz jn">
            <span class="jo">{{ $t('_n._gdsp') }}</span>
          </h2>
        </header>
        <ul class="bhC">
          <section id="5036759" class="Ig Ij Io uC" v-for="(vo, i) in goodsData" :key="i">
            <div class="bgE bgN bgR" aria-hidden="true">
              <router-link :to="{ name: 'detail', query: { id: vo.id } }" class="Iq">
                <div class="bgM">
                  <div class="bgT"></div>
                  <div class="bgT" v-for="(vvo, ii) in vo.imgs" :key="ii"></div>
                  <ul class="bgJ">
                    <li class="bgK">
                      <div class="pU p_0 bgI p_4">
                        <picture class="HI">
                          <source srcset="@/static/svg/download.svg" type="image/svg" />
                          <img class="pV pX pW" :src="vo.img" fetchpriority="auto" width="240" height="240" />
                        </picture>
                      </div>
                    </li>
                    <li class="bgK" v-for="(vvo, ii) in vo.imgs" :key="ii">
                      <div class="pU p_0 bgI p_4">
                        <picture class="HI">
                          <source srcset="@/static/svg/download.svg" type="image/svg" />
                          <img class="pV pX pW" :src="vvo.thumb" fetchpriority="auto" width="240" height="240" />
                        </picture>
                      </div>
                    </li>
                    <li class="bgK"><div class="pU p_0 bgI p_4"></div></li>
                  </ul>
                  <ul class="bgU">
                    <li class="bgV"></li>
                    <li class="bgV" v-for="(vvo, ii) in vo.imgs" :key="ii"></li>
                  </ul>
                </div>
              </router-link>
            </div>
            <div class="bg_0 bg_1">
              <span class="bg_8"
                >−<span class="bha">{{ $common.zkPrice(vo.price, vo.cost_price) }}</span
                >%</span
              >
              <div class="bg_2">
                <span class="bg_3 bg_5">$ {{ vo.price }}</span>
                <span class="bg_4">$ {{ vo.cost_price }}</span>
              </div>
            </div>
            <router-link :to="{ name: 'detail', query: { id: vo.id } }" class="Iq">
              <span class="Ip">{{ vo.name }}</span>
            </router-link>
            <!-- <div class="bhc">
              <span class="WVbEJ tKey9">
                <span role="img" class="T2Mzf" aria-label="4 out of 5 stars">
                  <span class="GocDN dUBUg"></span>
                  <span class="GocDN bFCnj" :style="'width: ' + 20 * parseInt(vo.star) + '%'"></span>
                </span>
              </span>
              <span class="bhf"> </span>
            </div> -->
            <ul class="bhg">
              <li class="bhh">
                <div class="R_5 Sc R_8 Se">
                  <div class="Sd">
                    <span style="vertical-align: inherit"><span style="vertical-align: inherit">Free Shipping</span></span>
                  </div>
                </div>
              </li>
            </ul>
          </section>
        </ul>
        <!-- 加载更多 -->
        <div v-show="loadmore" class="_load">
          <img class="_2O2Fm" src="@/static/img/loading.gif" />
        </div>
      </div>
    </section>

    <!--  topStore -->
    <!-- <section class="jh jp" hidden>
      <div class="jj">
        <header class="jm">
          <h2 class="bix biW biz jn" data-testid="typography">
            <span class="jo">{{ $t('_n._yzsj') }}</span>
          </h2>
        </header>
        <div class="Jd">
          <swiper
            :modules="modules"
            :slidesPerView="8"
            :spaceBetween="24"
            :navigation="{
              nextEl: '.sl2-next',
              prevEl: '.sl2-prev'
            }"
            :loop="false"
            :autoplay="{
              delay: 6000,
              disableOnInteraction: false
            }"
            class="__5 Jl"
          >
            <swiper-slide class="__7" v-for="(vo, i) in topStore" :key="i">
              <section class="Ig Ij Im __9">
                <div class="_7_6 _1T" hidden>
                  <div class="Rd Rg">
                    <div class="Re">
                      <button type="button" role="button" class="fS fW ga f_ gp gm">
                        <div class="fU">
                          <div class="fV">
                            <span class="_8a"
                              ><i class="eO eS _7_8 _7_9 _8d" aria-hidden="true">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                                  <use xlink:href="#heartHollowBg"></use></svg></i
                            ></span>
                          </div>
                          <div class="fV"><span class="_8f">喜欢</span></div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="bgu bgx bgy bgv" aria-hidden="true">
                  <div class="pU p_0 bgw">
                    <picture class="HI">
                      <source media="(min-width: 1024px)" srcset="@/static/svg/download.svg" type="image/svg" />
                      <source media="(min-width: 600px) and (max-width: 1023px)" srcset="@/static/svg/download.svg" type="image/svg" />
                      <source media="(max-width: 599px)" srcset="@/static/svg/download.svg" type="image/svg" />
                      <img class="pV pX pW" :alt="vo.name" :src="vo.avatar" fetchpriority="auto" width="240" height="240"
                    /></picture>
                  </div>
                </div>

                <div class="bg_8_1 bg_8_btn" @click="$router.push({ name: 'shopIndex', query: { id: vo.id } })">
                  <div class="bha_1" style="width: 100%">
                    <div style="vertical-align: inherit">
                      <div style="vertical-align: inherit; overflow: hidden; text-overflow: ellipsis; text-align: center">{{ vo.name }}</div>
                    </div>
                  </div>
                </div>
                <router-link :to="{ name: 'shopIndex', query: { id: vo.id } }" class="Iq"></router-link>
              </section>
            </swiper-slide>
          </swiper>
          <button type="button" role="button" class="fS Je gg ga f_1 gn gi gu gt">
            <div class="fU">
              <div class="fV">
                <i class="eO eS" aria-hidden="true"
                  ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                    <use xlink:href="#arrow_short_left"></use>
                  </svg>
                </i>
              </div>
            </div>
          </button>
          <button type="button" role="button" class="fS Jf gg ga f_1 gn gi gu gt">
            <div class="fU">
              <div class="fV">
                <i class="eO eS" aria-hidden="true">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                    <use xlink:href="#arrow_short_right"></use>
                  </svg>
                </i>
              </div>
            </div>
          </button>
        </div>
      </div>
    </section> -->

    <!-- 回到顶部 -->
    <div class="vYF33" style="position: fixed; top: 0px; left: 0; z-index: 1000" v-show="isTop">
      <div class="YVqE3">
        <div class="vMQ3i">
          <div class="rBV41 TqjVp">
            <div class="QUN1Y qhPJz">
              <router-link class="tqdF7" to="#top">
                <svg width="24px" height="13px" focusable="false" class="nui-icon nui-icon-large-chevron-up">
                  <path id="_x3C_Group_x3E__25_" class="nui-icon-large-chevron-up-0" d="M11.998 1 23 12M11.998 1 1 11.992"></path>
                </svg>
                <span class="phrase">Top</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { Navigation, Pagination, Autoplay } from 'swiper'
// import SpecialCategory from '@/components/index/SpecialCategory.vue'
import Banner from '@/components/index/Banner.vue'
export default {
  name: 'IndexVue',
  components: {
    Banner
    //  SpecialCategory
  },
  data() {
    return {
      //轮播参数
      modules: [Navigation, Pagination, Autoplay],

      //推荐商品
      recommend: [],

      //建议商品
      suggest: [],

      //superDeals
      superDeals: [],

      //plusSale
      plusSale: [],

      //加载更多
      scrollDisabled: false,
      page: 1,
      goodsData: [],
      loadmore: false,

      //商家
      topStore: [],

      //回到顶部
      isTop: false
    }
  },
  created() {
    //获取轮播图
    this.getActivity()
  },
  mounted() {
    // 注册监听页面高度
    let that = this
    let Val = 1000
    window.addEventListener('scroll', () => {
      let scrollTop = document.documentElement.scrollTop
      if (that.isTop && scrollTop < Val) {
        that.isTop = false
        // console.log(that.isTop, '隐藏')
      }

      if (!that.isTop && scrollTop >= Val) {
        that.isTop = true
        // console.log(that.isTop, '显示')
      }
    })
  },
  beforeMount() {
    // 注册监听页面高度
    window.removeEventListener('scroll', () => {})
  },
  methods: {
    getActivity() {
      let that = this
      //获取推荐
      this.$api.home.getTopGoods({ type: 1 }).then((res) => {
        this.recommend = res.data
      })

      //获取super
      this.$api.home.getTopGoods({ type: 2 }).then((res) => {
        this.superDeals = res.data
      })

      //获取plus
      this.$api.home.getTopGoods({ type: 3 }).then((res) => {
        this.plusSale = res.data
      })

      //获取活动
      // this.$api.home
      //   .getActivity({
      //     key: ['suggest', 'home_act']
      //   })
      //   .then((res) => {
      // this.suggest = res.data['suggest']
      //     this.activity = res.data['home_act']
      //   })

      //获取置顶店铺
      this.$api.home.getTopStore().then((res) => {
        that.topStore = res.data
      })
    },
    // 触底加载
    loadMore(page = 1) {
      if (this.loadmore) {
        return
      }

      if (page > 0 && this.page > page) {
        this.scrollDisabled = true
        return
      }
      this.loadmore = true
      this.getGoods()
    },
    getGoods(size = 24) {
      let that = this
      this.$api.home.getGoodsList({ page: this.page, size: size }).then((res) => {
        that.page++

        res.data.forEach(function (item) {
          that.goodsData.push(item)
        })
        that.loadmore = false
      })
    }
  }
}
</script>

<style scoped>
@import url('@/static/css2/559.b5a41cb40f752f33301a8f0486b832.css');
@import url('@/static/css2/143.6be7130b9f1e1036d2a16a0fc98f97.css');
@import url('@/static/css2/352.244e03012504d11fa9ec86dc8f080b.css');
@import url('@/static/css2/382.0080575219454102783a786154ae21.css');
@import url('@/static/css2/648.b20b73024dc54a4aaa179652ecf7ec.css');
@import url('@/static/css2/293.90477769df0c88fd442dcde29cdf95.css');
@import url('@/static/css2/966.d6015f1f32f53b3985af924d76d44f.css');
@import url('@/assets/css/index.css');
@import url('@/assets/css/ce06c8f557a77d4f.dl.css');
</style>
<style scoped>
.SDFW {
  width: 100%;
  display: flex;
  gap: 10px;
}
.XKCD {
  width: calc(100% / 6 - 10px);
  --column-gap: initial;
}
</style>
