<template>
  <order-list :status="2"></order-list>
</template>

<script>
import OrderList from "@/components/member/OrderList.vue";
export default {
  name: "OrderAll",
  components: {
    OrderList,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>