import request from "@/utils/request";

const api = {
  getGoodsList: "goods/getGoodsList",
  getGoodsInfo: "goods/getGoodsInfo",
  getRecommendedList: "goods/getRecommendedList",
  getTopSellingList: "goods/getTopSellingList",
  getStoreInfo: "goods/getStoreInfo",
  getCommentInfo: "goods/getCommentInfo",
  getCommentList: "goods/getCommentList",
};

export default {
  getGoodsList(parameter) {
    return request({
      url: api.getGoodsList,
      method: "post",
      data: parameter,
    });
  },
  getStoreInfo(parameter) {
    return request({
      url: api.getStoreInfo,
      method: "post",
      data: parameter,
    });
  },
  getGoodsInfo(parameter) {
    return request({
      url: api.getGoodsInfo,
      method: "post",
      data: parameter,
    });
  },
  getRecommendedList(parameter) {
    return request({
      url: api.getRecommendedList,
      method: "post",
      data: parameter,
    });
  },
  getTopSellingList(parameter) {
    return request({
      url: api.getTopSellingList,
      method: "post",
      data: parameter,
    });
  },
  getCommentInfo(parameter) {
    return request({
      url: api.getCommentInfo,
      method: "post",
      data: parameter,
    });
  },
  getCommentList(parameter) {
    return request({
      url: api.getCommentList,
      method: "post",
      data: parameter,
    });
  },
};
