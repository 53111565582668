<template>
  <div class="nav-header">
    <span class="nv-title">{{ $t('store.setting') }}</span>
    <!-- <span class="nv-desc">这是订单的管理页面，可管理用户的订单</span> -->
  </div>
  <!-- 2 -->
  <a-card style="margin-bottom: 20px">
    <div>
      {{ $t('setting.safe.title') }}
    </div>
    <a-form ref="secForm" :model="security" name="basic" :label-col="{ span: 4 }" :wrapper-col="{ span: 8 }" autocomplete="off" @finish="onSecurity" @finishFailed="onSecurityFailed">
      <a-form-item :label="$t('setting.passwd.title1')" name="old_passwd" :rules="[{ required: true, message: $t('setting.passwd.desc1') }]">
        <a-input type="password" v-model:value="security.old_passwd" />
      </a-form-item>

      <a-form-item :label="$t('setting.passwd.title2')" name="new_passwd" :rules="[{ required: true, message: $t('setting.passwd.desc2') }]">
        <a-input type="password" v-model:value="security.new_passwd" />
      </a-form-item>
      <a-form-item :label="$t('setting.passwd.title3')" name="confirm_passwd" :rules="[{ required: true, message: $t('setting.passwd.desc3') }]">
        <a-input type="password" v-model:value="security.confirm_passwd" />
      </a-form-item>

      <a-form-item :wrapper-col="{ offset: 4, span: 8 }">
        <a-button type="primary" html-type="submit" :loading="secbtnLoad">{{ $t('setting.shop.save') }}</a-button>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
export default {
  name: 'settingVue',
  components: {},
  data() {
    return {
      //账户安全
      security: {
        old_passwd: '',
        new_passwd: '',
        confirm_passwd: ''
      },
      secbtnLoad: false
    }
  },
  created() {},
  methods: {
    // 修改个人密码
    onSecurity() {
      const that = this
      this.$refs.secForm.validateFields().then((values) => {
        console.log(values)
        that.secbtnLoad = true
        that.$api.user
          .savePasswd(values)
          .then((res) => {
            that.secbtnLoad = false
            res
          })
          .catch(() => {
            that.secbtnLoad = false
          })
      })
    }
  }
}
</script>
<style scoped src="../../../static/css/store-css.css"></style>

<style>
.a-del {
  position: absolute;
  right: 10px;
  top: 0;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
}

.vo-name {
  font-size: 12px;
  white-space: normal;
  height: 38px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.op-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.del-btn {
  position: absolute;
  right: 5px;
  top: 5px;
}
</style>
