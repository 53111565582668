<template>
  <div class="nav-header">
    <span class="nv-title">{{ $t('share.yqlb') }}</span>
    <!-- <span class="nv-desc">这是订单的管理页面，可管理用户的订单</span> -->
  </div>
  <div class="bkbox">
    <div class="bkitem">
      <div class="ti" style="text-align: center;margin: 0;">{{ total_inv }}</div>
      <div>{{ $t('share.zmj') }}</div>
    </div>
    <div class="bkitem">
      <div class="ti" style="text-align: center;margin: 0;">{{ total_order }}</div>
      <div>{{ $t('share.zdd') }}</div>
    </div>
    <div class="bkitem">
      <div class="ti" style="text-align: center;margin: 0;">$ {{ total_amount }}</div>
      <div>{{ $t('store.order.total') }}</div>
    </div>
    <div class="bkitem">
      <div class="ti" style="text-align: center;margin: 0;">$ {{ total_profit }}</div>
      <div>{{ $t('share.zsy') }}</div>
    </div>
  </div>
  <div :style="{ background: '#fff', padding: '20px 20px' }">
    <div class="nav-content">
      <div></div>
      <!-- 右侧搜索框 -->
      <div>
        <a-space :size="20">
          <div>{{ $t('share.zts') }}:{{ pagination.total }}</div>
          <a-input-search :style="{ width: '400px' }" v-model:value="queryParam.search" :placeholder="$t('base.search.text')" :enter-button="$t('base.search')" size="large" @search="onSearch" />
        </a-space>
      </div>
    </div>
    <div style="margin-top: 10px">
      <a-table
        :rowKey="
          (record) => {
            return record.id
          }
        "
        :row-selection="rowSelection"
        :columns="columns"
        :data-source="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="getStoreProducts"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="record && column.dataIndex === 'img'">
            <img :src="record.img" width="50" style="margin-right: 5px" />
          </template>
          <template v-if="record && column.dataIndex === 'name'">
            <div style="display: flex; align-items: center">
              <div style="font-size: 13px">{{ record.name }}</div>
            </div>
          </template>
          <template v-if="record && column.key === 'order_num'">
            <!-- <a-tooltip placement="topLeft">
              <template #title> {{ $t('share.wcl') }}({{ record.order_s3 }})、{{ $t('share.ycl') }}({{ record.order_s4 }})、{{ $t('share.ywc') }}({{ record.order_s2 }}) </template>
              <div>{{ record.order_num }}</div>
            </a-tooltip> -->
            <div>{{ record.order_num }}</div>
          </template>
          <template v-if="record && column.key === 'total'">
            <div>$ {{ record.total }}</div>
          </template>
          <template v-if="record && column.key === 'profit'">
            <div>
              <a-tag color="green"> $ {{ record.profit }}</a-tag>
            </div>
          </template>
          <template v-if="record && column.dataIndex === 'top'">
            <div>
              <a-tag color="green" v-if="record.top == 3">{{ $t('products.t1') }}</a-tag>
              <a-tag color="cyan" v-if="record.top == 2">{{ $t('products.t2') }}</a-tag>
              <a-tag color="blue" v-if="record.top == 1">{{ $t('products.t3') }}</a-tag>
              <a-tag color="orange" v-if="record.top == 0">{{ $t('products.t4') }}</a-tag>
            </div>
          </template>
          <template v-else-if="column.key === 'action'">
            <span>
              <a href="javascript:" style="color: #f90" @click="onSelect(record)">{{ $t('products.syzd') }}</a>
              <a-divider type="vertical" />
              <a href="javascript:" @click="onTop(record)">{{ $t('products.top') }}</a>
              <a-divider type="vertical" />
              <a-popconfirm :title="$t('products.ask.delete')" :ok-text="$t('products.yes')" :cancel-text="$t('products.no')" @confirm="onDelete(record)">
                <a href="javascript:">{{ $t('products.delete') }}</a>
              </a-popconfirm>
            </span>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'productsVue',
  components: {},
  setup() {},
  data() {
    return {
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          sorter: false,
          width: '5%'
        },
        {
          title: this.$t('share.dpmc'),
          dataIndex: 'name',
          width: '20%'
        },
        {
          title: this.$t('share.ddl'),
          dataIndex: 'order_num',
          key: 'order_num',
          width: '20%'
        },
        {
          title: this.$t('store.order.total'),
          dataIndex: 'total',
          key: 'total',
          width: '20%'
        },
        {
          title: this.$t('share.jjsy'),
          dataIndex: 'profit',
          key: 'profit',
          width: '20%'
        },
        {
          title: this.$t('share.dj'),
          dataIndex: 'level',
          key: 'level',
          width: '20%'
        }
        // },
        // {
        //   title: this.$t('products.table.action'),
        //   key: 'action'
        // }
      ],
      loading: false,
      pagination: { total: 0, current: 1, pageSize: 20 },
      dataSource: [],
      queryParam: {
        search: '',
        category: []
      },

      //
      total_inv: 0,
      total_order: 0,
      total_amount: 0,
      total_profit: 0,

      //批量选中
      selectedRowKeys: [],
      selectedRows: []
    }
  },
  computed: {
    rowSelection() {
      return {
        onChange: this.onSelectChange
      }
    }
  },
  created() {
    this.getShareList(this.pagination)
  },
  methods: {
    /**
     * 选中框
     * @param {*} selectedRowKeys
     * @param {*} selectedRows
     */
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      console.log(selectedRowKeys, selectedRows)
    },
    onSearch() {
      this.getShareList(this.pagination)
    },
    getShareList(parameter) {
      const pagination = {
        page: parameter.current,
        size: parameter.pageSize
      }
      const requestParameters = Object.assign({}, pagination, this.queryParam)

      this.loading = true

      let that = this
      this.$api.store.getShareList(requestParameters).then((res) => {
        that.loading = false
        that.total_inv = res.data.total_inv
        that.total_order = res.data.total_order
        that.total_profit = res.data.total_profit
        that.total_amount = res.data.total_amount
        that.pagination = {
          total: res.data.total,
          current: res.data.pageNo,
          pageSize: res.data.pageSize
        }
        that.dataSource = res.data.data
      })
    }
  }
})
</script>

<style scoped src="@/static/css/store-css.css"></style>
<style scoped>
.bkbox {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}
.bkitem {
  flex: 1;
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bkitem .ti {
  font-size: 20px;
  font-weight: 700;
}
</style>
