<template>
  <a class="lHtRS cQ7wR IdMRc RTmqg ng-mob">
    <!-- <svg class="fAVHJ" style="fill: #a9c658" width="24px" height="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <use xlink:href="#pin"></use>
    </svg> -->
    <div class="langs-main">
      <div class="langs-box">
        <div :class="'langs-img langs-' + language.lang"></div>
      </div>
      <div style="font-size: 16px;">
        {{ language.text }}
      </div>
    </div>
    <div class="app-main" style="padding-top: 16px; left: calc(100% - 150px)">
      <div class="app-box">
        <ul class="AL1">
          <li class="AP1" v-for="(vo, i) in list" :key="i" @click="onItem(vo)">
            <div class="langs-box">
              <div :class="'langs-img langs-' + vo.lang"></div>
              <span class="lang-item" style="padding: 0">
                {{ vo.text }}
                <!-- <span style="color: #e72528">●</span> -->
              </span>
            </div>
          </li>
        </ul>
      </div>
      <div class="eLxL5" style="inset: 0px auto auto 125px"><div class="oGvoJ" style="top: 8px; transform: rotate(-135deg)"></div></div>
    </div>
  </a>
</template>

<script>
import { getCurrentInstance } from 'vue'
import { setLangauge } from '@/utils/vue-i18n'
export default {
  name: 'LanguageVue',
  setup() {
    const { proxy } = getCurrentInstance()
    function change(language) {
      proxy.$i18n.locale = language.lang
      setLangauge(language)
      console.log('切换语言', language)
    }
    return { change }
  },
  data() {
    return {
      isShow: false,
      //选择国家
      language: {
        text: 'English',
        lang: 'en-us'
      },
      list: this.$Const.LANGUAGE
    }
  },
  created() {
    try {
      let language = localStorage.getItem(this.$Const.KEY_LANG)
      if (language) {
        language = JSON.parse(language)
        this.language = language
      }
    } catch (error) {
      //console.log(error);
    }
  },
  methods: {
    show() {
      this.isShow = true
    },
    close() {
      this.isShow = false
    },
    onClose() {
      this.close()
    },
    onItem(vo) {
      this.language = vo
      //缓存
      localStorage.setItem(this.$Const.KEY_LANG, JSON.stringify(this.language))

      this.change(this.language)
      //   this.close()
      location.reload()
    }
  }
}
</script>

<style scoped>
.g_5 {
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes hr {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  to {
    visibility: visible;
    opacity: 1;
  }
}

.g5_1 {
  background: rgba(0, 0, 0, 0.4);
  animation: 220ms ease 0s 1 normal none running hr;
}

.g_5,
.g_6 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.g_6 {
  background: rgba(0, 0, 0, 0.4);
  z-index: -1;
  animation-fill-mode: forwards !important;
}

.g1_7 {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
  animation-fill-mode: forwards !important;
}

.g1_7,
.g_8:after {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.g_8:after,
.g_9 {
  display: inline-block;
  vertical-align: middle;
}

@keyframes ht {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.g91 {
  z-index: 1000;
  animation: 220ms ease 0s 1 normal none running ht;
  --max-width-s: 100%;
  --max-width-m: 995px;
  --max-width-l: 995px;
  --margin-s: 8px;
  --margin-m: 40px;
  --margin-l: 40px;
}

.g1_9 {
  width: calc(100% - var(--margin-s) * 2);
  max-width: var(--max-width-s, 100%);
  min-height: 200px;
  min-width: 300px;
  text-align: left;
  background: #fff;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  margin: var(--margin-s, 8px);
  color: #000;
  border-radius: 24px;
  position: relative;
  overflow-y: auto;
  animation-fill-mode: forwards !important;
}

@media only screen and (min-width: 600px) {
  .g1_9 {
    width: calc(100% - var(--margin-m) * 2);
    max-width: var(--max-width-m, 460px);
    margin: var(--margin-m, 8px);
  }
}

@media only screen and (min-width: 1024px) {
  .g1_9 {
    width: calc(100% - var(--margin-l) * 2);
    max-width: var(--max-width-l, 460px);
    margin: var(--margin-l, 8px);
  }
}

.btn1 {
  position: absolute;
  width: 32px;
  height: 32px;
  font-size: 0.875rem;
  line-height: 1rem;
  padding: 0;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  fill: #b3b3b3;
  margin-left: auto;
  margin-right: 12px;
  right: 5px;
  top: 15px;
}

.btn1:hover {
  background-color: #e5e5e5;
  fill: #a6a6a6;
}

.btn2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: filter 0.2s;
}

.btn3 {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
}

.btn4 {
  width: 100%;
  height: 100%;
}

.bs1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 600px) {
  .AbtJ {
    font-size: 1.125rem;
    line-height: 1.25rem;
  }
}
.AbtJ {
  display: flex;
  color: #0073e6;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 16px 0 20px;
  border-bottom: 1px solid #b3b3b3;
}

.gbt {
  margin-right: 8px;
  fill: #0073e6;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hAl {
  display: flex;
  flex-direction: column;
  /* padding: 0 40px; */
}

.hb_0 {
  padding: 0 40px;
}

.AP1 {
  padding: 5px 15px;
  color: #000;
  text-decoration: none;
  transition: color 0.3s;
}

.AP1:hover {
  background-color: #f5f5f5;
}

.AP11 {
  cursor: pointer;
}

.AP11:hover {
  color: #0073e6;
}

.hfA_1 {
  margin-bottom: 16px;
  font-size: 1.75rem;
  line-height: 2rem;
  font-weight: 700;
  margin-bottom: 16px;
  margin-top: 24px;
  color: #000;
  max-width: 90%;
}

.AL1 {
  display: flex;
  gap: 5px;
  flex-direction: column;
  padding: 10px 0;
}
</style>
