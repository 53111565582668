<template>
  <section class="By">
    <h2 class="Uz">
      <span style="vertical-align: inherit">
        <span style="vertical-align: inherit">{{ $t('_n2._gycp') }}</span>
        <span class="kj_yc" v-show="state" @click="onMore()">{{ $t('_n2._yc') }}</span>
      </span>
    </h2>
    <div class="w_4 xj xi w_8">
      <div class="" :style="'max-height:' + height + '; overflow: hidden'">
        <div class="xk xo">
          <div class="UA" v-html="content"></div>
        </div>
      </div>
      <div class="w_9 w_8">
        <div class="xa" @click="onMore()">
          <span class="UB">
            <span style="vertical-align: inherit">
              <span style="vertical-align: inherit">{{ state ? $t('_n2._yc') : $t('_n.ckgd') }}</span>
            </span>
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'GoodsContent',
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      state: false,
      height: '240px'
    }
  },
  methods: {
    onMore() {
      if (this.state) {
        this.height = '240px'
        this.state = false
      } else {
        this.height = 'auto'
        this.state = true
      }
    }
  }
}
</script>

<style>
.kj_yc {
  font-size: 17px;
  color: #0073e6;
  margin-left: 20px;
  cursor: pointer;
}
</style>
