<template>
  <a-modal v-model:visible="RechargeVisible" :title="$t('bank.recharge.title')" @ok="onNext()" :style="{ width: '800px' }" :okText="$t('bank.ok.text')" :cancelText="$t('recharge.cancel.text')">
    <!-- 选择银行卡 -->
    <div :style="{ padding: '0 20px' }" v-if="step == 1">
      <a-form name="basic" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }" autocomplete="off">
        <a-form-item :label="$t('bank.title')">
          <a-select ref="select" v-model:value="selectIndex" style="width: 100%" @change="onCurrencyChange">
            <a-select-option :value="i" v-for="(item, i) in currencyList" :key="i">{{ item.bank_name }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 24 }">
          <div style="display: flex">
            <div style="display: flex; flex-direction: column; align-items: flex-end; width: 27%">
              <div>{{ $t('bank.name') }}：</div>
              <div>{{ $t('bank.account') }}：</div>
              <div>{{ $t('bank.routing') }}：</div>
              <div>{{ $t('bank.code') }}：</div>
              <div>{{ $t('bank.bankname') }}：</div>
              <div>{{ $t('bank.bankaddress') }}：</div>
            </div>
            <div style="width: 80%">
              <div>{{ current.name }}</div>
              <div>{{ current.account }}</div>
              <div>{{ current.routing }}</div>
              <div>{{ current.code }}</div>
              <div>{{ current.bank_name }}</div>
              <div>{{ current.bank_address }}</div>
            </div>
          </div>
        </a-form-item>

        <!-- <a-form-item>
          <a-button type="primary" @click="onNext">下一步</a-button>
        </a-form-item> -->
      </a-form>
    </div>

    <!-- 提交信息 -->
    <div :style="{ padding: '0 20px' }" v-if="step == 2">
      <a-form :model="params" name="basic" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }" autocomplete="off">
        <!-- type -->
        <a-form-item :label="$t('bank.type')">
          <a-radio-group v-model:value="params.type">
            <a-radio :value="1">{{ $t('bank.usd') }} (USD)</a-radio>
            <a-radio :value="2">{{ $t('bank.eur') }} (EUR)</a-radio>
          </a-radio-group>
        </a-form-item>
        <!-- amount -->
        <a-form-item :label="$t('bank.amount')" name="amount" :rules="[{ required: true, message: 'Please input your username!' }]">
          <a-input v-model:value="params.amount" type="number" :step="0.00000001" :placeholder="$t('bank.amount.desc')" />
        </a-form-item>
        <!-- receipt -->
        <a-form-item :label="$t('bank.receipt.number')" :rules="[{ required: true, message: 'Please input your username!' }]">
          <a-input v-model:value="params.number" type="text" :placeholder="$t('bank.receipt.number.desc')" />
        </a-form-item>
        <!-- 封面图 -->
        <a-form-item :label="$t('bank.credential.picture')" :required="true">
          <!-- <a-upload ref="files" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" action="/web/v1/upload/file" :before-upload="beforeUpload" @change="onUploadChange" accept="image/*">
            <img v-if="imageUrl" :src="imageUrl" alt="avatar" width="126" />
            <div v-else>
              <loading-outlined v-if="loading"></loading-outlined>
              <plus-outlined v-else></plus-outlined>
              <div class="ant-upload-text">{{ $t('bank.upload') }}</div>
            </div>
          </a-upload> -->
          <upload-file @onUpload="onUpload"></upload-file>
          <!-- <div class="upload-box" @click="onOpenFile">
            <img v-if="imageUrl" :src="imageUrl" alt="avatar" width="126" />
            <div v-else class="upload-file">
              <loading-outlined v-if="loading"></loading-outlined>
              <plus-outlined v-else></plus-outlined>
              <div class="ant-upload-text">{{ $t('bank.upload') }}</div>
            </div>
          </div>
          <input ref="files" type="file" accept="image/*" @change="onFile" hidden /> -->
        </a-form-item>
        <!-- 打开文件 -->

        <!-- remark -->
        <a-form-item :wrapper-col="{ span: 24 }">
          <div style="display: flex; margin-left: 15%; flex-direction: column">
            <div>⚠️{{ $t('bank.text.title') }}</div>
            <div>📌{{ $t('bank.text.t1') }}</div>
            <div>📌{{ $t('bank.text.t2') }}</div>
            <div>📌{{ $t('bank.text.t3') }}</div>
            <div>📌{{ $t('bank.text.t4') }}</div>
            <div>📌{{ $t('bank.text.t5') }}</div>
            <div style="color: red">📌{{ $t('bank.text.t6') }}</div>
          </div>
        </a-form-item>

        <!-- <a-form-item>
          <a-button key="back" @click="onPrev">上一步</a-button>
          <a-button key="submit" type="primary" :loading="loadingBtn" @click="onSubmit">立即提交</a-button>
        </a-form-item> -->
      </a-form>
    </div>
    <template #footer>
      <a-button type="primary" @click="onNext" v-if="step == 1">{{ $t('bank.ok.text') }}</a-button>
      <a-button key="back" @click="onPrev" v-if="step == 2">{{ $t('bank.ok.prev') }}</a-button>
      <a-button key="submit" type="primary" :loading="loadingBtn" @click="onSubmit" v-if="step == 2">{{ $t('bank.submit') }}</a-button>
    </template>
  </a-modal>
</template>

<script>
import UploadFile from '@/components/UploadFile.vue'
export default {
  name: 'RechargeVue',
  components: {
    UploadFile
  },
  emits: ['BankCardSuccess'],
  data() {
    return {
      //存币
      step: 1,
      RechargeVisible: false,
      selectIndex: 0,
      currencyList: [],
      current: {},

      // 提交信息
      params: {
        type: 1,
        amount: '',
        number: '',
        img: ''
      },

      // 上传图片
      loading: false,
      imageUrl: '',
      img: '',

      // 提交
      loadingBtn: false
    }
  },
  created() {
    let that = this
    this.$api.currency.getBankCardList().then((res) => {
      that.currencyList = res.data
      //默认第一个
      that.onCurrencyChange(0)
    })
  },
  methods: {
    onUpload(img) {
      this.imageUrl = img
      this.img = img
    },
    /**
     * 上传封面图
     * @param {*} img
     * @param {*} callback
     */
    getBase64(img, callback) {
      const reader = new FileReader()
      reader.addEventListener('load', () => callback(reader.result))
      reader.readAsDataURL(img)
    },
    onUploadChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        var response = info.file.response
        if (response.code === 1) {
          this.loading = false
          this.$message.error(response.msg)
          return
        }

        this.img = response.data.url
        this.loading = false

        // Get this url from response in real world.
        this.getBase64(info.file.originFileObj, (imageUrl) => {
          this.imageUrl = imageUrl
          this.loading = false
        })
      }
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/bmp'
      if (!isJpgOrPng) {
        this.$message.error('can only upload jpg, png, bmp pictures')
      }
      const isLt2M = file.size / 1024 / 1024 < 20
      if (!isLt2M) {
        this.$message.error('Images must be less than 20MB!')
      }
      return isJpgOrPng && isLt2M
    },
    /**
     * 存币
     */
    show() {
      this.step = 1
      this.selectIndex = 0
      this.imageUrl = ''
      this.img = ''
      this.params = {
        type: 1,
        amount: '',
        number: '',
        img: ''
      }
      this.RechargeVisible = true
    },
    hide() {
      this.RechargeVisible = false
    },
    // 改变
    onCurrencyChange(i) {
      this.current = this.currencyList[i]
    },
    onNext() {
      this.step = 2
    },
    onPrev() {
      this.step = 1
    },
    valid() {
      if (this.params.amount === '') {
        this.$message.error('amount cannot be empty')
        return false
      }

      if (this.params.number === '') {
        this.$message.error('number cannot be empty')
        return false
      }

      if (this.params.img === '') {
        this.$message.error('credential image cannot be empty')
        return false
      }

      return true
    },
    onSubmit() {
      this.loadingBtn = true

      this.params.bank_id = this.current.id
      this.params.img = this.img

      if (!this.valid()) {
        this.loadingBtn = false
        return
      }

      //提交到后台
      const that = this
      this.$api.store
        .transferBankCard(this.params)
        .then((res) => {
          that.loadingBtn = false
          if (res.code == 0) {
            that.$message.success(res.msg)
            setTimeout(function () {
              that.hide()
              that.$emit('BankCardSuccess')
            }, 500)
          }
        })
        .catch((err) => {
          err
          that.loadingBtn = false
        })
    }
  }
}
</script>

<style></style>
