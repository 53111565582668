<template>
  <main class="h_9">
    <div class="h_3">
      <!-- 面包屑 -->
      <!-- <Bread></Bread> -->

      <div class="Bp">
        <!-- 轮播图片 -->
        <goods-banner ref="banner" :imgs="imgs || []"></goods-banner>
        <!-- 轮播图 -->

        <!-- 商品标题 -->
        <section class="Bt">
          <div class="Uj">
            <div class="Ul">
              <div class="Um">
                <ul class="Un">
                  <li class="Uo">
                    <div class="R_5 Sc R_8 Se Sg" style="background-color: rgb(0, 0, 0); color: rgb(255, 255, 255)">
                      <div class="Sd">
                        <span style="vertical-align: inherit">
                          <span style="vertical-align: inherit">{{ $t('_n2._rxsp') }}</span>
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <h1 class="Uk">
              <span style="vertical-align: inherit">
                <span style="vertical-align: inherit">{{ goods.name }}</span>
              </span>
            </h1>
            <div class="Ut">
              <div class="Uu">
                <div class="Uv">
                  <!-- <div :style="'width:' + 17.8 * parseInt(info.star / 0.5) + 'px;overflow: hidden;margin-right: 15px;'">
                    <div class="_8j _8A _8l Uw"></div>
                  </div> -->
                  <span role="img" class="T2Mzf" style="font-size: 1.3rem">
                    <span class="GocDN dUBUg"></span>
                    <span class="GocDN bFCnj" :style="'width: ' + 20 * parseInt(info.star || 0) + '%'"></span>
                  </span>
                  <a href="javascript:" class="Up" style="margin-left: 10px; display: flex; gap: 10px">
                    <span style="vertical-align: inherit">{{ comment.total }}</span>
                    <span style="vertical-align: inherit">{{ $t('_n2._pl') }}</span>
                  </a>
                </div>
              </div>
              <div class="Uu">
                <div class="_7_6">
                  <div class="Rd Rg">
                    <div class="Re">
                      <button type="button" role="button" class="fS fW ga f_ gh gm gu">
                        <div class="fU">
                          <div class="fV">
                            <span class="_8a"
                              ><i class="eO eS _7_8 _7_9" aria-hidden="true"
                                ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                                  <use xlink:href="#heartHollowBg"></use></svg></i
                            ></span>
                          </div>
                          <div class="fV">
                            <span class="_8g">
                              <span style="vertical-align: inherit">
                                <span style="vertical-align: inherit">{{ info.wish_num }}</span>
                              </span>
                            </span>
                          </div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Uu">
                <div class="Up">
                  <span class="Us">
                    <span style="vertical-align: inherit">
                      <span style="vertical-align: inherit">{{ $t('detail.orders') }} {{ info.orders }}</span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- 右侧按钮栏 -->
        <section class="Bq BF">
          <!-- 商品编码 -->
          <div class="BD" v-if="goods.code">
            <div class="Vh">{{ $t('_spxx._spbm') }}:{{ goods?.code }}</div>
          </div>
          <!-- 商品规格 -->
          <div class="BD" v-for="(item, i) in attr" :key="i">
            <p class="Vh">{{ item.name }}:{{ selected[i] && selected[i].name }}</p>
            <div class="Vi">
              <a href="javascript:" v-for="(vo, k) in item.children" :key="k" @click="onAttr(vo, i)" :class="['Vj', hasAttr(vo.id) ? '' : 'disabled', selected[i] && selected[i].id == vo.id ? 'Vl' : '']">
                <span class="Vm" v-if="vo.type == 1">{{ vo.name }}</span>
                <span class="Vm" v-if="vo.type == 2">
                  <picture class="HI">
                    <source srcset="@/static/svg/download.svg" type="image/svg" />
                    <img class="pV bc_1 pX" :src="vo.thumb" fetchpriority="high" />
                  </picture>
                </span>
              </a>
            </div>
          </div>
          <div class="BD">
            <p class="Vh">{{ $t('products.table.stock') }}:{{ getAttrVal('total') }}</p>
            <div class="_9J">
              <div class="bgp">
                <button @click="onSubNum()" :disabled="buyNum == 1" type="button" role="button" class="fS f_6 ga f_1 gh gl gu f_8 skd2123">
                  <div class="fU">
                    <div class="fV">
                      <i class="eO eT" aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                          <use xlink:href="#minus"></use>
                        </svg>
                      </i>
                    </div>
                  </div>
                </button>
                <div class="bgq">
                  <div class="n_7 oC oK oz n_8 ou ok bgr ow oh oi os">
                    <label class="oe">
                      <input class="ob bgs oN" type="number" maxlength="254" spellcheck="true" v-model="buyNum" @input="onNumber" />
                      <div class="og"></div>
                    </label>
                  </div>
                </div>
                <div>
                  <div class="sB s_4 sC">
                    <div class="sD">
                      <button @click="onAddNum()" :disabled="buyNum == getAttrVal('total')" type="button" role="button" class="fS f_6 ga f_1 gh gl gu f_7 skd2123">
                        <div class="fU">
                          <div class="fV">
                            <i class="eO eT" aria-hidden="true"
                              ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                                <use xlink:href="#plus"></use>
                              </svg>
                            </i>
                          </div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 价格与加入购物车 -->
          <section class="BD">
            <div>
              <div class="">
                <div class="_8K">
                  <p class="_8L">
                    <span style="vertical-align: inherit">
                      <span style="vertical-align: inherit">US ${{ getAttrVal('cost_price', 2) }}</span>
                    </span>
                  </p>
                  <div class="_8M">
                    <p class="_8T _8V">
                      <span style="vertical-align: inherit">
                        <span style="vertical-align: inherit">US ${{ getAttrVal('price', 2) }}</span>
                      </span>
                    </p>
                    <p class="_8X">
                      <span style="vertical-align: inherit"> <span style="vertical-align: inherit">-</span> </span
                      ><span class="_8Y">
                        <span style="vertical-align: inherit">
                          <span style="vertical-align: inherit">{{ info && info.discount }}</span>
                        </span>
                      </span>
                      <span style="vertical-align: inherit">
                        <span style="vertical-align: inherit">%</span>
                      </span>
                    </p>
                  </div>
                </div>
                <button type="button" role="button" class="fS _8Z fW ga f_1 gh gm gu">
                  <div class="fU">
                    <div class="fV">
                      <span style="vertical-align: inherit">
                        <span style="vertical-align: inherit">Free Shipping</span>
                      </span>
                      <span style="vertical-align: inherit">
                        <span style="vertical-align: inherit"></span>
                      </span>
                    </div>
                  </div>
                </button>
              </div>
            </div>
            <div class="_9J">
              <div class="">
                <div class="_9z sB s_4">
                  <div class="sD sdfeq" style="gap: 15px">
                    <button type="button" role="button" class="gv _9E gC gD gw sbtn" @click="onBuyNow()">
                      {{ $t('_n._ljgm') }}
                    </button>
                    <button type="button" role="button" class="gv _9E gC gD gw scbtn sfjkeq" @click="onAddToCart()">
                      {{ $t('detail.add.to.cart') }}
                    </button>
                    <button type="button" role="button" class="gv _9E gC gD gw scbtn sfjkeq" @click="onAllAddToCart()">
                      {{ $t('_st._pljrgwc') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button type="button" role="button" class="fS bbC _9I fW ga f_1 gh gl f_4" style="border: 1px #000 dashed">
              <div class="fU">
                <div class="fV">
                  <div class="bbE">
                    <span>
                      <span class="bbD">
                        <span style="vertical-align: inherit">
                          <span style="vertical-align: inherit">Estimated Delivery:1-5Days</span>
                        </span>
                      </span>
                    </span>
                    <i class="eO eS bbF" aria-hidden="true">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                        <use xlink:href="#arrow_short_right"></use>
                      </svg>
                    </i>
                  </div>
                </div>
              </div>
            </button>
          </section>

          <!-- 快递说明 -->
          <goods-delivery></goods-delivery>
        </section>

        <!-- 商品详情 -->
        <section class="Bs">
          <div style="display: flex; align-items: center; justify-content: space-between">
            <div style="width: 22%">
              <!-- 商家信息 -->
              <store-desc :store_id="info.store_id"></store-desc>
            </div>
            <div style="width: 77%">
              <!-- 同类产品推荐 -->
              <goods-featured :store_id="info.store_id"></goods-featured>
            </div>
          </div>

          <!-- 产品详情 -->
          <goods-content :content="goods.content || ''"></goods-content>

          <!-- 商品规格 -->
          <goods-specs :specs="goods.specs || []"></goods-specs>

          <!-- 评分 -->
          <goods-comment :goods_id="info.id"></goods-comment>

          <!-- 如你所见 -->
          <goods-see :goods_id="info.id"></goods-see>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import { mapState } from 'vuex'
// import Bread from '@/components/base/Bread.vue'
import GoodsBanner from '@/components/detail/GoodsBanner.vue'
import StoreDesc from '@/components/detail/StoreDesc.vue'
import GoodsFeatured from '@/components/detail/GoodsFeatured.vue'
import GoodsSee from '@/components/detail/GoodsSee.vue'
import GoodsContent from '@/components/detail/GoodsContent.vue'
import GoodsSpecs from '@/components/detail/GoodsSpecs.vue'
import GoodsComment from '@/components/detail/GoodsComment.vue'
import GoodsDelivery from '@/components/detail/GoodsDelivery.vue'
export default {
  name: 'detailVue',
  components: {
    // Bread,
    GoodsBanner,
    StoreDesc,
    GoodsFeatured,
    GoodsSee,
    GoodsContent,
    GoodsSpecs,
    GoodsComment,
    GoodsDelivery
  },
  data() {
    return {
      //商品信息
      info: {},
      goods: {
        name: ''
      },
      imgs: [{}, {}],
      sku: {},
      attr: [],
      tags: {},

      //选中
      selected: {},
      haveattr: [],
      //选择完成
      attrSku: {},

      //购买
      buyNum: 1
    }
  },
  created() {
    console.log('detail-created')
    let query = this.$route.query
    this.getGoodsInfo(query)
  },
  computed: {
    ...mapState({
      comment: (state) => state.goods.comment
    })
  },
  methods: {
    /**
     * 获取商品信息
     */
    getGoodsInfo(query) {
      let that = this
      this.$api.goods
        .getGoodsInfo({ id: query.id })
        .then((res) => {
          that.sku = res.data.sku
          that.goods = res.data.goods.goods
          // that.goods.specs = JSON.parse(that.goods.specs)

          that.imgs = res.data.goods.goods.imgs
          that.attr = res.data.attr
          that.tags = res.data.tags

          that.info = res.data.goods
          delete that.info.goods

          that.$store.commit('store/setId', { id: that.info.store_id })
          this.$store.dispatch('goods/getRecommendedList', { id: that.info.store_id })

          //获取销量商品
          // that.$api.goods.getTopSellingList({ store_id: store_id }).then((dat) => {
          //   that.topSellingList = dat.data
          // })

          // 获取店铺信息
          // that.$api.storeInfo.getStoreInfo({ id: store_id }).then((dat) => {
          //   that.store = dat.data.store
          //   if (dat.data.content) {
          //     that.storeContent = dat.data.content
          //   }
          //   //顶部店铺
          //   that.$refs.SearchBox.setStore(dat.data.store)
          // })

          //初始化评论
          // that.$refs.evaluation.init()
        })
        .catch(() => {
          setTimeout(() => {
            this.$router.go(-1)
          }, 1500)
        })
    },
    hasAttr(id) {
      if (this.haveattr.length > 0) {
        return this.haveattr.includes(id)
      }
      return true
    },
    /**
     * 选择规格
     */
    onAttr(vo, i) {
      if (this.haveattr.length > 0 && !this.haveattr.includes(vo.id) && this.attr.length > 1) {
        // return;
      }
      if (this.selected[i] && this.selected[i].id === vo.id) {
        delete this.selected[i]

        //带图的规格
        if (vo.type == 2) {
          this.$refs.banner.setThumb({})
        }
      } else {
        this.selected[i] = vo

        //带图的规格
        if (vo.type == 2) {
          if (vo.img) {
            this.$refs.banner.setThumb(vo)
          }
        }
      }

      //计算有的规格
      this.haveattr = this.getAttr()

      //触发规格属性
      this.attrSku = this.getSku()

      console.log('selected', this.selected)
      console.log('haveattr', this.haveattr)
      console.log('attrSku', this.attrSku)
    },
    onSubNum() {
      this.buyNum--
    },
    onAddNum() {
      this.buyNum++
    },
    onNumber() {
      let num = this.getAttrVal('total')
      if (this.buyNum > num) {
        this.buyNum = num
      }
    },
    /**
     * 获取显示价格
     */
    getAttrVal(key, type = 1) {
      if (this.attrSku[key]) {
        return this.attrSku[key]
      } else if (this.info.min && type == 1) {
        return this.info[key]
      } else if (this.info.min && type == 2) {
        return this.info.min[key] + '-' + this.info.max[key]
      }
      return ''
    },
    /**
     * 判断交集规格
     */
    getAttr() {
      console.log(this.selected, 'selected')
      let temp = []
      const arr = this.tags
      for (let i in this.selected) {
        const id = this.selected[i].id
        if (temp.length == 0) {
          temp = [].concat(arr[id])
        } else {
          temp = temp.filter((item) => arr[id].indexOf(item) > -1)
        }
      }
      return temp
    },
    getSku() {
      let temp = []
      if (this.hasCheck()) {
        let key = 'k'
        for (let i in this.selected) {
          if (key != 'k') {
            key += '_'
          }
          key += this.selected[i].id
        }
        console.log(key, this.sku, 'sku')
        temp = this.sku[key]

        this.buyNum = 1
      }
      return temp
    },
    /**
     * 是否全部选中
     */
    hasCheck() {
      let isCheck = true
      for (let n = 0; n < this.attr.length; n++) {
        if (this.selected[n] === undefined) {
          isCheck = false
        }
      }
      //console.log("是否选择", isCheck);
      return isCheck
    },
    /**
     * 购买
     */
    onBuyNow() {
      //是否登录
      if (!this.$common.isLogin()) {
        this.$message.warning({
          content: this.$t('_n2._qxdl')
        })
        return
      }

      let user = this.$common.getInfo()
      if (user.type == 2) {
        this.$message.warning({
          content: this.$t('_n3._sjwfgm')
        })
        return
      }

      if (Reflect.ownKeys(this.attrSku).length == 0 || this.attrSku.length == 0) {
        this.$message.warning(this.$t('_n2._xzspgg'))
        return
      }

      //保存参数
      localStorage.setItem(
        this.$Const.KEY_ORDER,
        JSON.stringify({
          type: 'order',
          goods_id: this.info.id,
          sku_id: this.attrSku.id,
          num: this.buyNum
        })
      )

      this.$router.push({ name: 'cart', query: { type: 'order' } })
    },
    onAllAddToCart() {
      //是否登录
      if (!this.$common.isLogin()) {
        this.$message.warning({
          content: this.$t('_n2._qxdl')
        })
        return
      }

      let user = this.$common.getInfo()
      if (user.type == 2) {
        this.$message.warning({
          content: this.$t('_n3._sjwfgm')
        })
        return
      }

      let that = this
      this.$message.loading({ content: 'add cart...', key: 'msg' })
      this.$api.order
        .addShopCartAll({
          goods_id: this.info.id,
          num: this.buyNum
        })
        .then((res) => {
          that.$message.success({
            content: res.msg,
            key: 'msg'
          })
          console.log(res)
        })
    },
    /**
     * 增加到购物车
     */
    onAddToCart() {
      //是否登录
      if (!this.$common.isLogin()) {
        this.$message.warning({
          content: this.$t('_n2._qxdl')
        })
        return
      }

      let user = this.$common.getInfo()
      if (user.type == 2) {
        this.$message.warning({
          content: this.$t('_n3._sjwfgm')
        })
        return
      }

      if (Reflect.ownKeys(this.attrSku).length === 0 || this.attrSku.length == 0) {
        this.$message.warning({
          content: this.$t('_n2._xzspgg')
        })
        return
      }

      let that = this
      this.$message.loading({ content: 'add cart...', key: 'msg' })
      this.$api.order
        .addShopCart({
          goods_id: this.info.id,
          sku_id: this.attrSku.id,
          num: this.buyNum
        })
        .then((res) => {
          that.$message.success({
            content: res.msg,
            key: 'msg'
          })
          console.log(res)
        })
    }
  }
}
</script>

<style scoped>
@import url('@/static/css2/143.6be7130b9f1e1036d2a16a0fc98f97.css');
@import url('@/static/css2/127.76d6a7627b7821de71baccfa30f799.css');
</style>

<style scoped>
.sbtn {
  background: #000;
  border-radius: 10px;
  font-size: 20px;
  color: #fff;
}
.sbtn:hover {
  background: #3e4142;
}

.skd2123 {
  cursor: pointer;
  border: 1px solid #000 !important;
}

.skd2123:hover {
  background: #f0f3f5;
}

.scbtn {
  background: #000;
  border-radius: 10px;
}

.sdfeq {
  display: flex;
  flex-direction: column;
}

.sfjkeq {
  border: 2px solid #000;
  background-color: #fff;
  color: #000;
  font-size: 20px;
}
.sfjkeq:hover {
  background-color: #f0f3f5;
}
</style>
