import axios from 'axios'
import router from '../router'
import { message } from 'ant-design-vue'
import Const from '@/utils/const'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: '/web/v1',
  timeout: 20000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  console.log('error', error)
  if (error.response) {
    const { status, data } = error.response
    if (status === 500 || status === 404) {
      message.destroy()
      message.error('Server exception')
    } else if (status === 401) {
      message.destroy()
      message.warning(data.msg)
      localStorage.removeItem(Const.KEY_TOKEN)
      localStorage.removeItem(Const.KEY_INFO)
      router.push({ name: 'index' })
    }
  }
  return Promise.reject(error)
}

// 请求拦截器
request.interceptors.request.use((config) => {
  // 如果 token 存在
  const token = localStorage.getItem(Const.KEY_TOKEN)
  if (token) {
    config.headers.Authorization = 'Basic ' + token
  }

  //多语言
  const language = JSON.parse(localStorage.getItem(Const.KEY_LANG))
  config.headers['Accept-Language'] = language ? language.lang + ',en;q=0.9' : 'en;q=0.9'

  return config
}, errorHandler)

// 响应拦截器
request.interceptors.response.use((response) => {
  //错误提示信息
  if (response.data.code == 1) {
    message.warning({ content: response.data.msg, key: 'msg' })
    return Promise.reject(response.data)
  } else if (response.data.code == 1000) {
    message.warning({ content: response.data.msg, key: 'msg' })
    return Promise.reject(response.data)
  }
  return response.data
}, errorHandler)

export default request
