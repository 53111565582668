<template>
  <div class="nav-header">
    <span class="nv-title">{{ $t('store.setting') }}</span>
    <!-- <span class="nv-desc">这是订单的管理页面，可管理用户的订单</span> -->
  </div>
  <!-- 1 -->
  <a-card style="margin-bottom: 20px">
    <div>
      {{ $t('member.shop.info') }}
    </div>
    <a-form :model="store" name="basic" :label-col="{ span: 4 }" :wrapper-col="{ span: 8 }" autocomplete="off" @finish="onFinish" @finishFailed="onFinishFailed">
      <a-form-item :label="$t('setting.avatar')" :required="true">
        <!-- <a-upload name="file" action="web/v1/upload/file" list-type="picture-card" :show-upload-list="false" :headers="{}" :data="{}" :before-upload="beforeUpload" @change="onAvatarUpload" accept=".jpg,.png,.bmp">
          <img v-if="imageUrl" :src="imageUrl" alt="avatar" style="width: 100%;" />
          <div v-else>
            <loading-outlined v-if="loadingImage"></loading-outlined>
            <plus-outlined v-else></plus-outlined>
            <div class="ant-upload-text">{{ $t('setting.upload') }}</div>
          </div>
        </a-upload> -->

        <upload-file @onUpload="onAvatarUpload" :imageUrl="imageUrl"></upload-file>
      </a-form-item>

      <a-form-item :label="$t('setting.shop.name')" name="name" :rules="[{ required: true, message: 'Please input your name!' }]">
        <a-input v-model:value="store.name" />
      </a-form-item>

      <a-form-item :label="$t('setting.shop.phone')" name="phone" :rules="[{ required: false, message: 'Please input your phone!' }]">
        <a-input v-model:value="store.phone" />
      </a-form-item>
      <a-form-item :label="$t('setting.shop.address')" name="address" :rules="[{ required: false, message: 'Please input your address!' }]">
        <a-input v-model:value="store.address" />
      </a-form-item>

      <a-form-item :wrapper-col="{ offset: 4, span: 8 }">
        <a-button type="primary" html-type="submit" :loading="btnLoad">{{ $t('setting.shop.save') }}</a-button>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
import UploadFile from '@/components/UploadFile.vue'
export default {
  name: 'settingVue',
  components: {
    UploadFile
  },
  data() {
    return {
      //店铺信息
      store: {
        id: 0,
        name: '',
        phone: '',
        address: ''
      }
    }
  },
  created() {
    //获取信息
    let that = this
    this.$api.store.getShop().then((res) => {
      that.store = res.data
      that.imageUrl = res.data.avatar
    })

    this.$api.store.getHome().then((res) => {
      // 封面图
      if (res.data) {
        this.topImageUrl = res.data.banner
        this.topImg = ''
        this.content = res.data.content
        this.columns = res.data.columns
        this.bannerImgs = res.data.imgs
      }
    })
  },
  methods: {
    // 修改个人密码
    onSecurity() {
      const that = this
      this.$refs.secForm.validateFields().then((values) => {
        console.log(values)
        that.secbtnLoad = true
        that.$api.user
          .savePasswd(values)
          .then((res) => {
            that.secbtnLoad = false
            res
          })
          .catch(() => {
            that.secbtnLoad = false
          })
      })
    },
    /**------------------------------
     * 保存个人信息
     * @param {*} values
     */
    onFinish(values) {
      this.btnLoad = true

      console.log('params:', values)
      //参数
      values['img'] = this.img

      let that = this
      this.$api.store.saveShop(values).then((res) => {
        console.log(res)
        that.btnLoad = false
        if (res.code == 1) {
          that.$message.warning(res.msg)
        } else {
          // 更新
          that.$common.setInfo(values.name)
          that.$message.success(res.msg)
        }
      })
    },
    onFinishFailed(errorInfo) {
      console.log('Failed:', errorInfo)
    },
    onAvatarUpload(img) {
      this.imageUrl = img
      this.img = img
    },
    /**
     * 上传封面图
     * @param {*} img
     * @param {*} callback
     */
    getBase64(img, callback) {
      const reader = new FileReader()
      reader.addEventListener('load', () => callback(reader.result))
      reader.readAsDataURL(img)
    },
    /**
     * 上传头像
     * @param {*} info
     */
    onAvatarUpload1(info) {
      if (info.file.status === 'uploading') {
        this.loadingImage = true
        return
      }
      if (info.file.status === 'done') {
        var response = info.file.response
        if (response.code === 1) {
          this.loadingImage = false
          this.$message.error(response.msg)
          return
        }

        this.img = response.data.url
        this.loadingImage = false

        // Get this url from response in real world.
        this.getBase64(info.file.originFileObj, (imageUrl) => {
          this.imageUrl = imageUrl
          this.loadingImage = false
        })
      }
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/bmp'
      if (!isJpgOrPng) {
        this.$message.error('only upload jpg')
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('max 2MB!')
      }
      return isJpgOrPng && isLt2M
    },
    // 订单通知
    onOrderNotify(e) {
      console.log(this.order_notify, e)
      const that = this
      this.$api.store
        .saveSetting({ order_notify: this.order_notify ? 2 : 1 })
        .then(() => {})
        .catch((err) => {
          err
          that.order_notify = !that.order_notify
        })
    },
    onTopUpload(img) {
      this.topImageUrl = img
      this.topImg = img
    },
    // 上传顶部图
    onTopUpload1(info) {
      if (info.file.status === 'uploading') {
        this.topLoading = true
        return
      }
      if (info.file.status === 'done') {
        var response = info.file.response
        if (response.code === 1) {
          this.topLoading = false
          this.$message.error(response.msg)
          return
        }

        this.topImg = response.data.url
        this.topLoading = false

        // Get this url from response in real world.
        this.getBase64(info.file.originFileObj, (imageUrl) => {
          this.topImageUrl = imageUrl
          this.topLoading = false
        })
      }
    },
    // -------------------------------------------
    // 上传顶部图
    // 开始预览
    async onBannerPreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    // 取消预览
    onBannerCancel() {
      this.previewVisible = false
    },
    onBannerUpload(imgs) {
      console.log(imgs)
      this.bannerImgs = imgs
    },
    onDeleteUpload(imgs) {
      console.log(imgs)
      //this.bannerImgs = imgs
    },
    onBannerUpload1(info) {
      const { file, fileList } = info
      console.log('上传')
      if (file.status === 'uploading' && file.percent === 0) {
        this.loadingImageMore = true
        console.log('开始上传', file.name)
      }
      if (file.status === 'uploading' && file.percent === 100) {
        this.loadingImageMore = true
        console.log('上传100%', file.name)
      }
      if (file.status === 'removed') {
        console.log('删除图片', file.name)
        if (file.id > 0) {
          //this.rmimgs.push(file.id)
        }
      }
      if (file.status === 'done') {
        this.loadingImageMore = false
        var response = file.response
        if (response.code === 1) {
          this.$message.error(response.msg)
          this.$vue.delete(this.bannerImgs, this.bannerImgs.length - 1, 1)
          return
        }
        console.log('上传成功', file.name)
      }

      const imgs = []
      fileList.forEach((item) => {
        const temp = {
          id: 0,
          uid: item.uid,
          name: item.name,
          status: item.status,
          url: ''
        }
        if (item.response) {
          temp.url = item.response.data.url
        }
        if (item.url) {
          temp.url = item.url
        }
        if (item.id) {
          temp.id = item.id
        }
        imgs.push(temp)
      })

      this.bannerImgs = [...imgs]
      console.log(this.bannerImgs, 'imgs')
    },

    /**--------------------------------
     * 增加
     */
    onAddAttr() {
      this.columns.push({
        bgimg: '',
        bgload: false,
        goods: []
      })
    },
    // 删除板块
    onDelAttr(i) {
      this.columns.splice(i, 1)
    },
    /**
     * 上传图
     * @param {*} img
     * @param {*} callback
     */
    onColumnsUpload(img, i) {
      this.columns[i].bgimg = img
    },
    onColumnsUpload1(info, i) {
      console.log(i)
      if (info.file.status === 'uploading') {
        this.columns[i].bgload = true
        return
      }
      if (info.file.status === 'done') {
        var response = info.file.response
        if (response.code === 1) {
          this.columns[i].bgload = false
          this.$message.error(response.msg)
          return
        }
        this.columns[i].bgimg = response.data.url
        this.columns[i].bgload = false
      }
    },
    /**
     * 选择商品
     */
    onAddGoods(sort) {
      this.currentSort = sort
      this.visible = true
    },
    // 删除商品
    onDelGoods(i, k) {
      this.columns[i].goods.splice(k, 1)
    },
    selectCancel() {
      this.visible = false
    },
    selectOk(selectedRows) {
      console.log(selectedRows)
      this.confirmLoading = true

      const that = this
      selectedRows.forEach((item) => {
        if (that.columns[that.currentSort].goods.length >= 10) {
          return
        }
        that.columns[that.currentSort].goods.push({
          id: item.id,
          name: item.name,
          img: item.img
        })
      })
      console.log('values', that.columns)
      this.confirmLoading = false
      this.visible = false
    },
    onSaveHome(values) {
      this.btnHomeLoad = true
      console.log('params:', values)
      //参数
      values['img'] = this.topImg
      values['imgs'] = this.bannerImgs
      values['columns'] = this.columns

      let that = this
      this.$api.store
        .saveHome(values)
        .then((res) => {
          console.log(res)
          that.btnHomeLoad = false
          if (res.code == 1) {
            that.$message.warning(res.msg)
          } else {
            that.$message.success(res.msg)
          }
        })
        .catch(() => {
          that.btnHomeLoad = false
        })
    },
    onSaveHomeFailed(errorInfo) {
      console.log('Failed:', errorInfo)
    }
  }
}
</script>
<style scoped src="../../../static/css/store-css.css"></style>

<style>
.a-del {
  position: absolute;
  right: 10px;
  top: 0;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
}

.vo-name {
  font-size: 12px;
  white-space: normal;
  height: 38px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.op-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.del-btn {
  position: absolute;
  right: 5px;
  top: 5px;
}
</style>
