<template>
  <div id="root">
    <div class="order-wrap">
      <!-- 头部菜单 -->
      <div class="order-header">
        <!-- 导航 -->
        <div class="order-nav">
          <div class="comet-tabs">
            <div class="comet-tabs-wrapper" style="text-align: left">
              <div class="comet-tabs-nav">
                <div :class="['comet-tabs-nav-item',menu == MENU.orderAll?'comet-tabs-nav-item-active':'']" @click="onMenu(MENU.orderAll)">{{$t('member.order.all')}}</div>
                <div :class="['comet-tabs-nav-item',menu == MENU.orderUnpaid?'comet-tabs-nav-item-active':'']" @click="onMenu(MENU.orderUnpaid)">{{$t('member.order.unpaid')}}</div>
                <div :class="['comet-tabs-nav-item',menu == MENU.orderProcurement?'comet-tabs-nav-item-active':'']" @click="onMenu(MENU.orderProcurement)">{{$t('member.order.procurement')}}</div>
                <div :class="['comet-tabs-nav-item',menu == MENU.orderProcessing?'comet-tabs-nav-item-active':'']" @click="onMenu(MENU.orderProcessing)">{{$t('member.order.processing')}}</div>
                <div :class="['comet-tabs-nav-item',menu == MENU.orderShipped?'comet-tabs-nav-item-active':'']" @click="onMenu(MENU.orderShipped)">{{$t('member.order.shipped')}}</div>
                <div :class="['comet-tabs-nav-item',menu == MENU.orderCompleted?'comet-tabs-nav-item-active':'']" @click="onMenu(MENU.orderCompleted)">{{$t('member.order.completed')}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="order-form">
          <span class="comet-input-group comet-input-search order-search">
            <input placeholder="Order ID, product or store name" class="comet-input" direction="ltr" type="text" value="" />
            <span class="comet-input-group-addon">
              <button type="button" class="comet-btn comet-btn-primary comet-btn-icon-only comet-input-search-button">
                <span class="comet-icon comet-icon-search">
                  <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                    <path d="M469.333333 106.666667c200.298667 0 362.666667 162.368 362.666667 362.666666 0 88.629333-31.786667 169.824-84.586667 232.821334L865.28 820.053333a32 32 0 0 1-43.712 46.688l-1.536-1.44L702.154667 747.413333A361.205333 361.205333 0 0 1 469.333333 832c-200.298667 0-362.666667-162.368-362.666666-362.666667s162.368-362.666667 362.666666-362.666666z m0 64c-164.949333 0-298.666667 133.717333-298.666666 298.666666s133.717333 298.666667 298.666666 298.666667 298.666667-133.717333 298.666667-298.666667-133.717333-298.666667-298.666667-298.666666z"></path>
                  </svg>
                </span>
              </button>
            </span>
          </span>
          <div class="order-filter">
            <a-select v-model:value="time" :size="size" style="width: 200px">
              <a-select-option :value="0">{{$t('member.order.all.time')}}</a-select-option>
              <a-select-option :value="1">Last 6 Months</a-select-option>
              <a-select-option :value="2">Last 1 Year</a-select-option>
              <a-select-option :value="3">Last 2 Year</a-select-option>
            </a-select>
          </div>
        </div>
      </div>

      <div class="order-main">
        <!-- 子页面 -->
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
const MENU = {
  orderAll: "orderAll",
  orderUnpaid: "orderUnpaid",
  orderProcurement: "orderProcurement",
  orderProcessing: "orderProcessing",
  orderShipped: "orderShipped",
  orderCompleted: "orderCompleted",
};
export default {
  name: "orderVue",
  components: {},
  data () {
    return {
      //菜单
      MENU,
      menu: this.$route.name,

      // 时间筛选
      time: 0,
    };
  },
  created () { },
  methods: {
    onMenu (name) {
      this.menu = name;
      this.$router.push({ name: name });
    },
  },
};
</script>

<style scoped src="../../static/css/vendor.css"></style>
<style scoped src="../../static/css/order.css"></style>
