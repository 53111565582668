<template>
  <a-modal v-model:visible="RechargeVisible" :title="$t('bind.address.title')" :footer="null" :style="{ width: '800px' }">
    <div :style="{ padding: '0 20px' }">
      <a-form :model="params" name="basic" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }" autocomplete="off" @finish="onFinish" @finishFailed="onFinishFailed">
        <a-form-item label="id" name="id" :rules="[{ required: params.id > 0, message: $t('withdrawal.address.desc') }]" v-show="false">
          <a-input v-model:value="params.id" type="text" />
        </a-form-item>
        <a-form-item :label="$t('recharge.currency')">
          <a-select ref="select" v-model:value="selectIndex" style="width: 100%" @change="onCurrencyChange" :placeholder="$t('withdrawal.choice')">
            <a-select-option :value="i" v-for="(item, i) in currencyList" :key="i">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('recharge.protocol')">
          <div style="display: flex">
            <div v-for="(item, i) in protocolList" :key="i" @click="onProtocolClick(i)">
              <div :class="['xy_span', current.id == item.id ? 'active' : '']">{{ item.cp_name }}</div>
            </div>
          </div>
        </a-form-item>
        <!-- address -->
        <a-form-item :label="$t('recharge.address')" name="address" :rules="[{ required: true, message: $t('withdrawal.address.desc') }]">
          <a-input v-model:value="params.address" type="text" :placeholder="$t('withdrawal.address.desc')" />
        </a-form-item>
        <!-- 备注 -->
        <!-- <a-form-item :label="$t('withdrawal.notice')" name="username" :label-col="{ span: 4 }" :wrapper-col="{ span: 17 }">
          <div style="padding: 5px 0; line-height: 23px; color: #999">
            <div>{{ $t('wallet.desc.text') }}</div>
          </div>
        </a-form-item> -->
        <a-form-item :wrapper-col="{ offset: 8, span: 16 }">
          <a-button type="primary" html-type="submit" :loading="btnLoad">{{ $t('withdrawal.submit') }}</a-button>
          <a-button style="margin-left: 10px" @click="hide()">{{ $t('recharge.cancel.text') }}</a-button>
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'RechargeVue',
  data() {
    return {
      //存币
      RechargeVisible: false,
      selectIndex: 0,
      currencyList: [],
      protocolList: [],
      current: {},

      //加载按钮
      btnLoad: false,

      //参数
      params: {
        id: 0,
        address: ''
      },

      //编辑记录
      record: {}
    }
  },
  created() {},
  methods: {
    refresh() {
      let that = this
      this.$api.currency.getProtocolList().then((res) => {
        that.currencyList = res.data

        //是否编辑
        if (that.params.id) {
          that.getDefault()
        } else {
          //默认第一个
          that.onCurrencyChange(0)
        }
      })
    },
    getDefault() {
      this.currencyList.map((v, k) => {
        //console.log(k, v)
        v.protocol.map((vv, kk) => {
          //console.log(kk, vv)
          if (this.record.cp_id == vv.id) {
            this.onCurrencyChange(k)
            this.onProtocolClick(kk)
          }
        })
      })
    },
    /**
     * 存币
     */
    show(record = {}) {
      this.refresh()
      this.RechargeVisible = true
      // 初始化
      this.params = {
        address: ''
      }

      if (record.id) {
        this.record = record
        this.params = {
          id: record.id,
          address: record.address
        }
      }
    },
    hide() {
      this.RechargeVisible = false
    },
    // 币种改变
    onCurrencyChange(i) {
      this.selectIndex = i
      this.protocolList = this.currencyList[i].protocol
      this.onProtocolClick(0)
    },
    // 点击协议
    onProtocolClick(i) {
      this.current = this.protocolList[i]
    },
    /**
     * 提交提现
     * @param {*} values
     */
    onFinish(values) {
      //参数
      values['cp_id'] = this.current.id
      console.log('params:', values)

      this.btnLoad = true
      this.$message.loading({ content: 'bind address...', key: 'msg' })

      let that = this
      this.$api.store
        .saveStoreAddress(values)
        .then((res) => {
          that.btnLoad = false
          that.$message.success({
            content: res.msg,
            key: 'msg'
          })

          //关闭弹窗，重新刷新
          that.hide()
          //that.refresh()
          that.$emit('BindAddressSuccess')
        })
        .catch(() => {
          that.btnLoad = false
        })
      //})
    },
    onFinishFailed(e) {
      console.log(e, this.params)
    }
  }
}
</script>

<style>
.wallet-title {
  margin-right: 5px;
  font-weight: 700;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.sendcode {
  border: 1px solid #ffe5dd;
  border-radius: 5px;
  padding: 0px 10px;
  background: #ffe5dd;
  color: #ff5722;
}
</style>
