<template>
  <main class="h_9 rf bdkg">
    <div class="h_3 h_7">
      <header class="Em h_8">
        <nav class="En" aria-label="breadcrumb">
          <ul class="Rx RG">
            <li class="Rz">
              <i class="eO eR RD" aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                  <use xlink:href="#arrow_short_left"></use>
                </svg>
              </i>
              <!-- <a href="/" class="RE">лавная</a> -->
              <i class="eO eQ RC" aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                  <use xlink:href="#arrow_short_right"></use>
                </svg>
              </i>
            </li>
          </ul>
        </nav>
        <h1 class="Eo" data-testid="page-title">{{ $t('_n3._qrdd') }}</h1>
      </header>
      <div class="rc">
        <div class="ri">
          <!-- 订单清单 -->
          <section class="_2Z" v-for="(vo, i) in orderList" :key="i">
            <!-- 店铺信息 -->
            <header class="_2_0" style="display: flex; align-items: center; justify-content: space-between">
              <div class="T_9">
                <div class="Pn">
                  <label :class="['Pq', vo.checked ? 'Pw' : '']">
                    <div class="Pp">
                      <input @click="onShopCheck($event, vo)" type="checkbox" name="" class="Px" data-testid="postponeAllProducts" checked="" />
                      <div class="Pr">
                        <div class="Ps">
                          <svg class="Pu"><use xlink:href="#checkbox_fill"></use></svg>
                          <svg class="Pt"><use xlink:href="#checkbox_stroke"></use></svg>
                        </div>
                        <i class="eO eR Py" aria-hidden="true"
                          ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP"><use xlink:href="#check"></use></svg>
                        </i>
                      </div>
                    </div>
                    <div class="Pz" @click="onShop($event, vo)">
                      <span class="_2_1">{{ vo.name }}</span>
                    </div>
                  </label>
                </div>
                <div aria-live="polite" class="Gy Ua Gz">
                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="GA"><circle cx="50%" cy="50%" r="50%" class="GB GL"></circle></svg>
                </div>
              </div>
              <!-- 删除按钮 -->
              <button @click="onRmShopCartAll(vo.id)" type="button" role="button" data-testid="discardProduct" class="fS bwH fW ga f_ gh gm">
                <div class="fU">
                  <div class="fV">
                    <i class="eO eS" aria-hidden="true">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                        <use xlink:href="#trash"></use>
                      </svg>
                    </i>
                    <span class="bwI">remove</span>
                  </div>
                </div>
              </button>
            </header>
            <!-- 商品列表 -->
            <ul class="_2_2">
              <li class="_2_3" v-for="(vvo, ii) in vo.goods_list" :key="ii">
                <div class="bjt bju" v-if="vvo.goods" :style="vvo.status && vvo.status.status == 1 ? 'border: 2px solid #f00;' : ''">
                  <div class="bwA bjB">
                    <div class="Pn">
                      <!-- 选中按钮 -->
                      <label :class="['Pq', vvo.checked ? 'Pw' : '']" @click.stop="onCheck($event, vvo)">
                        <div class="Pp">
                          <input id="" type="checkbox" name="" class="Px" data-testid="postponeProduct" checked="" />
                          <div class="Pr">
                            <div class="Ps">
                              <svg class="Pu"><use xlink:href="#checkbox_fill"></use></svg>
                              <svg class="Pt"><use xlink:href="#checkbox_stroke"></use></svg>
                            </div>
                            <i class="eO eR Py" aria-hidden="true">
                              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                                <use xlink:href="#check"></use>
                              </svg>
                            </i>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div aria-live="polite" class="Gy bwB Gz">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="GA">
                        <circle cx="50%" cy="50%" r="50%" class="GB GL"></circle>
                      </svg>
                    </div>
                  </div>
                  <!-- 商品图片 -->
                  <div class="bww" aria-hidden="true">
                    <div class="pU p_ bwx">
                      <img class="pV pX" :src="vvo.goods.img" />
                    </div>
                  </div>
                  <!-- 左侧 -->
                  <div class="bjw">
                    <!-- 标签 -->
                    <ul class="bwy">
                      <li class="bwz">
                        <div class="R_5 Sc R_8 Se Sg" style="background-color: rgb(255 255 255); color: #000; border: 1px solid #000">
                          <div class="Sd">{{ $t('_n2._rx') }}</div>
                        </div>
                      </li>
                    </ul>
                    <!-- 商品名称 -->
                    <router-link :to="{ name: 'detail', query: { id: vvo.goods_id } }" class="bwD">
                      <h4 class="bwF">{{ vvo.goods.name }}</h4>
                    </router-link>
                    <!-- 规格 -->
                    <span class="bwJ" v-for="(svo, sk) in vvo.sku.remark" :key="sk">{{ sk }}:{{ svo }}</span>
                  </div>

                  <!-- 右侧 -->
                  <div class="bjC">
                    <!-- 商品价格 -->
                    <div class="bwp">
                      <span class="bws">$ {{ vvo.sku.cost_price }}</span>
                      <span class="bwq bwr">
                        <span>$ {{ vvo.sku.price }}</span>
                        <span class="bwu">
                          <span>−</span>
                          <span class="bwv">{{ $common.zkPrice(vvo.sku.price, vvo.sku.cost_price) }}</span>
                          <span>%</span>
                        </span>
                      </span>
                    </div>
                    <!-- 购买数量 -->
                    <div class="bgp bjz">
                      <button :disabled="vvo.num <= 1" @click="onSubNum(vvo)" type="button" role="button" data-testid="productDecreaseQuantity" class="fS f_6 ga f_ gh gl gu f_8">
                        <div class="fU">
                          <div class="fV">
                            <i class="eO eT" aria-hidden="true">
                              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                                <use xlink:href="#minus"></use>
                              </svg>
                            </i>
                          </div>
                        </div>
                      </button>
                      <div class="bgq">
                        <div class="n_7 oC oG oz n_8 ou ok bgr ow oh oi os">
                          <label class="oe">
                            <input @input="onNumber(vvo)" v-model="vvo.num" class="ob bgs oN" type="number" maxlength="254" spellcheck="true" data-testid="productQuantityInput" />
                            <div class="og"></div>
                          </label>
                        </div>
                      </div>
                      <div>
                        <div class="sB s_4">
                          <div class="sD">
                            <button :disabled="vvo.num >= vvo.sku.total" @click="onAddNum(vvo)" type="button" role="button" data-testid="productIncreaseQuantity" class="fS f_6 ga f_ gh gl gu f_7">
                              <div class="fU">
                                <div class="fV">
                                  <i class="eO eT" aria-hidden="true">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                                      <use xlink:href="#plus"></use>
                                    </svg>
                                  </i>
                                </div>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- 最后面 -->
                  <div class="bjD">
                    <!-- 喜欢 -->
                    <div class="_7_6 bwG">
                      <div class="Rd Rg">
                        <div class="Re">
                          <!-- <button type="button" role="button" data-testid="addToFavorite" class="fS fW ga f_ gp gm">
                            <div class="fU">
                              <div class="fV">
                                <span class="_8a">
                                  <i class="eO eS _7_8 _7_9 _8d" aria-hidden="true">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                                      <use xlink:href="#heartHollowBg"></use>
                                    </svg>
                                  </i>
                                </span>
                              </div>
                              <div class="fV"><span class="_8f">wish</span></div>
                            </div>
                          </button> -->
                          <div v-if="vvo.status && vvo.status.status == 1" style="color: #ff0000; font-weight: 700">Removed</div>
                        </div>
                      </div>
                    </div>

                    <!-- 删除按钮 -->
                    <button @click="onRmShopCart(vvo.cart_id)" type="button" role="button" data-testid="discardProduct" class="fS bwH fW ga f_ gh gm">
                      <div class="fU">
                        <div class="fV">
                          <i class="eO eS" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                              <use xlink:href="#trash"></use>
                            </svg>
                          </i>
                          <span class="bwI">remove</span>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </section>

          <!--  -->
          <loading v-if="loading"></loading>
          <empty v-if="!loading && orderList.length == 0"></empty>
        </div>

        <aside class="rk">
          <div class="Mp">
            <!-- 商品合计 -->
            <div class="Mv">
              <div class="_5B">
                <div class="bmY">
                  <div class="bmZ">
                    <span class="bix biR biy" data-testid="typography">{{ $t('_n3._njsp', { num: sumNum }) }}</span>
                    <!-- <span class="bm_">Вес заказа: 36,2&nbsp;к</span> -->
                  </div>
                  <span class="bix biR biz" data-testid="typography">$ {{ subTotal }}</span>
                </div>
                <div class="bmY">
                  <span class="bix biR biy" data-testid="typography">{{ $t('detail.delivery') }}</span>
                  <span class="biw biR biz" data-testid="typography">{{ $t('detail.free.shipping') }}</span>
                </div>
                <!-- 生效类型 -->
                <div class="bmY">
                  <span class="bix biR biy" data-testid="typography">{{ $t('order.sxsj') }}</span>
                  <div class="biw biR biz" data-testid="typography">
                    <a-radio-group v-model:value="timeType">
                      <a-radio :value="1">{{ $t('order.ljsj') }}</a-radio>
                      <a-radio :value="2">{{ $t('order.dssj') }}</a-radio>
                    </a-radio-group>
                  </div>
                </div>
                <!-- 生效时间 -->
                <div class="bmY" v-show="timeType == 2">
                  <span class="bix biR biy" data-testid="typography"> </span>
                  <div class="biw biR biz" data-testid="typography">
                    <a-date-picker -disabled-date="disabledDate" :disabled-time="disabledDateTime" :show-time="{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }" placeholder="Select Time" @change="onChangeTime" @ok="onOkTime" />
                  </div>
                </div>

                <div class="_5C">
                  <div class="bmM">
                    <div class="bmN">
                      <span>{{ $t('confirm.total') }}</span>
                    </div>
                    <div class="bmO">
                      <div class="bmP">$ {{ sumTotal }}</div>
                    </div>
                  </div>
                  <!-- <div class="bmQ">&nbsp;+604,18 бонуса</div> -->
                </div>
              </div>
            </div>
            <div class="Mz MA">
              <button @click="onSubmit()" type="button" class="fS fW ga f_1 gh gi f_4">
                <div class="fU">
                  <div class="fV">{{ $t('confirm.place.order') }}</div>
                </div>
              </button>
            </div>
          </div>
        </aside>
      </div>
    </div>
  </main>
</template>

<script>
import dayjs from 'dayjs'
import Empty from '@/components/base/Empty.vue'
import Loading from '@/components/base/Loading.vue'
const TYPE = {
  order: 'order',
  cart: 'cart'
}
export default {
  components: { Empty, Loading },
  name: 'cartVue',
  data() {
    return {
      //加载中
      loading: false,

      //当前类型
      type: '',

      //数据列表
      orderList: [],

      //购物id
      cart_ids: [],

      //按商家购物车id
      store_cart_ids: {},

      //总计金额
      subTotal: '0.00',
      sumTotal: '0.00',
      sumNum: 0,

      //生效
      timeType: 1,
      timeStr: '',

      //是否提交
      isSubmit: false
    }
  },
  setup() {
    // const range = (start, end) => {
    //   const result = []

    //   for (let i = start; i < end; i++) {
    //     result.push(i)
    //   }

    //   return result
    // }

    const disabledDate = (current) => {
      // Can not select days before today and today
      return current && current < dayjs().startOf('day')
    }

    const disabledDateTime = () => {
      return {
        // disabledHours: () => range(0, 24).splice(4, 20)
        // disabledMinutes: () => range(30, 60),
        // disabledSeconds: () => [55, 56]
      }
    }
    return {
      dayjs,
      disabledDate,
      disabledDateTime
    }
  },
  watch: {
    // $route(){
    //   location.reload()
    // }
  },
  created() {
    let query = this.$route.query
    console.log(query, 'query')
    this.init(query)
  },
  methods: {
    init(query) {
      this.type = query.type
      if (query.type == TYPE.order) {
        this.setOrder()
      } else if (query.type == TYPE.cart) {
        this.setCart()
      }
    },
    // 初始化订单
    setOrder() {
      //获取缓存参数
      let order = JSON.parse(localStorage.getItem(this.$Const.KEY_ORDER))
      if (Reflect.ownKeys(order).length === 0) {
        this.$message.warning('Please select the specific specifications of the product')
        return
      }

      console.log(order)
      let that = this
      this.loading = true
      this.$api.order
        .getConfirm(order)
        .then((res) => {
          this.loading = false
          that.orderList = res.data

          // 初始化时，都选中
          that.orderList.forEach((vo) => {
            vo.checked = true
            vo.goods_list.forEach((vvo) => {
              vvo.checked = true
            })
          })

          that.setTotal()
        })
        .catch(() => {
          // setTimeout(() => {
          //   that.$router.push({ name: 'index' })
          // }, 1000)
        })
    },
    /**
     * 获取购物车信息
     */
    setCart() {
      let that = this
      this.loading = true
      this.$api.order
        .getShopCartList()
        .then((res) => {
          this.loading = false
          that.orderList = res.data

          //组合购物车选中数据结构
          that.orderList.forEach((vo) => {
            vo.checked = true

            vo.goods_list.forEach((vvo) => {
              vvo.checked = true
            })
          })

          that.setTotal()
        })
        .catch(() => {
          // setTimeout(() => {
          //   that.$router.push({ name: 'index' })
          // }, 1000)
        })

      //购物车
      // this.$api.order.getConfirmList(order).then((res) => {
      //   that.orderList = res.data
      //   that.setTotal()
      // })
    },
    onRmShopCartAll(store_id) {
      let cart_ids = []
      if (JSON.stringify(this.store_cart_ids[store_id]) != '[]') {
        cart_ids = this.store_cart_ids[store_id]
      } else {
        this.$message.error('No products selected')
        return
      }

      let that = this
      this.$message.loading({ content: 'remove cart...', key: 'msg' })
      this.$api.order.rmShopCartAll({ store_id: store_id, cart_ids: cart_ids }).then((res) => {
        that.$message.success({
          content: res.msg,
          key: 'msg'
        })
        //重新加载
        that.setCart()
      })
    },
    onRmShopCart(cart_id) {
      let that = this
      this.$message.loading({ content: 'remove cart...', key: 'msg' })
      this.$api.order.rmShopCart({ id: cart_id }).then((res) => {
        that.$message.success({
          content: res.msg,
          key: 'msg'
        })
        //重新加载
        that.setCart()
      })
    },
    onShop(e, vo) {
      e.preventDefault()
      this.$router.push({ name: 'shopIndex', query: { id: vo.id } })
    },
    //店铺点击
    onShopCheck(e, vo) {
      e.preventDefault()
      vo.checked = !vo.checked

      vo.goods_list.forEach((sub) => {
        sub.checked = vo.checked
      })
      console.log(vo.checked)
      //计算总价
      this.setTotal()
    },
    //商品点击
    onCheck(e, vvo) {
      e.preventDefault()
      vvo.checked = !vvo.checked
      //计算总价
      this.setTotal()
    },
    onSubNum(vo) {
      if (vo.num <= 1) {
        return
      }
      vo.num--

      //请求接口
      if (this.type == TYPE.cart) {
        this.$api.order.saveShopCart({ cart_id: vo.cart_id, type: 'sub' }).then((res) => {
          console.log(res)
        })
      }

      //计算总价
      this.setTotal()
    },
    onAddNum(vo, num = 0) {
      if (vo.num >= vo.sku.total) {
        return
      }
      if (num == 0) {
        vo.num++
      }

      //请求接口
      if (this.type == TYPE.cart) {
        this.$api.order.saveShopCart({ cart_id: vo.cart_id, num: num, type: 'add' }).then((res) => {
          console.log(res)
        })
      }

      //计算总价
      this.setTotal()
    },
    onNumber(vo) {
      console.log(vo)
      let num = vo.sku.total
      if (vo.num > num) {
        vo.num = num
      }

      if (this.type == TYPE.cart) {
        this.onAddNum(vo, vo.num)
      }

      //计算总价
      this.setTotal()
    },
    /**
     * 计算总金额
     */
    setTotal() {
      let total = 0
      let totalNum = 0
      this.cart_ids = []
      this.store_cart_ids = {}
      for (var i in this.orderList) {
        let store_id = this.orderList[i].id
        if (!this.store_cart_ids[store_id]) {
          this.store_cart_ids[store_id] = []
        }

        for (var k in this.orderList[i].goods_list) {
          let goods = this.orderList[i].goods_list[k]
          if (goods.checked) {
            let price = goods.sku.price
            let num = goods.num

            if (goods.cart_id > 0) {
              this.cart_ids.push(goods.cart_id)
              this.store_cart_ids[store_id].push(goods.cart_id)
            }

            total += parseFloat(parseInt(num) * parseFloat(price))
            totalNum += parseInt(num)
          }
        }
      }

      if (total > 0) {
        this.subTotal = total.toFixed(2)
        this.sumTotal = total.toFixed(2)
        this.sumNum = totalNum
      } else {
        this.subTotal = '0.00'
        this.sumTotal = '0.00'
        this.sumNum = 0
      }
      console.log('总金额', this.subTotal, this.sumTotal, this.sumNum, this.cart_ids)
    },
    onChangeTime(e, dateString) {
      // console.log(e.value, dateString)
      this.timeStr = dateString
    },
    onOkTime() {},
    /**
     * 立即下单支付
     */
    onSubmit() {
      if (this.orderList.length <= 0 && this.cart_ids.length <= 0) {
        return
      }

      if (this.isSubmit) {
        return
      }
      this.isSubmit = true

      let param = {}

      if (this.type == TYPE.order) {
        //购买商品
        let goods = this.orderList[0].goods_list[0]
        param = {
          type: this.type,
          goods_id: goods.goods_id,
          num: goods.num,
          sku_id: goods.sku.id,
          time_type: this.timeType,
          time_str: this.timeStr
        }
      } else if (this.type == TYPE.cart) {
        if (this.cart_ids.length == 0) {
          this.$message.warning(this.$t('_n2._xzspgg'))
          return
        }

        param = {
          type: this.type,
          cart_ids: this.cart_ids,
          time_type: this.timeType,
          time_str: this.timeStr
        }
      }

      let that = this
      this.$message.loading({ content: this.$t('_dd._tijd') + '...', key: 'msg' })
      this.$api.order
        .saveOrder(param)
        .then((res) => {
          that.isSubmit = false

          if (res.code == 1) {
            that.$message.error(res.msg)
            return
          }

          //支付
          that.$router.push({ name: 'pay', query: { order_sn: res.data } })
        })
        .catch(() => {
          that.isSubmit = false
        })
    }
  }
}
</script>

<style scoped>
@import url('@/static/css2/716.fab477407da70d6e4f6f8a00ea882b.css');
@import url('@/static/css2/581.bfe04c9564d5a12e7fc76211e0b6f2.css');
</style>
<style scoped>
.bdkg {
  background: #f2f6fa;
}
</style>
