<template>
  <div class="nav-header">
    <span class="nv-title">{{ $t('_xxtz._zntz') }}</span>
    <!-- <span class="nv-desc">这是订单的管理页面，可管理用户的订单</span> -->
  </div>
  <!-- 3 -->
  <div style="display: flex; align-items: center; justify-content: center">
    <div style="width: 70%">
      <a-card>
        <div class="_dcc" v-if="loading">
          <a-spin tip="Loading..."></a-spin>
        </div>
        <a-list item-layout="horizontal" :data-source="list" v-else>
          <template #renderItem="{ item }">
            <router-link class="n-detail" :to="{ name: 'storeNoticeDetail', query: { id: item.id } }">
              <a-list-item>
                <a-list-item-meta>
                  <template #title>
                    <a-badge :dot="item.is_read == 1">
                      <a href="javascript:" :style="{ color: item.is_read == 1 ? '#000' : '#999' }">{{ item.title }}</a>
                    </a-badge>
                  </template>
                  <template #description>
                    <view>
                      <span>{{ item.create_time }}</span>
                    </view>
                  </template>
                  <!-- <template #avatar>
                    <a-avatar src="static/avatar.png" />
                  </template> -->
                </a-list-item-meta>
                <RightOutlined />
              </a-list-item>
            </router-link>
          </template>

          <template #loadMore>
            <div v-if="showmore">
              <div v-if="ismore" :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }">
                <a-button @click="onLoadMore">{{ $t('_xxtz._jzgd') }}</a-button>
              </div>
              <div v-else :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }">
                <a-button type="dashed" @click="onInit">{{ $t('_xxtz._mysj') }}</a-button>
              </div>
            </div>
          </template>
        </a-list>
      </a-card>
    </div>
  </div>
</template>

<script>
import { RightOutlined } from '@ant-design/icons-vue'
export default {
  name: 'settingVue',
  components: { RightOutlined },
  data() {
    return {
      loading: true,
      showmore: false,
      ismore: true,
      page: 1,
      size: 10,
      list: []
    }
  },
  created() {
    this.getNoticeList()
  },
  methods: {
    getNoticeList() {
      let that = this
      this.$api.notice.getNoticeList({ page: this.page, size: this.size }).then((res) => {
        if (this.page == 1) {
          //关闭加载
          that.loading = false
          //重置数据
          that.list = res.data
        } else {
          //再次加入
          res.data.forEach((item) => {
            that.list.push(item)
          })
        }

        if (that.list.length > 0) {
          //展示加载更多按钮
          that.showmore = true
        }

        if (that.list.length > 0 && res.data.length <= 0) {
          //显示没有数据了
          that.ismore = false
          return
        }

        this.page++
      })
    },
    onLoadMore() {
      this.getNoticeList()
    },
    onInit() {
      this.page = 1
      this.ismore = true
      this.getNoticeList()
    }
  }
}
</script>

<style scoped>
.b-content >>> td {
  border: 1px solid #dfdfdf !important;
}
.nav-header {
  display: flex;
  flex-direction: column;
  padding: 0 0px 20px 0px;
}

.nav-header .nv-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 40px;
}
.n-detail:hover .ant-list-item {
  background-color: #f7f7f7;
}

.ant-list-item {
  padding: 12px;
}
</style>
