<template>
  <a-modal v-model:visible="RechargeVisible" :title="$t('member.crypto.withdrawal')" :footer="null" :style="{ width: '800px' }">
    <div :style="{ padding: '0 20px' }">
      <a-form :model="params" name="basic" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }" autocomplete="off" @finish="onFinish" @finishFailed="onFinishFailed">
        <a-form-item :label="$t('recharge.currency')">
          <a-select ref="select" v-model:value="selectIndex" style="width: 100%" @change="onCurrencyChange" :placeholder="$t('withdrawal.choice')">
            <a-select-option :value="i" v-for="(item, i) in currencyList" :key="i">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('recharge.protocol')">
          <div style="display: flex">
            <div v-for="(item, i) in protocolList" :key="i" @click="onProtocolClick(i)">
              <div :class="['xy_span', current.id == item.id ? 'active' : '']">{{ item.cp_name }}</div>
            </div>
          </div>
        </a-form-item>
        <!-- address -->
        <a-form-item :label="$t('recharge.address')" name="address" :rules="[{ required: true, message: $t('withdrawal.address.choice') }]">
          <a-select ref="address" allow-clear v-model:value="params.address" style="width: 100%" @change="onAddressChange" :placeholder="$t('withdrawal.address.choice')">
            <a-select-option :value="item.address" v-for="(item, i) in current.address" :key="i">{{ item.address }}</a-select-option>
          </a-select>
        </a-form-item>
        <!-- address -->
        <!-- <a-form-item :label="$t('recharge.address')" name="address" :rules="[{ required: true, message: $t('withdrawal.address.desc') }]">
          <a-input v-model:value="params.address" type="text" :placeholder="$t('withdrawal.address.desc')" />
        </a-form-item> -->
        <!-- number -->
        <a-form-item :label="$t('withdrawal.number')" name="number" :rules="[{ required: true, message: $t('withdrawal.number.desc') }]">
          <a-input v-model:value="params.number" type="number" :step="0.00000001" :placeholder="$t('withdrawal.number.desc')">
            <template #suffix>
              <a href="javascript:" @click="onAll()">{{ $t('withdrawal.btn.all') }}</a>
            </template>
          </a-input>
        </a-form-item>
        <a-row>
          <a-col :offset="6">
            <div style="margin-bottom: 10px">
              <div>
                <span class="wallet-title">{{ $t('withdrawal.balance') }}:</span>
                <span>≈ {{ balance }} {{ current.wallet && current.name }} ({{ $t('wallet.ykcsxf') }})</span>
              </div>
              <div>
                <span class="wallet-title">{{ $t('wallet.sxf') }}:</span>
                <span>≈ {{ fee }} {{ current.wallet && current.name }}</span>
              </div>
              <div style="margin-top: 10px">
                <span class="wallet-title">{{ $t('wallet.zxje') }}:</span>
                <span>≈ {{ transfer_min }} {{ current.wallet && current.name }}</span>
              </div>
              <div v-if="transfer_24_max > 0">
                <span class="wallet-title">{{ $t('wallet.24hxe') }}:</span>
                <span>≈ {{ transfer_24_max }} {{ current.wallet && current.name }}</span>
              </div>
              <div>
                <span class="wallet-title">{{ $t('wallet.sxf') }}:</span>
                <span>≈ {{ fee_min }} ~ {{ fee_max }} {{ current.wallet && current.name }}</span>
              </div>
              <!-- <div>
                <span class="wallet-title">{{ $t('withdrawal.actual.amount') }}:</span>
                <span>≈ {{ real_number }} {{ current.wallet && current.name }}</span>
              </div> -->
            </div>
          </a-col>
        </a-row>
        <!-- code -->
        <a-form-item :label="$t('withdrawal.yzm')" name="code" :rules="[{ required: true, message: $t('withdrawal.qsryzm') }]" v-if="is_moni == 1">
          <a-input v-model:value="params.code" type="text" :step="0.00000001" :placeholder="$t('withdrawal.qsryzm')">
            <template #suffix>
              <a href="javascript:" class="sendcode" @click="onSendCode()">{{ send_code_text }}</a>
            </template>
          </a-input>
        </a-form-item>
        <!-- 备注 -->
        <a-form-item :label="$t('withdrawal.notice')" name="username" :label-col="{ span: 4 }" :wrapper-col="{ span: 17 }">
          <div style="padding: 5px 0; line-height: 23px; color: #999">
            <div>{{ $t('wallet.desc.text') }}</div>
          </div>
        </a-form-item>
        <a-form-item :wrapper-col="{ offset: 8, span: 16 }">
          <a-button type="primary" html-type="submit" :loading="btnLoad">{{ $t('withdrawal.submit') }}</a-button>
          <a-button style="margin-left: 10px" @click="hide()">{{ $t('recharge.cancel.text') }}</a-button>
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
import { countdown } from '@/utils/tools'
export default {
  name: 'RechargeVue',
  data() {
    return {
      //存币
      RechargeVisible: false,
      selectIndex: 0,
      currencyList: [],
      protocolList: [],
      current: {},

      //加载按钮
      btnLoad: false,

      //
      selectAddress: 0,
      //参数
      params: {
        address: '',
        number: '',
        code: ''
      },

      // 佣金
      commission: 0,
      real_number: 0,

      //当前余额
      balance: 0,
      transfer_min: '0.001',
      fee: '0.00',
      fee_max: '0.00',
      fee_min: '0.00',
      transfer_24_max: '0.00',
      c_name: '',

      hasSend: true,
      send_code_text: this.$t('withdrawal.fs'),

      is_moni: 2
    }
  },
  created() {},
  methods: {
    refresh() {
      let that = this
      this.$api.currency.getWithdrawalList().then((res) => {
        that.currencyList = res.data.list
        that.is_moni = res.data.is_moni
        //默认第一个
        that.onCurrencyChange(0)
      })

      let key = this.$md5('withdraw')
      countdown.run(key, that.CallTime)
    },
    /**
     * 存币
     */
    show() {
      this.refresh()
      this.RechargeVisible = true
      // 初始化
      this.params = {
        address: '',
        number: ''
      }
      this.commission = 0
      this.real_number = 0
      //this.onCurrencyChange(0)
    },
    hide() {
      this.RechargeVisible = false
    },
    // 币种改变
    onCurrencyChange(i) {
      this.selectIndex = i
      this.protocolList = this.currencyList[i].protocol
      this.onProtocolClick(0)
    },
    // 点击协议
    onProtocolClick(i) {
      this.params.address = undefined

      this.current = this.protocolList[i]

      //当前协议
      this.c_name = this.current.name + '_' + this.current.cp_name

      //24小时限额
      this.transfer_24_max = parseFloat(this.current.transfer_24_max)

      //最小提现
      this.transfer_min = parseFloat(this.current.transfer_min)

      //随机抽取费率
      this.fee_max = parseFloat(this.current.transfer_fee_max)
      this.fee_min = parseFloat(this.current.transfer_fee_min)

      //缓存生成费用
      this.setCache()

      //当前余额
      this.balance = parseFloat((parseFloat(this.current.wallet.number) - this.fee).toFixed(8))
      if (this.balance < 0) {
        this.balance = 0
      }
    },
    setCache() {
      console.log(this.c_name)
      const k1 = this.c_name + '_sp_fee'
      const k2 = this.c_name + '_sp_fee_t'

      this.fee = localStorage.getItem(k1)
      const o = localStorage.getItem(k2)
      const n = parseInt(new Date().getTime() / 1000)
      let t = parseInt(o - n)
      if (t < 0) {
        this.fee = parseFloat((Math.random() * (this.fee_max - this.fee_min) + this.fee_min).toFixed(6))
        localStorage.setItem(k1, this.fee)
        localStorage.setItem(k2, n + 60)
      }
    },
    // 币种改变
    onAddressChange(address) {
      this.params.address = address
    },
    /**
     * 提交提现
     * @param {*} values
     */
    onFinish(values) {
      console.log('params:', values)
      //this.$refs.form.validateFields().then((values) => {
      console.log(values, 111)
      //参数
      values['cp_id'] = this.current.id
      values['wallet_id'] = this.current.wallet.id
      values['fee'] = this.fee

      this.btnLoad = true
      this.$message.loading({ content: 'Submit order...', key: 'msg' })

      let that = this
      this.$api.store
        .transfer(values)
        .then((res) => {
          that.btnLoad = false
          that.$message.success({
            content: res.msg,
            key: 'msg'
          })

          //关闭弹窗，重新刷新
          that.hide()
          that.refresh()
          that.$emit('WithdrawalSuccess')
        })
        .catch(() => {
          that.btnLoad = false
        })
      //})
    },
    onFinishFailed(e) {
      console.log(e, this.params)
    },
    /**
     * 全部
     */
    onAll() {
      this.params.number = this.balance
      //this.onNumber()
    },
    /**
     * 输入数字
     */
    onNumber() {
      // let total = this.current.wallet.number;
      let name = this.current.name
      let price = this.current.price
      let number = this.params.number
      let fee_rate = parseFloat((this.current.transfer_fee_rate / 100).toFixed(8))
      let fee_min = parseFloat(this.current.transfer_fee_min)

      //小于0
      if (!number || number < 0) {
        number = ''
      }

      // 大于总数量
      // if (parseFloat(number) > total) {
      //   number = total;
      // }
      console.log(parseFloat(number), 'number')

      // 计算佣金 (市值的0.9%)
      let usdt = parseFloat((number * price).toFixed(8))
      let commission = parseFloat((usdt * fee_rate).toFixed(8))
      let commissionCoin = parseFloat((commission / price).toFixed(8))
      console.log(`输入:${number} ${name},单价:${price} USDT,费率:${fee_rate},最低:${fee_min} ${name},实际佣金:${commission} USDT ≈ ${commissionCoin} ${name}`)

      if (commissionCoin < fee_min) {
        commissionCoin = fee_min
      }

      //实际
      let real_number = parseFloat((number - commissionCoin).toFixed(8))
      if (real_number < 0) {
        real_number = 0
      }

      console.log(`[佣金:${commissionCoin} ${name},实际:${real_number} ${name}]`)
      let that = this
      setTimeout(function () {
        that.params.number = number
        that.commission = commissionCoin
        that.real_number = real_number
      }, 10)
    },
    /**
     * 发送验证码
     */
    onSendCode() {
      if (!this.hasSend) {
        return
      }

      let key = this.$md5('withdraw')

      this.$message.loading({ content: 'loading...', key: 'msg' })

      let that = this
      this.$api.code
        .sendCode({
          scene: 'withdraw'
        })
        .then((res) => {
          that.$message.success({
            content: this.$t('base.send.success') /* + ",code:" + res.data.code*/,
            key: 'msg'
          })
          countdown.start(key, res.data.exp)
          countdown.run(key, that.CallTime)
        })
    },
    /**
     * 倒计时回调
     * @param {*} time
     */
    CallTime(time) {
      console.log(time)
      if (time == 0) {
        this.hasSend = true
        this.send_code_text = this.$t('base.send.code')
      } else {
        this.hasSend = false
        this.send_code_text = this.$t('base.send.time', {
          time: time
        })
      }
    }
  }
}
</script>

<style>
.wallet-title {
  margin-right: 5px;
  font-weight: 700;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.sendcode {
  border: 1px solid #ffe5dd;
  border-radius: 5px;
  padding: 0px 10px;
  background: #ffe5dd;
  color: #ff5722;
}
</style>
