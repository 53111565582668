<template>
  <div class="ui-fixed-panel" style="position: fixed; visibility: visible; right: 250px; bottom: 200px;">
    <div class="ui-fixed-panel-operation" data-role="operationArea">
      <div class="ui-fixed-panel-unit ui-fixed-panel-go-top" data-role="hide" data-widget-cid="widget-13" style="visibility: visible; opacity: 1;">
        <a @click="onTop()"></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onTop () {
      window.scrollTo(0, 0)
    },
  }
}
</script>

<style>
.ui-fixed-panel-operation .ui-fixed-panel-unit > a {
  opacity: 0.2;
  border-radius: 16px;
}

.ui-fixed-panel-go-top > a {
  background-position: 0 0;
}

.ui-fixed-panel-unit > a {
  display: block;
  width: 32px;
  height: 32px;
  margin-bottom: 5px;
  text-decoration: none;
  cursor: pointer;
  background: url(../static/img/fixed-panel-elements.e4854f23.png) no-repeat;
}
</style>