<template>
  <section data-testid="mainBanners" class="jh _1S ji -jk">
    <div class="jj" style="">
      <div class="Jd Jj Jk">
        <swiper
          :modules="modules"
          :spaceBetween="0"
          :effect="'fade'"
          :fadeEffect="{ crossFade: true }"
          :pagination="{
            el: '.banner-slick-dots',
            type: 'custom',
            renderCustom: onRenderCustom
          }"
          :navigation="{
            nextEl: '.sl-next',
            prevEl: '.sl-prev'
          }"
          :loop="false"
          :autoplay="{
            delay: 5000,
            disableOnInteraction: false
          }"
        >
          <swiper-slide class="bhz" v-for="(vo, i) in banner" :key="i" style="background-color: #fff">
            <li class="buB">
              <div class="pU p_0 buD p_4">
                <picture class="HI">
                  <source media="(min-width: 1024px)" srcset="@/static/svg/download.svg" type="image/svg" />
                  <source media="(max-width: 1023px)" srcset="@/static/svg/download.svg" type="image/svg" />
                  <img class="pV pX pW" :src="vo.banner" fetchpriority="auto" width="1120" height="500" />
                </picture>
              </div>
              <a class="buC" :href="vo.url.length > 0 ? vo.url : 'javascript:'" rel="nofollow">
                <span class="buE">
                  <span style="vertical-align: inherit">
                    <span style="vertical-align: inherit">{{ vo.banner }}</span>
                  </span>
                </span>
              </a>
            </li>
          </swiper-slide>
          <swiper-slide class="bhz" v-if="banner.length <= 0">
            <li class="buB">
              <div class="pU p_0 buD p_4">
                <picture class="HI">
                  <source media="(min-width: 1024px)" srcset="@/static/svg/download.svg" type="image/svg" />
                  <source media="(max-width: 1023px)" srcset="@/static/svg/download.svg" type="image/svg" />
                </picture>
              </div>
              <a class="buC" href="javascript:" rel="nofollow">
                <span class="buE">
                  <span style="vertical-align: inherit">
                    <span style="vertical-align: inherit"></span>
                  </span>
                </span>
              </a>
            </li>
          </swiper-slide>
        </swiper>

        <!-- 轮播小点 -->
        <div class="Jh swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal banner-slick-dots">
          <span :class="['swiper-pagination-bullet Jm', current == i && 'swiper-pagination-bullet-active Jn']" v-for="i in total" :key="i"></span>
        </div>
        <!-- 左侧翻页 -->
        <button type="button" class="fS Je gg ga f_1 gn gi gu gt sl-prev">
          <div class="fU">
            <div class="fV">
              <i class="eO eS" aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                  <use xlink:href="#arrow_short_left"></use>
                </svg>
              </i>
            </div>
          </div>
        </button>
        <!-- 右侧翻页 -->
        <button type="button" class="fS Jf gg ga f_1 gn gi gu gt sl-next">
          <div class="fU">
            <div class="fV">
              <i class="eO eS" aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                  <use xlink:href="#arrow_short_right"></use>
                </svg>
              </i>
            </div>
          </div>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { EffectFade, Navigation, Pagination, Autoplay } from 'swiper'
export default {
  name: 'BannerVue',

  data() {
    return {
      //轮播参数
      modules: [EffectFade, Navigation, Pagination, Autoplay],
      total: 0,
      current: 0,

      banner: []
    }
  },
  created() {
    this.getBanner()
  },
  methods: {
    onRenderCustom(swiper, current, total) {
      this.total = total
      this.current = current
    },
    getBanner() {
      //获取轮播图
      let that = this
      this.$api.home.getBanner().then((res) => {
        that.banner = res.data
      })
    }
  }
}
</script>

<style></style>
