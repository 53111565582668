<template>
  <div class="nav-header">
    <span class="nv-title">{{ $t('store.products.list') }}</span>
    <!-- <span class="nv-desc">这是订单的管理页面，可管理用户的订单</span> -->
  </div>
  <div :style="{ background: '#fff', padding: '20px 20px' }">
    <!-- 第一层 -->
    <div class="nav-content">
      <div style="display: flex;gap: 20px;align-items: center;">
        <!-- <div>
          <a-button size="large">
            <template #icon>
              <PlusCircleOutlined />
            </template>
            {{ $t('products.add.new') }}
          </a-button>
          <a-divider type="vertical" />
          <a-button size="large" @click="onAddFromWareHouse()">
            <template #icon>
              <PlusCircleOutlined />
            </template>
            {{ $t('products.add.from.warehouse') }}
          </a-button>
        </div> -->
        <!-- <div style="display: flex">
          <a-popconfirm :title="$t('products.ask.delete')" :ok-text="$t('products.yes')" :cancel-text="$t('products.no')"
            @confirm="onDeleteGoods()" :disabled="selectedRowKeys.length == 0">
            <a-button type="primary" :disabled="selectedRowKeys.length == 0">
              <template #icon>
                <close-circle-outlined />
              </template>
              {{ $t('products.batch.delete') }}
            </a-button>
          </a-popconfirm>
        </div> -->
      </div>
      <!-- 右侧搜索框 -->
      <div>
        <a-space :size="20">
          <div>{{ $t('products.total') }}:{{ pagination.total }}</div>
          <div>
            <a-cascader size="large" :style="{ width: '300px' }" v-model:value="queryParam.category" :options="categorys"
              :placeholder="$t('products.search.category')" />
          </div>
          <a-input-search :style="{ width: '300px' }" v-model:value="queryParam.search"
            :placeholder="$t('base.search.text')" :enter-button="$t('base.search')" size="large" @search="onSearch" />
          <a-button size="large" @click="onReset">{{$t('_st._czsp')}}</a-button>
        </a-space>
      </div>
    </div>
    <!-- 第二层 -->
    <div class="nav-content">
      <div style="display: flex;gap: 20px;align-items: center;">
        <div>
          <a-button size="large">
            <template #icon>
              <PlusCircleOutlined />
            </template>
            {{ $t('products.add.new') }}
          </a-button>
          <a-divider type="vertical" />
          <a-button size="large" @click="onAddFromWareHouse()">
            <template #icon>
              <PlusCircleOutlined />
            </template>
            {{ $t('products.add.from.warehouse') }}
          </a-button>
        </div>
        <div style="display: flex">
          <a-popconfirm :title="$t('products.ask.delete')" :ok-text="$t('products.yes')" :cancel-text="$t('products.no')"
            @confirm="onDeleteGoods()" :disabled="selectedRowKeys.length == 0">
            <a-button type="primary" :disabled="selectedRowKeys.length == 0">
              <template #icon>
                <close-circle-outlined />
              </template>
              {{ $t('products.batch.delete') }}
            </a-button>
          </a-popconfirm>
        </div>
      </div>
      <!-- 右侧搜索框 -->
      <div>
        <!-- <a-space :size="20">
          <div>{{ $t('products.total') }}:{{ pagination.total }}</div>
          <div>
            <a-cascader size="large" :style="{ width: '300px' }" v-model:value="queryParam.category" :options="categorys"
              :placeholder="$t('products.search.category')" />
          </div>
          <a-input-search :style="{ width: '300px' }" v-model:value="queryParam.search"
            :placeholder="$t('base.search.text')" :enter-button="$t('base.search')" size="large" @search="onSearch" />
          <a-button size="large" @click="onReset">{{$t('_st._czsp')}}</a-button>
        </a-space> -->
      </div>
    </div>
    <div class="tb-box" style="margin-top: 10px;overflow-y: auto">
      <a-table :rowKey="(record) => {
        return record.id
      }
        " :row-selection="rowSelection" :columns="columns" :data-source="dataSource" :pagination="pagination"
        :loading="loading" @change="getStoreProducts" :scroll="{ x: 1500, y: 1200 }">
        <template #bodyCell="{ column, record }">
          <template v-if="record && column.dataIndex === 'img'">
            <img :src="record.img" width="50" style="margin-right: 5px" />
          </template>
          <template v-if="record && column.dataIndex === 'name'">
            <div style="display: flex; align-items: center">
              <div style="font-size: 13px">{{ record.name }}</div>
            </div>
          </template>
          <template v-if="record && column.dataIndex === 'price'">
            <div>${{ record.price }}</div>
          </template>
          <template v-if="record && column.dataIndex === 'profit_value'">
            <div>
              <a-tag color="green"> ≈ ${{ record.profit_value }}</a-tag>
            </div>
          </template>
          <template v-if="record && column.dataIndex === 'top'">
            <div>
              <a-tag color="green" v-if="record.top == 3">{{ $t('products.t1') }}</a-tag>
              <a-tag color="cyan" v-if="record.top == 2">{{ $t('products.t2') }}</a-tag>
              <a-tag color="blue" v-if="record.top == 1">{{ $t('products.t3') }}</a-tag>
              <a-tag color="orange" v-if="record.top == 0">{{ $t('products.t4') }}</a-tag>
            </div>
          </template>
          <template v-else-if="column.key === 'action'">
            <span>
              <a href="javascript:" style="color: #f90" @click="onSelect(record)">{{ $t('products.syzd') }}</a>
              <a-divider type="vertical" />
              <a href="javascript:" @click="onTop(record)">{{ $t('products.top') }}</a>
              <a-divider type="vertical" />
              <a-popconfirm :title="$t('products.ask.delete')" :ok-text="$t('products.yes')"
                :cancel-text="$t('products.no')" @confirm="onDelete(record)">
                <a href="javascript:">{{ $t('products.delete') }}</a>
              </a-popconfirm>
            </span>
          </template>
        </template>
      </a-table>
    </div>

    <!-- 弹框 -->
    <a-modal v-model:visible="visible" style="width: 400px" :title="$t('products.syzd')">
      <div>
        <a-form ref="form" :model="formState" name="basic" :label-col="{ span: 7 }" :wrapper-col="{ span: 18 }"
          autocomplete="off">
          <!-- 选择板块 -->
          <a-form-item :label="$t('products.zdwz')">
            <a-radio-group v-model:value="formState.type" name="radioGroup" @change="onType">
              <a-radio value="0">{{ $t('products.t4') }}</a-radio>
              <a-radio value="3">{{ $t('products.t1') }}</a-radio>
              <a-radio value="2">{{ $t('products.t2') }}</a-radio>
              <a-radio value="1">{{ $t('products.t3') }}</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-form>
      </div>
      <template #footer>
        <a-button key="submit" type="primary" :loading="btnLoad" @click="onSave">{{ $t('setting.shop.save') }}</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { PlusCircleOutlined, CloseCircleOutlined } from '@ant-design/icons-vue'
export default defineComponent({
  name: 'productsVue',
  components: {
    PlusCircleOutlined,
    CloseCircleOutlined
  },
  setup() { },
  data() {
    return {
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          sorter: false,
          width: '70px',
          // fixed: 'left'
        },
        {
          title: this.$t('products.table.img'),
          dataIndex: 'img',
          width: '120px'
        },
        {
          title: this.$t('products.table.name'),
          dataIndex: 'name',
          width: '500px'
        },
        {
          title: this.$t('products.table.category'),
          dataIndex: 'cate_name',
          width: '150px'
        },
        {
          title: this.$t('products.table.wish'),
          dataIndex: 'wish_num',
          width: '120px'
        },
        {
          title: this.$t('products.table.stock'),
          dataIndex: 'total',
          width: '120px'
        },
        {
          title: this.$t('products.table.price'),
          dataIndex: 'price',
          width: '120px'
        },
        {
          title: this.$t('products.table.profit'),
          dataIndex: 'profit_value',
          width: '120px'
        },
        {
          title: this.$t('products.zdwz'),
          dataIndex: 'top',
          width: '120px'
        },
        {
          title: this.$t('products.table.action'),
          key: 'action',
          width: '180px',
          fixed: 'right',
        }
      ],
      loading: false,
      pagination: { total: 0, current: 1, pageSize: 20 },
      dataSource: [],
      queryParam: {
        search: '',
        goods_id: 0,
        category: []
      },

      //筛选
      categorys: [],

      //批量选中
      selectedRowKeys: [],
      selectedRows: [],

      //-------------------
      //当前商品
      current: {},
      //提交信息
      formState: {
        type: '0'
      },
      //弹框显示
      visible: false,
      //加载中
      btnLoad: false
    }
  },
  computed: {
    rowSelection() {
      return {
        onChange: this.onSelectChange
      }
    }
  },
  created() {

    //获取goods_id 从排行榜点击过来
    console.log(this.$route)
    if (this.$route.query.id) {
      this.queryParam.goods_id = this.$route.query.id
    }

    this.getStoreProducts(this.pagination)

    // 获取分类
    const that = this
    this.$api.category.getCategorys().then((res) => {
      that.categorys = res.data.options
    })
  },
  methods: {
    onReset() {
      this.queryParam = {
        search: '',
        goods_id: 0,
        category: []
      }
      this.getStoreProducts(this.pagination)
    },
    onSelect(record) {
      this.current = record
      this.visible = true
    },
    /**
     * 添加单个商品
     * @param {*} record
     */
    onSave() {
      const that = this
      this.$refs.form.validateFields().then((values) => {
        values.type = that.formState.type
        values.goods_id = that.current.id
        console.log(values)
        that.btnLoad = true
        this.$message.loading({
          content: 'submit...',
          key: 'msg',
          duration: 50
        })
        that.$api.store
          .saveHomeTop(values)
          .then((res) => {
            that.btnLoad = false
            that.visible = false
            that.$message.success({
              content: res.msg,
              key: 'msg'
            })
            that.getStoreProducts(this.pagination)
          })
          .catch(() => {
            that.btnLoad = false
          })
      })
    },
    onTop(record) {
      this.$message.loading({ content: 'set top...', key: 'msg', duration: 50 })
      let that = this
      this.$api.store.saveGoodsTop({ id: record.id }).then((res) => {
        that.$message.success({
          content: res.msg,
          key: 'msg'
        })
        that.getStoreProducts(that.pagination)
      })
    },
    /**
     * 选中框
     * @param {*} selectedRowKeys
     * @param {*} selectedRows
     */
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      console.log(selectedRowKeys, selectedRows)
    },
    onDelete(record) {
      this.$message.loading({ content: 'delete...', key: 'msg', duration: 50 })
      let that = this
      this.$api.store.rmStoreProduct({ ids: [record.id] }).then((res) => {
        that.$message.success({
          content: res.msg,
          key: 'msg'
        })
        that.getStoreProducts(that.pagination)
      })
    },
    /**
     * 批量添加
     */
    onDeleteGoods() {
      this.$message.loading({ content: 'delete...', key: 'msg', duration: 50 })
      let that = this
      this.$api.store.rmStoreProduct({ ids: this.selectedRowKeys }).then((res) => {
        that.$message.success({
          content: res.msg,
          key: 'msg'
        })
        that.selectedRowKeys = []
        that.getStoreProducts(that.pagination)
      })
    },
    onAddFromWareHouse() {
      this.$router.push({ name: 'StoreAddFromHouse' })
    },
    onSearch() {
      this.getStoreProducts(this.pagination)
    },
    getStoreProducts(parameter) {
      const pagination = {
        page: parameter.current,
        size: parameter.pageSize
      }
      const requestParameters = Object.assign({}, pagination, this.queryParam)

      this.loading = true

      let that = this
      this.$api.store.getStoreProducts(requestParameters).then((res) => {
        that.loading = false
        that.pagination = {
          total: res.data.total,
          current: res.data.pageNo,
          pageSize: res.data.pageSize
        }
        that.dataSource = res.data.data
      })
    }
  }
})
</script>

<style scoped src="@/static/css/store-css.css"></style>
<style>
.tb-box >>> .ant-table table {
  min-width: 1200px;
}
</style>
