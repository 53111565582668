<template>
  <div style="background: #f5f5f5;">
    <!-- 顶部导航 -->
    <head-top :isCategory="false"></head-top>

    <router-view></router-view>
    <!-- 页脚 -->
    <footer-vue></footer-vue>
  </div>
</template>

<script>
import HeadTop from '@/components/base/HeadTop.vue'
import FooterVue from '@/components/base/FooterVue.vue'
export default {
  name: 'layoutVue',
  components: {
    HeadTop,
    FooterVue
  }
}
</script>
<style src="@/static/css/member-index.css"></style>
<style scoped>
@media only screen and (min-width: 1024px) {
  .H1R {
    font-size: 2.5rem;
    line-height: 3rem;
  }
}

.H1R {
  font-weight: 700;
  color: #000;
  font-size: 1.75rem;
  line-height: 2rem;
  margin-bottom: 25px;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
}
</style>
