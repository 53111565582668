<template>
  <div class="comet-tabs TabsViewer--tabsContainer--OY1QcUp null">
    <!-- 标题 -->
    <div class="comet-tabs-wrapper">
      <div class="comet-tabs-nav">
        <div class="comet-tabs-nav-item comet-title">{{$t('followlist.title')}}</div>
      </div>
    </div>
    <!-- 内容 -->
    <div class="comet-tabs-container">
      <div class="comet-tabs-pane comet-tabs-pane-active">
        <div>
          <div class="AllItemsPage--allItemsPageContainer--3YeJslK">
            <div>
              <div class="AllItemsPage--listContainer--2joB-BW">

                <!-- 加载更多 -->
                <div class="loadmore" v-show="loading">
                  <img class="_2O2Fm" src="@/static/img/loading.gif" />
                </div>

                <!-- item -->
                <div v-for="(item,i) in followList" :key="i" class="AllItemsPage--listItemContainer--1iA48ky">
                  <!-- 左边内容 -->

                  <router-link class="AllListItem--alllistItemContainer--3BpNMAE" :to="{name:'storeHome',query:{id:item.store_id}}">
                    <div class="AllListItem--leftContainer--1eqBej2">
                      <div class="Image--imageContainer--24sj7qa AllListItem--image--3LSk1Fr" :style="'background-image: url('+item.avatar+');background-size: 100% 100%;'"></div>
                    </div>
                    <div class="AllListItem--rightContainer--2AiihCN">
                      <h3 class="AllListItem--productNameText--3aZEYzK ellipse">{{item.name}}</h3>
                      <h4 class="AllListItem--emptyInventoryText--f-T-5lL">{{item.follow}} {{$t('followlist.follow')}}</h4>
                      <p class="AllListItem--priceNowText--24hulSy">SID:{{item.sid}}</p>
                    </div>
                  </router-link>
                  <!-- 右边按钮 -->
                  <div class="AllItemEdit--allItemEditContainer--1PyiTPB">
                    <div class="AllItemEdit--editButtonContainer--j_GUgpf">
                      <!-- <button type="button" class="comet-btn AllItemEdit--deleteButtonPC--1kl3v1-">
                        <span>
                          <div>Move to cart</div>
                        </span>
                      </button> -->
                      <button @click="onDelete(item)" type="button" class="comet-btn AllItemEdit--deleteButtonPC--1kl3v1-">
                        <span>
                          <div>{{$t('followlist.delete')}}</div>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FollowlistVue",
  data () {
    return {
      followList: [],
      page: 1,
      size: 10,

      loading: true,
    }
  },
  created () {
    this.getFollowList()
  },
  methods: {
    getFollowList () {
      let that = this
      this.$api.user.getFollowList({ page: this.page, size: this.size }).then(res => {
        that.loading = false
        that.followList = res.data
      })
    },
    onDelete (item) {
      let that = this;
      this.$message.loading({ content: "delete wish...", key: "msg" });
      this.$api.user.saveFollow({ store_id: item.store_id }).then((res) => {
        that.$message.success({
          content: res.msg,
          key: "msg",
        });
        //重新加载
        that.getFollowList();
      });
    },
  }
}
</script>

<style src="@/static/css/wishlist.css"></style>