import request from '@/utils/request'

const api = {
  apply: 'finance/apply',
  repayment: 'finance/repayment',
  getApplyList: 'finance/getApplyList'
}

export default {
  apply(parameter) {
    return request({
      url: api.apply,
      method: 'post',
      data: parameter
    })
  },
  repayment(parameter) {
    return request({
      url: api.repayment,
      method: 'post',
      data: parameter
    })
  },
  getApplyList(parameter) {
    return request({
      url: api.getApplyList,
      method: 'post',
      data: parameter
    })
  }
}
