<template>
  <div class="a navigation-mouse">
    <!-- header -->
    <head-top></head-top>

    <!-- 主界面 -->
    <router-view></router-view>

    <!-- 页脚 -->
    <FooterVue></FooterVue>
  </div>
</template>

<script>
import HeadTop from '@/components/base/HeadTop.vue'
import FooterVue from "@/components/base/FooterVue.vue"
export default {
  name: 'IndexVue',
  components: {
    HeadTop,
    FooterVue,
},

  watch: {
    router(a, b) {
      console.log(a, b)
    }
  }
}
</script>

<style scoped>
@import url('@/static/css2/559.b5a41cb40f752f33301a8f0486b832.css');
@import url('@/static/css2/143.6be7130b9f1e1036d2a16a0fc98f97.css');
@import url('@/static/css2/352.244e03012504d11fa9ec86dc8f080b.css');
@import url('@/static/css2/382.0080575219454102783a786154ae21.css');
@import url('@/static/css2/648.b20b73024dc54a4aaa179652ecf7ec.css');
@import url('@/static/css2/293.90477769df0c88fd442dcde29cdf95.css');
@import url('@/static/css2/966.d6015f1f32f53b3985af924d76d44f.css');
</style>
