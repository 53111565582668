import request from '@/utils/request'

const api = {
  login: 'auth/login',
  autoLogin: 'auth/autoLogin',
  signup: 'auth/signup',
  checkEmail: 'auth/checkEmail',
  resetPasswd: 'auth/resetPasswd'
}

export default {
  login(parameter) {
    return request({
      url: api.login,
      method: 'post',
      data: parameter
    })
  },
  autoLogin(parameter) {
    return request({
      url: api.autoLogin,
      method: 'post',
      data: parameter
    })
  },
  signup(parameter) {
    return request({
      url: api.signup,
      method: 'post',
      data: parameter
    })
  },
  checkEmail(parameter) {
    return request({
      url: api.checkEmail,
      method: 'post',
      data: parameter
    })
  },
  resetPasswd(parameter) {
    return request({
      url: api.resetPasswd,
      method: 'post',
      data: parameter
    })
  }
}
