<template>
  <slot name="banner" :recommend="recommend"></slot>
  <!-- 第一板块 -->
  <div class="t1">Prior Choice</div>
  <div class="bk01">
    <div class="bk01-box">
      <div @click="$router.push({ name: 'detail', query: { id: item.id } })" class="gi-item" v-for="(item, i) in preferred" :key="i">
        <div class="gi-img" v-if="item.goods">
          <img :src="item.goods.img" alt="" />
        </div>
        <div class="gi-info" v-if="item.goods">
          <div class="gi-name">{{ item.goods.name }}</div>
          <div class="gi-price-box">
            <div class="gi-price">US ${{ item.goods.price }}</div>
            <div class="gi-cost-price">/US ${{ item.goods.cost_price }}</div>
            <div class="gi-dist">-{{ $common.zkPrice(item.goods.price, item.goods.cost_price) }}%</div>
          </div>
          <div class="gi-price-box gi-btn">
            <img src="../../static/temp/1/bt1.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 第二板块 -->
  <div style="">
    <div style="width: 100%; margin: 0px auto">
      <div style="">
        <div style="width: 100%; height: 0.01px"></div>
        <div dir="ltr" style="width: 1220px; margin: 0px auto; font-family: Arial, Helvetica, sans-senif, SimSun">
          <div style="width: 1220px; text-align: center; margin-top: 30px; margin-bottom: 25px">
            <span data-locale="663914722_title" style="position: relative; box-sizing: border-box; flex-direction: column; align-content: flex-start; flex-shrink: 0; margin-top: 38px; margin-bottom: 32px; font-size: 32px; line-height: 40px; font-weight: 700; color: rgb(0, 0, 0)">Boutique</span>
          </div>
          <div class="goods-box">
            <div @click="$router.push({ name: 'detail', query: { id: item.id } })" v-for="(item, i) in boutique" :key="i" style="width: 100%; position: relative; box-sizing: border-box; display: inline-block; flex-direction: column; align-content: flex-start; flex-shrink: 0">
              <div style="width: 100%; margin-bottom: 16px; float: left" v-if="item.goods">
                <a style="width: 100%; text-decoration: none; display: flex; flex-direction: row; align-items: center; text-align: center; vertical-align: middle; justify-content: center; height: 250px; background: rgb(255, 255, 255); border-width: 1px; border-style: solid; border-color: rgb(242, 242, 242); padding: 8px">
                  <img :src="item.goods.img" style="width: 100%; height: 100%; display: block; max-width: 270px; max-height: 270px; cursor: pointer" />
                </a>
                <a style="text-decoration: none; display: block; width: 100%; font-family: OpenSans">
                  <span style="width: 100%; padding: 0 5px; position: relative; box-sizing: border-box; flex-direction: column; align-content: flex-start; flex-shrink: 0; margin-top: 8px; margin-bottom: 0px; font-size: 13px; line-height: 18px; height: 36px; color: rgb(51, 51, 51); overflow: hidden; text-overflow: ellipsis; cursor: pointer; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2">{{ item.goods.name }}</span></a
                >
                <div style="position: relative; box-sizing: border-box; display: flex; flex-direction: row; place-content: flex-start space-between; flex-shrink: 0">
                  <div style="position: relative; box-sizing: border-box; display: flex; flex-direction: row; align-content: flex-start; flex-shrink: 0; align-items: center">
                    <span style="position: relative; box-sizing: border-box; flex-direction: column; align-content: flex-start; flex-shrink: 0; margin-top: 4px; margin-bottom: 0px; float: left; font-family: OpenSans-Bold; font-weight: 700; font-size: 18px; color: rgb(0, 0, 0); line-height: 24px; overflow: hidden; text-overflow: ellipsis; width: 144px; font-style: italic; padding-right: 2px; white-space: nowrap">US ${{ item.goods.price }}</span>
                  </div>
                  <span style="position: relative; box-sizing: border-box; flex-direction: column; align-content: flex-start; flex-shrink: 0; margin-top: 4px; margin-bottom: 0px; float: right; font-family: OpenSans; font-size: 12px; color: rgb(153, 153, 153); line-height: 24px; text-align: right; white-space: nowrap; overflow: hidden">{{ item.orders }} orders</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style=""></div>
        <div class="ae-pc-productList-footer" style="width: 1200px; height: 0px; background-color: transparent; margin-bottom: 20px"></div>
      </div>
    </div>
  </div>
  <!-- 返回顶部 -->
  <div style="">
    <div style="width: 100%; margin: 0px auto">
      <div style="">
        <div style="width: 100%; height: 0.01px"></div>
        <div style="width: 100%; margin: 0px auto; cursor: pointer; min-width: 1200px; align-items: center">
          <a style="text-decoration: none"><img src="../../static/temp/1/f1.jpg" style="width: 100%; display: block; margin: 0px auto" /></a>
        </div>
        <div style=""></div>
        <div class="ae-pc-singleImageText-footer" style="width: 1200px; height: 0px; background-color: transparent; margin-bottom: 0px"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'template1Vue',
  props: {
    store_id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      store: {},
      preferred: [],
      boutique: []
    }
  },
  created() {
    this.getStoreTemplate()
  },
  methods: {
    getStoreTemplate() {
      this.$api.storeInfo.getStoreTemplate({ id: this.store_id, template: 1 }).then((res) => {
        this.store = res.data.store
        this.preferred = res.data.preferred
        this.boutique = res.data.boutique
      })
    }
  }
}
</script>

<style>
.t1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 65px;
  color: #e74f41;
  font-weight: 700;
  padding-top: 20px;
  background-color: rgb(249, 234, 213);
}
.bk01 {
  padding: 30px 0 60px 0;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: #f9ead5;
  position: relative;
}
.bk01-box {
  width: 1220px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18.5%, 1fr));
  gap: 20px;
  box-sizing: border-box;
}

.gi-item {
  height: 300px;
  overflow: hidden;
  border-radius: 10px;
  background-color: #fff;
}

.gi-img {
  overflow: hidden;
  height: 70%;
}

.gi-img img {
  width: auto;
  height: 100%;
  width: 100%;
}

.gi-info {
  height: 30%;
}

.gi-name {
  font-size: 18px;
  white-space: nowrap;
  font-weight: 600;
  text-overflow: ellipsis;
  padding: 0 15px;
  overflow: hidden;
}

.gi-price-box {
  display: flex;
  padding: 0 0px;
  align-items: center;
  justify-content: center;
}

.gi-price {
  font-size: 15px;
  font-weight: 700;
  color: #4d53a1;
}

.gi-cost-price {
  font-size: 14px;
  color: #999;
  font-weight: 600;
  margin-left: 5px;
}

.gi-dist {
  padding: 0px 5px;
  background-color: #e44c3e;
  color: #fff;
  border-radius: 15px;
  font-weight: 600;
  margin-left: 5px;
  font-size: 12px;
}

.gi-btn {
  margin-top: 5px;
  cursor: pointer;
}

.goods-box {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(18.5%, 1fr));
}
</style>
