<template>
  <a-modal v-model:visible="Visible" :title="$t('_wd._nbjy')" @ok="hide()" :style="{ width: '800px' }" :okText="$t('recharge.ok.text')" :cancelText="$t('recharge.cancel.text')">
    <div :style="{ padding: '0 20px' }" v-if="step == 1">
      <a-form :model="params" name="basic" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }" autocomplete="off">
        <!-- sid -->
        <a-form-item label="UID" name="uid" :rules="[{ required: true, message: $t('_wd._qsruid') }]">
          <a-input v-model:value="params.uid" type="number" :placeholder="$t('_wd._qsruid')" />
        </a-form-item>
        <!-- amount -->
        <a-form-item :label="$t('_wd._je')" name="number" :rules="[{ required: true, message: $t('_wd._qsrhzje') }]">
          <a-input suffix="USD" v-model:value="params.number" type="number" :step="0.00000001" :placeholder="$t('_wd._qsrhzje')" />
        </a-form-item>
      </a-form>
    </div>

    <div :style="{ padding: '0 20px' }" v-if="step == 2">
      <a-form :model="params" name="basic" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }" autocomplete="off">
        <a-form-item :label="$t('_wd._skr')"> {{ store.name }}({{ store.sid }}) </a-form-item>
        <!-- amount -->
        <a-form-item :label="$t('_wd._je')" style=""> ${{ params.number }} </a-form-item>
        <!-- 备注 -->
        <a-form-item :label="$t('withdrawal.notice')" name="username" :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }">
          <div style="padding: 5px 0; line-height: 23px; color: #999">
            <div>{{ $t('_wd._nbhzsm') }}</div>
          </div>
        </a-form-item>
      </a-form>
    </div>
    <template #footer>
      <a-button type="primary" @click="onNext" v-if="step == 1">{{ $t('bank.ok.text') }}</a-button>
      <template v-if="step == 2">
        <a-button type="primary" @click="onNext" v-if="step == 1">{{ $t('bank.ok.text') }}</a-button>
        <a-button key="back" @click="onPrev" v-if="step == 2">{{ $t('bank.ok.prev') }}</a-button>
        <a-button key="submit" type="primary" :loading="loadingBtn" @click="onSubmit" v-if="step == 2">{{ $t('bank.submit') }}</a-button>
      </template>
    </template>
  </a-modal>
</template>

<script>
export default {
  name: 'InternalTransfer',
  components: {},
  emits: ['InternalTransferSuccess'],
  data() {
    return {
      Visible: false,
      //步骤
      step: 1,

      // 提交信息
      params: {
        number: '',
        uid: ''
      },

      //内部划转用户
      store: {},

      // 上传图片
      loading: false,
      imageUrl: '',
      img: ''
    }
  },
  created() {},
  methods: {
    /**
     * 存币
     */
    show() {
      this.step = 1
      this.store = {}
      this.params = {
        number: '',
        uid: ''
      }
      this.Visible = true
    },
    hide() {
      this.Visible = false
    },
    onNext() {
      if (!this.valid()) {
        return
      }
      let that = this
      //通过uid查询用户信息
      this.$api.user
        .getUser({ uid: this.params.uid })
        .then((res) => {
          that.store = res.data
          this.step = 2
        })
        .catch((err) => {
          console.log(err)
        })
    },
    onPrev() {
      this.step = 1
    },
    valid() {

      if (this.params.uid === '') {
        this.$message.error('uid cannot be empty')
        return false
      }

      if (this.params.number === '') {
        this.$message.error('amount cannot be empty')
        return false
      }

      return true
    },
    onSubmit() {
      this.loadingBtn = true

      if (!this.valid()) {
        this.loadingBtn = false
        return
      }

      //提交到后台
      const that = this
      this.$api.store
        .internalTransfer(this.params)
        .then((res) => {
          that.loadingBtn = false
          if (res.code == 0) {
            that.$message.success(res.msg)
            setTimeout(function () {
              that.hide()
              that.$emit('InternalTransferSuccess')
            }, 500)
          }
        })
        .catch((err) => {
          err
          that.loadingBtn = false
        })
    }
  }
}
</script>

<style></style>
