import request from '@/utils/request'

const api = {
  getRechargeList: 'currency/getRechargeList',
  getBankCardList: 'currency/getBankCardList',
  getWithdrawalList: 'currency/getWithdrawalList',
  getProtocolList: 'currency/getProtocolList'
}

export default {
  getRechargeList(parameter) {
    return request({
      url: api.getRechargeList,
      method: 'post',
      data: parameter
    })
  },
  getBankCardList(parameter) {
    return request({
      url: api.getBankCardList,
      method: 'post',
      data: parameter
    })
  },
  getWithdrawalList(parameter) {
    return request({
      url: api.getWithdrawalList,
      method: 'post',
      data: parameter
    })
  },
  getProtocolList(parameter) {
    return request({
      url: api.getProtocolList,
      method: 'post',
      data: parameter
    })
  }
}
