import request from "@/utils/request";

const api = {
  getCategory: "category/getCategory",
  getCategorys: "category/getCategorys",
  getCategoryList: "category/getCategoryList",
};

export default {
  getCategory(parameter) {
    return request({
      url: api.getCategory,
      method: "post",
      data: parameter,
    });
  },
  getCategorys(parameter) {
    return request({
      url: api.getCategorys,
      method: "post",
      data: parameter,
    });
  },
  getCategoryList(parameter) {
    return request({
      url: api.getCategoryList,
      method: "post",
      data: parameter,
    });
  },
};
