<template>
  <section class="Bw">
    <div class="T_1" style="display: flex">
      <section class="BZ" style="flex: 1">
        <h3 class="Cg">
          <span style="vertical-align: inherit">
            <span style="vertical-align: inherit">{{ $t('_n2._sjxx') }}</span>
          </span>
        </h3>
        <div class="B_ B_0">
          <div class="B_2">
            <div class="B_4" style="width: 150px">
              <div class="B_5">
                <router-link :to="{ name: 'shopIndex', query: { id: store.id } }">
                  <div style="vertical-align: inherit; cursor: pointer">
                    <div class="s_name">{{ store && store.name }}</div>
                  </div>
                </router-link>
              </div>
              <div class="B_6 s_item">
                <div style="vertical-align: inherit">
                  <div>
                    <div class="lev-bg" v-if="store.level > 5">
                      <div v-for="(vo, k) in store.level - 5" :key="k">
                        <img src="@/static/level/c.png" />
                      </div>
                    </div>
                    <div class="lev-bg" v-else>
                      <div v-for="(vo, k) in store.level" :key="k">
                        <img src="@/static/level/b.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="s_item">
                <span style="vertical-align: inherit">{{ store.follow_str }} {{ $t('base.follow') }}</span>
              </div>
              <div class="s_item s_contact">
                <div @click="onFollow()" :class="['Xn', 'Xl']" style="margin-bottom: 10px">
                  <HeartOutlined v-if="store.is_follow == 1" />
                  <heart-filled v-if="store.is_follow == 2" />
                  <span>
                    <span style="vertical-align: inherit">
                      <span style="vertical-align: inherit">{{ store.is_follow == 1 ? $t('base.follow') : $t('base.following') }}</span>
                    </span>
                  </span>
                </div>

                <div class="CXn CXm Xn" @click="onChat()">
                  <MessageOutlined />
                  <span>
                    <span style="vertical-align: inherit">
                      <span style="vertical-align: inherit">{{ $t('_n._lxwm') }}</span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { MessageOutlined, HeartOutlined, HeartFilled } from '@ant-design/icons-vue'
export default {
  name: 'StoreDescVue',
  components: {
    MessageOutlined,
    HeartOutlined,
    HeartFilled
  },
  props: {
    store_id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      storeContent: {},
      goods_id: 0
    }
  },
  watch: {
    store_id(id) {
      console.log(id, 'store_id')
      this.$store.dispatch('store/getStore', { id: this.store_id })
    }
  },
  created() {
    this.goods_id = this.$route.query.id
  },
  computed: {
    ...mapState({
      store: (state) => state.store.info
    })
  },
  methods: {
    onChat() {
      //是否登录
      if (!this.$common.isLogin()) {
        this.$message.warning({
          content: this.$t('_n2._qxdl')
        })
        return
      }

      let user = this.$common.getInfo()
      if (user.type == 2) {
        this.$message.warning({
          content: this.$t('_n3._sjbnlt')
        })
        return
      }

      this.$router.push({ name: 'message', query: { code: this.store.sid, goods_id: this.goods_id } })
    },
    /**
     * 添加到喜欢列表
     */
    onFollow() {
      let that = this
      this.$message.loading({ content: 'follow store...', key: 'msg' })
      this.$api.user
        .saveFollow({
          store_id: this.store.id
        })
        .then((res) => {
          //设置当前喜欢状态
          that.store.is_follow = res.data.code
          that.store.follow_str = res.data.num
          //提示成功
          that.$message.success({
            content: res.msg,
            key: 'msg'
          })
        })
    }
  }
}
</script>

<style scoped>
.s_name {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 20px;
}

.s_item {
  width: 100%;
  margin: 5px 0;
}
.s_contact {
  cursor: pointer;
}

.ygz {
  color: #ffffff !important;
  fill: #ffffff !important;
  background-color: #000000 !important;
}

.CXn.CXm {
  color: #ffffff;
  fill: #ffffff;
  background-color: #383838;
}
</style>
