<template>
  <div id="root">
    <div class="bonus--bonus-container--2SVqM7D">
      <div class="header--wallet-header--15Fxc5O">
        <div style="margin-bottom: 0px;">{{$t('member.payment.title')}}</div>
      </div>
    </div>

    <div style="display: flex;">
      <div class="usdt_bg" v-for="(item,i) in walletList" :key="i">
        <div>
          <dollar-outlined :style="{fontSize: '25px', color: '#FFFFFF'}" />
        </div>
        <div class="usdt_balance">
          ${{item.balance}}
        </div>
        <div class="usdt_title">{{$t('member.wallet.balance')}}</div>
      </div>
      <div class="option_bg" @click="onInternal">
        <div>
          <plus-circle-outlined :style="{fontSize: '50px', color: '#ff5722'}" />
        </div>
        <div class="option_title">
          {{$t('_wd._nbjy')}}
        </div>
      </div>
      <div class="option_bg" @click="onRecharge">
        <div>
          <plus-circle-outlined :style="{fontSize: '50px', color: '#ff5722'}" />
        </div>
        <div class="option_title">
          {{$t('member.crypto.recharge')}}
        </div>
      </div>
      <div class="option_bg" @click="onWithdrawal">
        <div>
          <plus-circle-outlined :style="{fontSize: '50px', color: '#ff5722'}" />
        </div>
        <div class="option_title">
          {{$t('member.crypto.withdrawal')}}
        </div>
      </div>
    </div>

    <div class="card-root--card-root--BfQRUAH">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane :key="1" :tab="$t('member.wallet.record')">
          <ReceiveList ref="receive"></ReceiveList>
        </a-tab-pane>
        <a-tab-pane :key="2" :tab="$t('member.withdrawal.record')">
          <TransferList ref="transfer"></TransferList>
        </a-tab-pane>
        <a-tab-pane :key="3" :tab="$t('member.transaction.record')">
          <Walletlog ref="walletlog"></Walletlog>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>

  <!-- 充值 -->
  <!-- 存币弹框 -->
  <Recharge ref="recharge" @RechargeSuccess="RechargeSuccess"></Recharge>

  <!-- 提现弹框 -->
  <Withdrawal ref="withdrawal" @WithdrawalSuccess="WithdrawalSuccess"></Withdrawal>

  <!-- 内部划转 -->
  <InternalTransfer ref="internalTransfer" @InternalTransferSuccess="InternalTransferSuccess"></InternalTransfer>

</template>

<script>
import { DollarOutlined, PlusCircleOutlined } from "@ant-design/icons-vue";
import Recharge from "@/components/store/Recharge.vue";
import Withdrawal from "@/components/store/Withdrawal.vue";
import ReceiveList from "@/components/store/ReceiveList.vue";
import TransferList from "@/components/store/TransferList.vue";
import Walletlog from "@/components/store/Walletlog.vue";
import InternalTransfer from '@/components/store/InternalTransfer.vue'
export default {
  name: "paymentVue",
  components: {
    DollarOutlined,
    PlusCircleOutlined,
    Recharge,
    Withdrawal,
    ReceiveList,
    TransferList,
    Walletlog,
    InternalTransfer
  },
  data () {
    return {
      //钱包
      walletList: [],
    };
  },
  created () {
    this.getWallet();
  },
  methods: {
    onInternal() {
      this.$refs.internalTransfer.show()
    },
    onRecharge () {
      this.$refs.recharge.show();
    },
    RechargeSuccess () {
      this.getWallet();
      this.$refs.receive.refresh();
    },
    /**
     * 提现
     */
    onWithdrawal () {
      this.$refs.withdrawal.show();
    },
    WithdrawalSuccess () {
      this.getWallet();
      this.$refs.transfer.refresh();
    },
    InternalTransferSuccess() {
      this.getWallet()
    },
    getWallet () {
      let that = this;
      this.$api.user.getWallet().then((res) => {
        that.walletList = res.data;
      });
    },
  },
};
</script>
<style scoped src="@/static/css/store-wallet.css"></style>
<style scoped>
.wallet-list {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.usdt_bg {
  flex: 1;
  /* min-width: 300px; */
  border-radius: 5px;
  background-image: linear-gradient(155deg, #ffeb3b 0%, #ff5722 50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  margin-left: 10px;
}

.option_bg {
  flex: 1;
  border-radius: 5px;
  background-image: linear-gradient(155deg, #ffffff 0%, #ffffff 50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.option_bg:hover {
  box-shadow: 0px 4px 8px #ddd;
}

.usdt_bg:hover {
  box-shadow: 0px 4px 8px #ddd;
}

.usdt_bg:nth-child(1) {
  margin-left: 0px;
}

.usdt_balance {
  margin: 5px 0;
  font-size: 1.5rem;
  color: #fff;
  font-weight: 700;
}

.usdt_title {
  color: #fff;
  font-size: 15px;
}

.option_title {
  margin: 5px 0;
  font-size: 1.5rem;
  color: #f7815c;
  font-weight: 700;
}

.wallet-record {
  color: #000;
}

.wallet-record th {
  padding: 15px 10px;
}
.wallet-record td {
  padding: 15px 10px;
}
.wallet-record tr {
  border-bottom: 1px #f4f4f4 solid;
}
</style>
