<template>
  <div style="display: grid; gap: 10px; grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))">
    <template v-for="(vo, k) in img" :key="k">
      <div class="upload-box">
        <div class="upload-del">
          <div class="upload-d-btn">
            <eye-outlined @click="onView(k)" :style="{ color: '#fff', fontSize: '16px' }" />
            <delete-outlined @click="onDel(k)" :style="{ color: '#fff', fontSize: '16px' }" />
          </div>
        </div>
        <img :src="vo.url + '?r=' + Math.random() * 1000" alt="avatar" width="126" />
      </div>
    </template>
    <div class="upload-file upload-box" @click="onOpenFile">
      <loading-outlined v-if="loading"></loading-outlined>
      <plus-outlined v-else></plus-outlined>
      <div class="ant-upload-text">{{ $t('bank.upload') }}</div>
    </div>
  </div>
  <input ref="files" type="file" accept="image/*" @change="onFile" hidden />

  <a-modal :visible="previewVisible" :footer="null" @cancel="onCancel">
    <img alt="example" style="width: 100%" :src="previewImage" />
  </a-modal>
</template>

<script>
import { PlusOutlined, LoadingOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons-vue'
export default {
  components: {
    PlusOutlined,
    LoadingOutlined,
    DeleteOutlined,
    EyeOutlined
  },
  props: {
    imageUrl: {
      type: Object,
      default: null
    }
  },
  emits: ['onUpload', 'onDelete'],
  data() {
    return {
      loading: false,
      img: [],
      previewVisible: false,
      previewImage: ''
    }
  },
  created() {
    this.$watch('imageUrl', () => {
      this.img = this.imageUrl
      // console.log(this.img, this.imageUrl)
    })
  },
  methods: {
    onView(i) {
      this.previewImage = this.img[i].url
      this.previewVisible = true
    },
    onCancel() {
      this.previewVisible = false
    },
    onDel(i) {
      this.img.splice(i, 1)
      this.$emit('onUpload', this.img)
      // this.$emit('onDelete', this.img)
    },
    onOpenFile() {
      this.$refs.files.click()
    },
    onFile(file) {
      const files = file.target.files[0]
      console.log(files)
      let uploadFile = new FormData()
      uploadFile.append('file', files)

      this.loading = true
      this.$api.tools.file(uploadFile).then((res) => {
        this.loading = false
        this.img.push({ id: 0, url: res.data.url })
        this.$emit('onUpload', this.img)
      })
    }
  }
}
</script>

<style>
.upload-box {
  width: auto;
  max-width: 300px;
  height: 100px;
  border: 1px #ccc dashed;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.upload-box img {
  /* width: 100%; */
  height: auto;
}

.upload-file {
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.upload-del {
  position: absolute;
  background-color: hwb(0deg 0% 100% / 50%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}
.upload-del:hover {
  opacity: 1;
  transition: opacity 0.2s;
}
.upload-d-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
</style>
